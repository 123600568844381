import React, { useState } from "react";
import Logo from "./Logo";
import MenuToggle from "./MenuToggle";
import MenuItems from "./MenuItems";
import NavBarContainer from "./NavBarContainer";
import UserAvatar from "./UserAvatar";
import { HStack,Icon } from "@chakra-ui/react";
import { FaSignInAlt } from "react-icons/fa";

const Header = (props) => {
  const [isOpen, setIsOpen] = useState(true);
  const toggle = () => setIsOpen(!isOpen);
  return (
    <NavBarContainer username={props.username} roles={props.roles}>
      <MenuToggle isOpen={isOpen} toggle={toggle} />
      <MenuItems isOpen={isOpen} roles={props.roles} isLoggedIn={props.isLoggedIn} />
      <HStack>
        <Logo w="125px" />
        {props.username ? (
          <div onClick={props.handlelogout}>
            <UserAvatar name={props.username} />
          </div>
        ) : (
        <div onClick={props.handlelogin}>
          <Icon as={FaSignInAlt} boxSize={"1.5em"} />
        </div>)}
      </HStack>
    </NavBarContainer>
  );
};

export default Header;
