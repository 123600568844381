import { useState, useEffect, useCallback } from "react";
import {
  Box,
  Heading,
  Button,
  UnorderedList,
  Text,
  Icon,
  ListItem,
  Link,
  useToast,
} from "@chakra-ui/react";
import ComponentContainer from "../../common/ComponentContainer";
import FormHeader from "../../common/FormHeader";
import { getBaseUrl, getDefaultFetchOptions } from "../../utils";
import { HTTP_VERB } from "../../enums";
import CompanySelectById from "../../common/CompanySelectById";
import { getWebUrl } from "../../utils";
import { FaFileCsv } from "react-icons/fa";

const ImportBarcodes = () => {
  const toast = useToast();
  const renderToast = useCallback((toast, isError, title, desc) => {
    let status = "success";
    if (isError) {
      status = "error";
    }
    toast({
      title: title,
      description: desc,
      status: status,
      duration: 3000,
      isClosable: true,
    });
  }, []);
  const [createFile, setCreateFile] = useState(null);
  const [companyId, setCompanyId] = useState();
  const [isLoading, setIsLoading] = useState(false);
  const [isComplete, setIsComplete] = useState(false);
  const [files, setFiles] = useState([]);
  const getFiles = useCallback(() => {
    (async () => {
      const uri = `${getBaseUrl()}/barcodeImport/files`;
      let request = await fetch(uri, getDefaultFetchOptions(HTTP_VERB.GET));
      if (request.ok) {
        let response = await request.json();
        setFiles(response);
      } else {
        let errResponse = await request.json();
        renderToast(
          toast,
          true,
          "Error fetching existing Payment files.",
          errResponse?.detail
        );
      }
    })();
  }, [toast, renderToast]);
  useEffect(() => {
    getFiles();
  }, [getFiles]);

  const uploadFile = useCallback(
    async (csvFile) => {
      try {
        setIsLoading(true);
        const formData = new FormData();
        formData.append("csvFile", csvFile);

        const response = await fetch(`${getBaseUrl()}/BarcodeImport/upload?companyId=${companyId}`, {
          ...getDefaultFetchOptions(HTTP_VERB.POST, false),
          body: formData,
        });

        if (!response.ok) {
          const result = await response.json();
          renderToast(toast, true, "Error processing file", result?.detail);
        } else {
          getFiles();
          setIsComplete(true);
          renderToast(
            toast,
            false,
            "File successfully processed.",
            "View results within processed csv file above"
          );
        }
      } catch (error) {
        console.log(error);
        renderToast(toast, true, "Error processing file", error);
      } finally {
        setIsLoading(false);
        setCreateFile(null);
      }
    },
    [companyId, getFiles, toast, renderToast]
  );
  const handleCreateFileInputChange = (event) => {
    event.preventDefault();
    event.target.files &&
      event.target.files[0] &&
      setCreateFile(event.target.files[0]);
  };
  useEffect(() => {
    !!createFile && uploadFile(createFile);
  }, [createFile, uploadFile]);

  const getFileUrl = (fileName) => {
    const uri = `${getWebUrl()}/${fileName}`;
    return uri;
  };
  const cleanFileName = (fileName) => {
    fileName = fileName.replace("barcodeimport\\", "");
    return fileName;
  };
  const renderFiles = () => {
    if (!files || files.length === 0) {
      return;
    }
    return files.map((v, i) => {
      return (
        <ListItem key={i}>
          <Link href={getFileUrl(v)}>
            {cleanFileName(v)} <Icon as={FaFileCsv} />
          </Link>
        </ListItem>
      );
    });
  };

  return (
    <ComponentContainer
      width={"1000px"}
      height={"800px"}
      maxHeight={900}
      overflowY="auto"
    >
      <FormHeader
        title={"Barcode Uploader"}
        subTitle={"Import barcodes from an uploaded CSV file"}
      />
      <Box borderWidth={1} borderRadius={4} ml={2} mt={5} p={2}>
        <Heading as={"h3"} size="sm" mb="1">
          Previously processed files
        </Heading>
        <Text fontSize={"xs"} as={"span"}>
          Click any file below to download.
        </Text>
        <Box maxHeight={"200px"} overflowY={"auto"} p={1}>
          <UnorderedList cursor={"pointer"}>{renderFiles()}</UnorderedList>
        </Box>
      </Box>
      <CompanySelectById onCompanyChange={(e) => setCompanyId(e)} />
      {companyId && (
        <Box>
          <Heading mt={5} ml={2} size="md" fontWeight={600}>
            {!isLoading && !isComplete
              ? "Click the button below to upload your CSV file"
              : isLoading && !isComplete
              ? "Please wait until the excel file is ready to be downloaded"
              : "The excel file is ready to be downloaded"}
          </Heading>
          <Box>
            <Button
              isLoading={isLoading}
              variant="solid"
              as="label"
              mt={41}
              colorScheme="teal"
            >
              Select file
              <input
                hidden
                accept=".csv"
                type="file"
                onChange={handleCreateFileInputChange}
              />
            </Button>
          </Box>
        </Box>
      )}
    </ComponentContainer>
  );
};

export default ImportBarcodes;
