export const HTTP_VERB = {
  GET: "GET",
  POST: "POST",
  PUT: "PUT",
  PATCH: "PATCH",
  DELETE: "DELETE",
};

export const ROLES = {
  IT: 1,
  Support: 2,
  Accounting: 3,
  WEX:4,
};

export const TABLE_MODE = {
  VIEW_ONLY: 1,
  SELECTABLE: 2,
  CLICKABLE: 3,
};
