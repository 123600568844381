import React from "react";
import SelectCustomer from "../SelectCustomer";
import { Outlet, Routes, Route } from "react-router-dom";
import ComponentContainer from "../../../common/ComponentContainer";
import FormHeader from "../../../common/FormHeader";

const ConsignorRatesHome = () => {
  return (
    <ComponentContainer
      width={"1400px"}
      height={"900px"}
      maxHeight={900}
      overflowY="auto"
    >
      <FormHeader title={"Consignor Rates"} />
      <Routes>
        <Route
          path="/"
          element={<SelectCustomer path={"/accounting/consignorrates/"} />}
        />
      </Routes>
      <Outlet />
    </ComponentContainer>
  );
};

export default ConsignorRatesHome;
