import React from "react";
import { Box, Image } from "@chakra-ui/react";

const HeroImage = ({ imgSrc }) => {
  return (
    <Box w={{ base: "80%", sm: "60%", md: "50%" }} mb={{ base: 12, md: 0 }}>
      <Image src={imgSrc} size="100%" rounded="1rem" shadow="2xl" />
    </Box>
  );
};

export default HeroImage;
