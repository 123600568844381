import React, { forwardRef } from "react";
import DatePicker from "react-datepicker";
import { Button } from "@chakra-ui/react";
import "react-datepicker/dist/react-datepicker.css";
import { BsCalendar3 } from "react-icons/bs";

const CustomInput = forwardRef(({ value, onClick }, ref) => (
  <Button
    onClick={onClick}
    ref={ref}
    colorScheme={"teal"}
    variant="solid"
    leftIcon={<BsCalendar3 />}
  >
    {value}
  </Button>
));

const ButtonDatePicker = ({ date, onDateChange }) => {
  return (
    <DatePicker
      customInput={<CustomInput />}
      selected={date}
      onChange={(d) => onDateChange(d)}
      popperClassName="button-calendar-popper"
    />
  );
};

export default ButtonDatePicker;
