import React, { useState, useEffect, useCallback } from "react";
import {
  Button,
  useToast,
  Modal,
  ModalBody,
  ModalContent,
  ModalOverlay,
  useDisclosure,
} from "@chakra-ui/react";
import { DateTime } from "luxon";
import { useNavigate } from "react-router-dom";
import ComponentContainer from "../../../common/ComponentContainer";
import FormHeader from "../../../common/FormHeader";
import { getBaseUrl, getDefaultFetchOptions } from "../../../utils";
import { HTTP_VERB, TABLE_MODE } from "../../../enums";
import OneTicketEventMap from "./OneTicketEventMap";
import OneTicketNotFound from "./OneTicketNotFound";
import TableCore from "../../../common/TableCore";

const columns = [
  { label: "External", renderCell: (item) => item.external_Event_Name },
  {
    label: "Internal",
    renderCell: (item) => item.event_Headliner,
  },
  {
    label: "Updated Date",
    renderCell: (item) => DateTime.fromISO(item.updatedOn).toLocaleString(),
  },
];

const createRow = (item) => {
  return {
    id: item.oneTicket_Event_Name_Map_ID,
    ...item,
  };
};

const OneTicketEventMapManagement = () => {
  const [selectedMap, setSelectedMap] = useState();
  const [externalName, setExternalName] = useState("");
  const [eventHeadlinerId, setEventHeadlinerId] = useState();
  const [values, setValues] = useState();
  const [search, setSearch] = useState("");
  const { isOpen, onOpen, onClose } = useDisclosure();
  const toast = useToast();
  const navigate = useNavigate();
  const renderToast = useCallback((toast, isError, title, desc) => {
    let status = "success";
    if (isError) {
      status = "error";
    }
    toast({
      title: title,
      description: desc,
      status: status,
      duration: 3000,
      isClosable: true,
    });
  }, []);
  const handleRowClick = (action, state) => {
    if (action.payload) {
      let selectedMapping = values.find((x) => {
        return x.id === action.payload.id;
      });
      setExternalName(selectedMapping.external_Event_Name);
      setEventHeadlinerId(selectedMapping.event_Headliner_ID);
      setSelectedMap(selectedMapping);
      onOpen();
    }
  };
  const getData = () => {
    if (!search) {
      return values;
    }
    return values.filter((x) => {
      return JSON.stringify(x).toLowerCase().includes(search.toLowerCase());
    });
  };
  let data = () => {
    return {
      nodes: getData(),
    };
  };
  useEffect(() => {
    (async () => {
      const uri = `${getBaseUrl()}/OneTicketEventMap`;
      let result = await fetch(uri, getDefaultFetchOptions(HTTP_VERB.GET));
      if (result.ok) {
        let response = await result.json();
        let temp = [];
        for (const i of response) {
          temp.push(createRow(i));
        }
        setValues(temp);
      }
    })();
  }, []);

  const clearState = () => {
    setSelectedMap();
    setExternalName("");
    setEventHeadlinerId();
  };
  const handleCancelClick = () => {
    clearState();
    onClose();
  };
  const handleSaveClick = (data) => {
    let temp = [...values];
    let wasUpdate = false;
    temp.forEach((x) => {
      if (x.id === data.oneTicket_Event_Name_Map_ID) {
        x.external_Event_Name = data.external_Event_Name;
        x.event_Headliner = data.event_Headliner;
        wasUpdate = true;
      }
    });
    if (!wasUpdate) {
      temp.push(createRow(data));
    }
    setValues(temp);
    setSelectedMap(undefined);
    renderToast(toast, false, "Success", "Mapping succesfully updated");
    onClose();
  };
  const handleDeleteClick = (id) => {
    let temp = [...values];
    temp = temp.filter((x) => {
      return x.oneTicket_Event_Name_Map_ID !== id;
    });
    setValues(temp);
    clearState();
    renderToast(toast, false, "Success", "Mapping succesfully deleted");
    onClose();
  };
  const handleAddModeClick = () => {
    clearState();
    onOpen();
  };
  const handleInvoiceClick = (data) => {
    if (data?.payload?.id) {
      navigate(`/orders/oneticketeventsearch/${data.payload.id}`);
    }
  };
  return (
    <ComponentContainer width={1000}>
      <FormHeader
        title={"1Ticket Event Name Map"}
        subTitle={"Associate 1Ticket event names to StageFront Event names"}
      />
      <OneTicketNotFound onRowClick={(data) => handleInvoiceClick(data)} />
      <Button
        mt={5}
        mb={5}
        colorScheme={"teal"}
        width={"125px"}
        variant={"solid"}
        onClick={() => handleAddModeClick()}
      >
        Create New
      </Button>
      {values && (
        <TableCore
          columns={columns}
          data={data()}
          height={"660px"}
          width={"800px"}
          onRowSelect={handleRowClick}
          onSearchChange={(e) => setSearch(e)}
          mode={TABLE_MODE.CLICKABLE}
          pageSize={10}
          showSearch={true}
        />
      )}

      <Modal
        isOpen={isOpen}
        isCentered
        onClose={onClose}
        scrollBehavior={"outside"}
        size={"4xl"}
      >
        <ModalOverlay />
        <ModalContent>
          <ModalBody>
            <OneTicketEventMap
              mapId={selectedMap?.id}
              externalName={externalName}
              eventHeadlinerId={eventHeadlinerId}
              onExternalNameChange={(e) => setExternalName(e)}
              onEventHeadlinerChange={(e) => setEventHeadlinerId(e)}
              onCancelClick={() => {
                handleCancelClick();
              }}
              onSave={(d) => handleSaveClick(d)}
              onDelete={(id) => handleDeleteClick(id)}
            />
          </ModalBody>
        </ModalContent>
      </Modal>
    </ComponentContainer>
  );
};

export default OneTicketEventMapManagement;
