import React from "react";
import Sidebar from "../../common/Sidebar/Sidebar";
import { Box, Flex, Icon } from "@chakra-ui/react";
import CheckPurchases from "./CheckPurchases";
import {
  FaHome,
  FaDollarSign,
  FaHistory,
  FaClock,
  FaSearch,
  FaCalendarCheck,
  FaMapMarked,
  FaQrcode,
  FaStream,
} from "react-icons/fa";
import { Outlet, Routes, Route } from "react-router-dom";

const subMenu = [
  {
    icon: <Icon as={FaQrcode} />,
    path: "tmAuth",
    label: "TM Auth Codes",
  },
  {
    icon: <Icon as={FaQrcode} />,
    path: "axsAuth",
    label: "AXS Auth Codes",
  },
  {
    icon: <Icon as={FaHome} />,
    path: "",
    label: "Check Purchases",
  },
  {
    icon: <Icon as={FaDollarSign} />,
    path: "oneticketxfer",
    label: "1Ticket Invoice Transfer",
  },
  {
    icon: <Icon as={FaSearch} />,
    path: "oneticketxfersearch",
    label: "1Ticket Invoice Transfer Search",
  },
  {
    icon: <Icon as={FaHistory} />,
    path: "oneticketbatch",
    label: "1Ticket Batch Transfers",
  },
  {
    icon: <Icon as={FaClock} />,
    path: "oneticketpending",
    label: "1Ticket Pending Transfers",
  },
  {
    icon: <Icon as={FaCalendarCheck} />,
    path: "oneticketeventsearch",
    label: "1Ticket Event Search",
  },
  {
    icon: <Icon as={FaMapMarked} />,
    path: "oneticketeventmap",
    label: "1Ticket Event Name Map",
  },
  {
    icon: <Icon as={FaStream} />,
    path: "orderintervention",
    label: "Order Intervention",
  },
];

const OrdersHome = () => {
  return (
    <Flex direction={"row"}>
      <Sidebar isOpen={true} title="Orders Management" linkItems={subMenu} />
      <Box h="full" p="4">
        <Flex direction={"column"}>
          <Routes>
            <Route path="/" element={<CheckPurchases />} />
          </Routes>
          <Outlet />
        </Flex>
      </Box>
    </Flex>
  );
};

export default OrdersHome;
