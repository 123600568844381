import { Avatar, Tooltip } from "@chakra-ui/react";
import React from "react";

const UserAvatar = ({ name }) => {
  return (
    <Tooltip label={name}>
      <Avatar name={name} />
    </Tooltip>
  );
};

export default UserAvatar;
