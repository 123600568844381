import { useState, useRef, useEffect, useCallback } from "react";
import {
  Box,
  Heading,
  Button,
  Alert,
  AlertTitle,
  AlertDescription,
  AlertIcon,
  Text,
  Progress,
  CloseButton,
  Flex,
} from "@chakra-ui/react";
import fileIcon from "../../images/file-icon.png";
import {
  getBaseUrl,
  getDefaultFetchOptions,
  convertFileSize,
} from "../../utils";
import { HTTP_VERB } from "../../enums";

const WEX_OPERATIONS = {
  CREATE_CARDS: "createCards",
  CLOSE_ACCOUNTS: "closeCards",
};

const WEXCards = ({ username }) => {
  const downloadButtonRef = useRef(null);
  const [isAllowedToClose, setIsAllowedToClose] = useState(false);

  const [createFile, setCreateFile] = useState(null);
  const [closeFile, setCloseFile] = useState(null);
  const [fileName, setFileName] = useState("");
  const [fileSize, setFileSize] = useState("0 bytes");

  const [isLoading, setIsLoading] = useState(false);
  const [isComplete, setIsComplete] = useState(false);
  const [isWarning, setIsWarning] = useState(false);

  const [errorOpen, setErrorOpen] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");

  const handleCreateFileInputChange = (event) => {
    event.preventDefault();
    event.target.files &&
      event.target.files[0] &&
      setCreateFile(event.target.files[0]);
  };
  const handleCloseFileInputChange = (event) => {
    event.preventDefault();
    event.target.files &&
      event.target.files[0] &&
      setCloseFile(event.target.files[0]);
  };

  const callWEXService = useCallback(
    async (csvFile, operation) => {
      setIsWarning(false);
      setErrorOpen(false);
      setErrorMessage("");
      setIsLoading(true);

      const formData = new FormData();
      formData.append("csvFile", csvFile);

      try {
        const response = await fetch(`${getBaseUrl()}/WEX/${operation}`, {
          ...getDefaultFetchOptions(HTTP_VERB.POST, false),
          body: formData,
        });
        if (!response.ok) {
          const responseBody = await response.json();
          const errorMessage = responseBody.detail;
          throw new Error(`${response.statusText} - ${errorMessage}`);
        } else if (response.status === 206) {
          setIsWarning(true);
        }

        const blob = await response.blob();
        const url = URL.createObjectURL(blob);

        setFileSize(convertFileSize(blob.size));

        if (downloadButtonRef.current) {
          downloadButtonRef.current.href = url;
          downloadButtonRef.current.download = fileName;
        }

        setIsLoading(false);
        setIsComplete(true);
      } catch (error) {
        console.error(error);

        setErrorOpen(true);
        setErrorMessage(error.message);
        setIsLoading(false);
      } finally {
        setCreateFile(null);
        setCloseFile(null);
      }
    },
    [fileName]
  );

  useEffect(() => {
    (async () => {
      const uri = `${getBaseUrl()}/WEX/getWEXClosePermission`;
      const response = await fetch(uri, getDefaultFetchOptions(HTTP_VERB.GET));
      let result = await response.json();

      if (!!result) {
        setIsAllowedToClose(result.includes(username.toLowerCase()));
      } else {
        setIsAllowedToClose(false);
      }
    })();
  }, [username]);

  useEffect(() => {
    const date = new Date();
    const options = { month: "2-digit", day: "2-digit", year: "numeric" };
    const dateString = date
      .toLocaleDateString("en-US", options)
      .replace(/\//g, ".");
    setFileName(
      `WEX-Card-Data-${dateString}-${Math.round(Math.random() * 1e9)}.xlsx`
    );
  }, [createFile, closeFile]);

  useEffect(() => {
    if (closeFile) {
      callWEXService(closeFile, WEX_OPERATIONS.CLOSE_ACCOUNTS);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [closeFile]);

  useEffect(() => {
    if (createFile) {
      callWEXService(createFile, WEX_OPERATIONS.CREATE_CARDS);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [createFile]);

  return (
    <Box borderRadius={4} boxShadow="lg">
      <Box bg="#283F83" borderRadius="4px 4px 0 0" p={7}>
        <Heading size="md" color="white" fontWeight={600}>
          {!isLoading && !isComplete
            ? "Upload a .CSV file in the correct format"
            : isLoading && !isComplete
            ? "Please wait until the excel file is ready to be downloaded"
            : "The excel file is ready to be downloaded"}
        </Heading>
      </Box>
      <Box p={10} pl={20} pr={20}>
        {((!isLoading && !isComplete) || errorOpen) && (
          <Box>
            {!!errorOpen ? (
              <Alert status="error" variant="subtle" borderRadius={4}>
                <AlertIcon />
                <Box>
                  <AlertTitle>Error</AlertTitle>
                  <AlertDescription>{errorMessage}</AlertDescription>
                </Box>
                <CloseButton
                  alignSelf="flex-start"
                  position="relative"
                  ml={"auto"}
                  right={-1}
                  top={-1}
                  onClick={() => setErrorOpen(false)}
                />
              </Alert>
            ) : (
              <Text fontSize="2xl" fontWeight={600} align="center" p={23}>
                Upload a file from your computer
              </Text>
            )}
            <Flex
              justifyContent="center"
              alignItems="center"
              flexDirection="column"
            >
              <Button
                variant="solid"
                as="label"
                mt={41}
                width={150}
                colorScheme="facebook"
                bg="#283F83"
              >
                Create Cards
                <input
                  hidden
                  accept=".csv"
                  type="file"
                  onChange={handleCreateFileInputChange}
                />
              </Button>
              {isAllowedToClose && (
                <Button
                  variant="solid"
                  as="label"
                  mt={21}
                  width={150}
                  colorScheme="orange"
                >
                  Close Cards
                  <input
                    hidden
                    accept=".csv"
                    type="file"
                    onChange={handleCloseFileInputChange}
                  />
                </Button>
              )}
            </Flex>
          </Box>
        )}

        {/* In Progress & Ready for Download */}
        {(isLoading || (isComplete && !isLoading)) && (
          <Box>
            <Text mb={4} align="center" fontSize="2xl">
              {!isComplete ? "Loading..." : "Completed!"}
            </Text>
            <Progress
              colorScheme="facebook"
              isIndeterminate={!isComplete}
              value={100}
              borderRadius={4}
            />

            <Box mt={4}>
              {isWarning && (
                <Alert status="warning" variant="subtle" borderRadius={4}>
                  <AlertIcon />
                  <Box>
                    <AlertTitle>Warning</AlertTitle>
                    <AlertDescription>
                      Some of the requests #FAILED, check the Failure Reason
                      column in the Failed Attempts sheet of the excel file for
                      more information. Convert the Failed Attempts sheet to
                      .CSV and try again.
                    </AlertDescription>
                  </Box>
                  <CloseButton
                    alignSelf="flex-start"
                    position="relative"
                    ml={"auto"}
                    right={-1}
                    top={-1}
                    onClick={() => setIsWarning(false)}
                  />
                </Alert>
              )}
              <Flex
                border="1px solid #E9E9E9"
                borderRadius={8}
                p="28px 22px"
                alignItems="center"
                gap={4}
                mt={4}
              >
                <Box as="img" src={fileIcon} />
                <Text>{`${fileName} (${fileSize})`}</Text>
              </Flex>
              <Flex justifyContent="flex-end" alignItems="center">
                <Button
                  ref={downloadButtonRef}
                  variant="solid"
                  colorScheme="facebook"
                  bg="#283F83"
                  as="a"
                  disabled={!isComplete}
                  mt={4}
                >
                  Download File
                </Button>
              </Flex>
            </Box>
          </Box>
        )}
      </Box>
    </Box>
  );
};

export default WEXCards;
