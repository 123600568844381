import React, { useEffect, useState, useCallback } from "react";
import {
  Button,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  useDisclosure,
  Spinner,
  useToast,
} from "@chakra-ui/react";
import { getDefaultFetchOptions, getBaseUrl } from "../../../utils";
import { HTTP_VERB, TABLE_MODE } from "../../../enums";
import InvoicesTable from "./InvoicesTable";
import ComponentContainer from "../../../common/ComponentContainer";
import FormHeader from "../../../common/FormHeader";
import InvoiceDetails from "../InvoiceDetails";

const OneTicketPendingInvoices = () => {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [selectedInvoiceId, setSelectedInvoiceId] = useState();
  const [isFetching, setIsFetching] = useState(false);
  const [invoices, setInvoices] = useState();
  const [invoiceDetails, setInvoiceDetails] = useState();
  const toast = useToast();
  const renderToast = useCallback((toast, isError, title, desc) => {
    let status = "success";
    if (isError) {
      status = "error";
    }
    toast({
      title: title,
      description: desc,
      status: status,
      duration: 3000,
      isClosable: true,
    });
  }, []);
  useEffect(() => {
    (async () => {
      const uri = `${getBaseUrl()}/invoice/pending`;
      let request = await fetch(uri, getDefaultFetchOptions(HTTP_VERB.GET));
      const response = await request.json();
      if (request.ok) {
        setInvoices(response);
      } else {
        renderToast(
          toast,
          true,
          "Error Fetching Pending Invoices",
          response?.detail
        );
      }
    })();
  }, [toast, renderToast]);
  const fetchInvoiceDetails = async (invoiceId) => {
    if (!invoiceId) {
      return;
    }
    setIsFetching(true);
    const uri = `${getBaseUrl()}/invoice/${invoiceId}`;
    const request = await fetch(uri, getDefaultFetchOptions(HTTP_VERB.GET));
    try {
      const response = await request.json();
      if (request.ok) {
        setInvoiceDetails(response);
      } else {
        renderToast(
          toast,
          true,
          "Error Fetching Invoice Details",
          response?.detail
        );
      }
    } catch (error) {
      renderToast(
        toast,
        true,
        "Error Fetching Invoice Details",
        "Could not render invoice"
      );
    }

    setIsFetching(false);
  };
  const handleRowClick = (payload) => {
    const invoiceId = payload.id;
    if (!invoiceId) {
      setSelectedInvoiceId();
      return;
    }
    setSelectedInvoiceId(() => {
      fetchInvoiceDetails(invoiceId);
      return invoiceId;
    });

    onOpen();
  };
  const handleClose = () => {
    setSelectedInvoiceId();
    onClose();
  };
  return (
    <>
      <ComponentContainer width={1000}>
        <FormHeader
          title={"1Ticket Pending Invoices"}
          subTitle={
            "Displays a list of 1Ticket Invoice Transfers currently in the PENDING status"
          }
        />
        {invoices && (
          <InvoicesTable
            invoices={invoices}
            mode={TABLE_MODE.CLICKABLE}
            onRowSelect={(e) => handleRowClick(e.payload)}
          />
        )}
      </ComponentContainer>
      <Modal
        isOpen={isOpen}
        onClose={onClose}
        scrollBehavior={"inside"}
        size={"4xl"}
      >
        <ModalOverlay />
        <ModalContent>
          {isFetching && <Spinner size="xl" />}
          {!isFetching}
          {
            <>
              <ModalHeader>Invoice {selectedInvoiceId}</ModalHeader>
              <ModalBody>
                <InvoiceDetails invoice={invoiceDetails} />
              </ModalBody>
            </>
          }
          <ModalCloseButton />
          <ModalFooter>
            <Button colorScheme="red" mr={3} onClick={handleClose}>
              Close
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </>
  );
};

export default OneTicketPendingInvoices;
