import React, { useState, useEffect } from "react";
import { getBaseUrl, getDefaultFetchOptions } from "../utils";
import { HTTP_VERB } from "../enums";
import CompanySelectCore from "./CompanySelectCore";

const CompanySelect = ({
  companyName,
  onCompanyNameChange,
  shouldContainTransferKey = false,
}) => {
  const [companyNames, setCompanyNames] = useState([]);
  const [namesFetching, setNamesFetching] = useState(false);
  useEffect(() => {
    async function fetchNames() {
      setNamesFetching(true);
      let uri = `${getBaseUrl()}/companies/names`;
      if (shouldContainTransferKey) {
        uri += "?shouldContainTransferKey=true";
      }
      let result = await fetch(uri, getDefaultFetchOptions(HTTP_VERB.GET));
      let names = await result.json();
      setCompanyNames(names);
      setNamesFetching(false);
    }
    fetchNames();
  }, [shouldContainTransferKey]);
  return (
    <CompanySelectCore
      companies={companyNames}
      selectedValue={companyName}
      fetching={namesFetching}
      onValueChange={onCompanyNameChange}
      useIdAsValue={false}
    />
  );
};

export default CompanySelect;
