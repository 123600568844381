import React, { useState, useEffect } from "react";
import {
  Box,
  Button,
  FormControl,
  FormLabel,
  HStack,
  Spinner,
  Text,
  useToast,
  Popover,
  PopoverTrigger,
  PopoverContent,
  PopoverArrow,
  PopoverCloseButton,
  PopoverBody,
  PopoverHeader,
} from "@chakra-ui/react";
import { DateTime } from "luxon";
import { getDefaultFetchOptions, getBaseUrl } from "../../utils";
import { HTTP_VERB } from "../../enums";
import ButtonDatePicker from "../../common/ButtonDatePicker";

const PaymentPendingForm = ({ onSubmit }) => {
  const getStartDate = () => {
    var today = DateTime.now();
    if (today.day <= 10) {
      return today.set({ day: 1 }).toJSDate();
    }
    if (today.day <= 20) {
      return today.set({ day: 11 }).toJSDate();
    }
    return today.set({ day: 21 }).toJSDate();
  };
  const getEndDate = () => {
    var today = DateTime.now();
    if (today.day <= 10) {
      return today.set({ day: 10 }).toJSDate();
    }
    if (today.day <= 20) {
      return today.set({ day: 20 }).toJSDate();
    }
    return today.set({ day: today.daysInMonth }).toJSDate();
  };
  const [startDate, setStartDate] = useState(getStartDate());
  const [endDate, setEndDate] = useState(getEndDate());
  const [exchangeRate, setExchangeRate] = useState();
  const [isSubmitting, setSubmitting] = useState(false);
  const toast = useToast();
  useEffect(() => {
    (async () => {
      const uri = `${getBaseUrl()}/currency/exchange?baseSymbol=CAD&symbol=USD`;
      let request = await fetch(uri, getDefaultFetchOptions(HTTP_VERB.GET));
      if (request.ok) {
        let response = await request.text();
        setExchangeRate(response);
      } else {
        let errResponse = await request.json();
        toast({
          title: "Error fetching Exchange Rate.",
          description: errResponse?.detail,
          status: "error",
          duration: 10000,
          isClosable: true,
        });
      }
    })();
  }, [toast]);
  const handleSubmit = async () => {
    setSubmitting(true);
    let body = {
      startDate: startDate.toISOString(),
      endDate: endDate.toISOString(),
      exchangeRate: exchangeRate,
    };
    const uri = `${getBaseUrl()}/consignorpayments/generatePaymentPending`;
    try {
      let request = await fetch(uri, {
        ...getDefaultFetchOptions(HTTP_VERB.POST),
        body: JSON.stringify(body),
      });
      if (request.ok) {
        toast({
          title: "Payment Pending Complete",
          description: "Payment Pending reports generated.",
          status: "success",
          duration: 3000,
          isClosable: true,
        });
        onSubmit();
      } else {
        try {
          let errResponse = await request.json();
          toast({
            title: "Error generating Payment Pending reports.",
            description: errResponse?.detail,
            status: "error",
            duration: 10000,
            isClosable: true,
          });
        } catch (error) {
          console.log(error);
        }
      }
    } catch (error) {
      console.log(error);
      toast({
        title: "Error generating Payment Pending reports.",
        description: error,
        status: "error",
        duration: 10000,
        isClosable: true,
      });
    }

    setSubmitting(false);
  };
  const handleConfirmClick = (onClose) => {
    onClose();
    handleSubmit();
  };
  const handleEndDateChange = (val) => {
    if (val < startDate) {
      toast({
        title: "Form Validation error.",
        description: "End Date cannot be before Start Date",
        status: "error",
        duration: 10000,
        isClosable: true,
      });
      return;
    }
    setEndDate(val);
  };
  const handleStartDateChange = (val) => {
    if (val > endDate) {
      toast({
        title: "Form Validation error.",
        description: "Start Date cannot be after End Date",
        status: "error",
        duration: 10000,
        isClosable: true,
      });
      return;
    }
    setStartDate(val);
  };
  return (
    <Box borderWidth={1} borderRadius={4} mb={5}>
      <HStack p={2} spacing={1}>
        <FormControl>
          <FormLabel>Period Start Date</FormLabel>
          <ButtonDatePicker
            date={startDate}
            onDateChange={(d) => handleStartDateChange(d)}
          />
        </FormControl>
        <FormControl>
          <FormLabel>Period End Date</FormLabel>
          <ButtonDatePicker
            date={endDate}
            onDateChange={(d) => handleEndDateChange(d)}
          />
        </FormControl>
        <FormControl>
          <FormLabel>CAD to USD Rate</FormLabel>
          {!exchangeRate && <Spinner />}
          <Text>{exchangeRate}</Text>
        </FormControl>

        <HStack>
          <Popover>
            {({ isOpen, onClose }) => (
              <>
                <PopoverTrigger>
                  <Button
                    isDisabled={isSubmitting}
                    isLoading={isSubmitting}
                    colorScheme={"blue"}
                    width="250px"
                  >
                    Submit
                  </Button>
                </PopoverTrigger>
                <PopoverContent
                  color="white"
                  bg="blue.800"
                  borderColor="blue.800"
                >
                  <PopoverArrow />
                  <PopoverHeader pt={4} fontWeight="bold">
                    Please verify your dates are correct
                  </PopoverHeader>
                  <PopoverCloseButton />
                  <PopoverBody>
                    <Button
                      colorScheme={"blue"}
                      onClick={() => handleConfirmClick(onClose)}
                    >
                      My dates are correct.
                    </Button>
                  </PopoverBody>
                </PopoverContent>
              </>
            )}
          </Popover>
          {isSubmitting && (
            <Text>Generating reports. This could take several minutes.</Text>
          )}
        </HStack>
      </HStack>
    </Box>
  );
};

export default PaymentPendingForm;
