import React, { useState, useEffect } from "react";
import {
  FormControl,
  FormLabel,
  Input,
  Select,
  VStack,
} from "@chakra-ui/react";
import { getBaseUrl, getDefaultFetchOptions } from "../../utils";
import GenericFormContainer from "../../common/GenericForm/GenericFormContainer";
import { HTTP_VERB } from "../../enums";

const AddVendor = () => {
  const [isFetching, setIsFetching] = useState(false);
  const [crawlersFetching, setCrawlersFetching] = useState(false);
  const [vendor, setVendor] = useState("");
  const [teamCode, setTeamCode] = useState("");
  const [crawler, setCrawler] = useState(0);
  const [crawlers, setCrawlers] = useState([]);
  const [resultMessage, setResultMessage] = useState("");
  const [isError, setIsError] = useState(false);
  useEffect(() => {
    async function fetchCrawlers() {
      setCrawlersFetching(true);
      const uri = `${getBaseUrl()}/crawlers`;
      let result = await fetch(uri, getDefaultFetchOptions(HTTP_VERB.GET));
      let crawlers = await result.json();
      let options = [];
      for (const crawler of crawlers) {
        options.push({ name: crawler.crawlerType, id: crawler.crawlerId });
      }
      setCrawlers(options);
      setCrawlersFetching(false);
    }
    fetchCrawlers();
  }, []);
  const submitVendor = async () => {
    try {
      setIsFetching(true);
      const body = {
        name: vendor,
        teamCode: teamCode,
        crawlerId: crawler,
      };
      var uri = `${getBaseUrl()}/marvin/vendors`;
      var result = await fetch(uri, {
        ...getDefaultFetchOptions(HTTP_VERB.POST),
        body: JSON.stringify(body),
      });
      if (result.ok) {
        setIsError(false);
        setResultMessage(`Vendor: ${vendor} added succesfully.`);
      } else {
        setIsError(true);
        setResultMessage(
          `Error encountered adding Vendor: ${vendor}.  Status Code: ${result.status}`
        );
      }
    } catch (error) {
      setIsFetching(false);
      console.log(error);
    }
    setIsFetching(false);
    setVendor("");
    setTeamCode("");
  };
  const renderSelectItems = () => {
    if (crawlers.length === 0) {
      return;
    }
    return crawlers.map((v, i) => {
      return (
        <option key={v.id} value={v.id}>
          {v.name}
        </option>
      );
    });
  };
  const allowSubmit = () => {
    if (vendor.length > 0) {
      return true;
    }
    return false;
  };
  return (
    <GenericFormContainer
      formMaxWidth={"500px"}
      isSubmitDisabled={!allowSubmit()}
      isFetching={isFetching}
      onSubmitClick={() => {
        submitVendor();
      }}
      isResultError={isError}
      resultMessage={resultMessage}
      subTitle={"Insert a new Vendor Record into Marvin"}
      title={"Add Vendor"}
    >
      <VStack align={"flex-start"} p="2">
        <FormControl>
          <FormLabel htmlFor="vendorName">Vendor Name</FormLabel>
          <Input
            id="vendorName"
            type="text"
            placeholder="Enter Vendor Name"
            maxLength={128}
            variant={"filled"}
            value={vendor}
            onChange={(e) => setVendor(e.target.value)}
          />
        </FormControl>
        <FormControl>
          <FormLabel htmlFor="teamCode">Team Code</FormLabel>
          <Input
            id="teamCode"
            type="text"
            maxLength={50}
            placeholder="Enter Team Code"
            variant={"filled"}
            value={teamCode}
            onChange={(e) => setTeamCode(e.target.value)}
          />
        </FormControl>
        <FormControl>
          <FormLabel>Crawler</FormLabel>
          <Select
            isDisabled={crawlersFetching}
            variant={"filled"}
            onChange={(e) => setCrawler(e.target.value)}
          >
            {renderSelectItems()}
          </Select>
        </FormControl>
      </VStack>
    </GenericFormContainer>
  );
};

export default AddVendor;
