import {
  Box,
  Button,
  FormControl,
  FormLabel,
  HStack,
  Input,
  VStack,
  useToast,
} from "@chakra-ui/react";
import React, { useState, useCallback } from "react";
import ComponentContainer from "../../../common/ComponentContainer";
import FormHeader from "../../../common/FormHeader";
import { HTTP_VERB } from "../../../enums";
import { getBaseUrl, getDefaultFetchOptions } from "../../../utils";
import PrettyPrintJson from "../../../common/PrettyPrintJson";
import OneTicketTransferMetrics from "./OneTicketTransferMetrics";

const OneTicketTransferHome = () => {
  const invoiceIdRegex = /([0-9,])+/;
  const [invoiceIds, setInvoiceIds] = useState("");
  const [isFetching, setIsFetching] = useState(false);
  const [results, setResults] = useState("");
  const toast = useToast();
  const renderToast = useCallback((toast, isError, title, desc) => {
    let status = "success";
    if (isError) {
      status = "error";
    }
    toast({
      title: title,
      description: desc,
      status: status,
      duration: 3000,
      isClosable: true,
    });
  }, []);
  const handleInvoiceIdInputChange = (text) => {
    if (!text.match(invoiceIdRegex)) {
      text = "";
    }
    setInvoiceIds(text);
  };
  const isSubmitDisabled = () => {
    return invoiceIds.length === 0;
  };
  const onSubmitClick = async () => {
    setIsFetching(true);
    const uri = `${getBaseUrl()}/invoice/bulktransfer`;
    const body = invoiceIds.split(",").map((x) => x.trim());
    let request = await fetch(uri, {
      ...getDefaultFetchOptions(HTTP_VERB.POST),
      body: JSON.stringify([...body]),
    });
    if (request.ok) {
      let response = await request.json();
      renderToast(toast, false, "Invoices Submitted", "See results below");
      setResults(response);
    } else {
      let err = await request.json();
      renderToast(toast, true, "Error Submitting Invoices", err?.detail);
    }
    setIsFetching(false);
  };
  const onClearClick = () => {
    setResults("");
    setInvoiceIds("");
  };
  const onUpdateStatusClick = async () => {
    setIsFetching(true);
    const uri = `${getBaseUrl()}/invoice/bulkstatus`;
    const body = invoiceIds.split(",").map((x) => x.trim());
    try {
      let request = await fetch(uri, {
        ...getDefaultFetchOptions(HTTP_VERB.POST),
        body: JSON.stringify([...body]),
      });
      if (request.ok) {
        let response = await request.json();
        renderToast(
          toast,
          false,
          "Status Updates Performed",
          "See results below"
        );
        setResults(response);
      } else {
        let err = await request.json();
        renderToast(toast, true, "Error checking invoice Status", err?.detail);
      }
    } catch (error) {
      renderToast(toast, true, "Error checking invoice Status", "Server error");
      console.log(error);
    }

    setIsFetching(false);
  };
  return (
    <ComponentContainer width={1000}>
      <OneTicketTransferMetrics />
      <FormHeader
        title={"Initiate 1Ticket Transfer by Invoice ID"}
        subTitle={
          "Submit InvoiceID to 1Ticket to begin transfer process.  You may enter a comma seperated list"
        }
      />
      <VStack align={"flex-start"} p="2">
        <FormControl>
          <FormLabel>Invoice ID</FormLabel>
          <FormControl>
            <Input
              variant={"filled"}
              value={invoiceIds}
              type={"text"}
              onChange={(e) => handleInvoiceIdInputChange(e.target.value)}
            />
          </FormControl>
        </FormControl>
        <HStack>
          <Button
            isDisabled={isSubmitDisabled()}
            colorScheme={"teal"}
            isLoading={isFetching}
            onClick={() => onSubmitClick()}
          >
            Submit Transfer
          </Button>
          <Button
            isDisabled={isSubmitDisabled()}
            colorScheme={"teal"}
            isLoading={isFetching}
            onClick={() => onUpdateStatusClick()}
          >
            Check Status
          </Button>
        </HStack>
      </VStack>
      {results && (
        <Button colorScheme={"teal"} onClick={() => onClearClick()}>
          Clear Results
        </Button>
      )}
      {results && (
        <Box>
          <PrettyPrintJson data={results} />
        </Box>
      )}
    </ComponentContainer>
  );
};

export default OneTicketTransferHome;
