import { FormControl, FormLabel, VStack } from "@chakra-ui/react";
import React, { useState, useEffect } from "react";
import GenericFormContainer from "../../common/GenericForm/GenericFormContainer";
import { HTTP_VERB } from "../../enums";
import { getBaseUrl, getDefaultFetchOptions } from "../../utils";
import Select from "react-select";

const DisableUser = () => {
  const [userName, setUserName] = useState("");
  const [isFetching, setIsFetching] = useState(false);
  const [resultMessage, setResultMessage] = useState("");
  const [isError, setIsError] = useState(false);
  const [users, setUsers] = useState([]);
  // TODO: Create a UserSelect component
  useEffect(() => {
    async function fetchUsers() {
      const uri = `${getBaseUrl()}/users?isDisabled=false`;
      let result = await fetch(uri, getDefaultFetchOptions(HTTP_VERB.GET));
      if (result.ok) {
        let userResults = await result.json();
        let options = userResults.map((v) => {
          return { value: v.userName, label: v.userName };
        });

        setUsers(options);
      }
    }
    fetchUsers();
  }, []);
  const submit = async () => {
    const uri = `${getBaseUrl()}/users/${userName}/disable`;
    setIsFetching(true);
    try {
      let result = await fetch(uri, getDefaultFetchOptions(HTTP_VERB.PUT));
      if (result.ok) {
        setIsError(false);
        setUserName("");
        setResultMessage(`User ${userName} disabled succesfully.`);
      } else {
        setIsError(true);
        setResultMessage(
          `Error encountered disabling user ${userName}.  Status Code: ${result.status}`
        );
      }
    } catch (error) {
      console.log(error);
    }
    setIsFetching(false);
  };
  const allowSubmit = () => {
    return userName.length > 0;
  };
  return (
    <GenericFormContainer
      formMaxWidth={"500px"}
      title={"Disable User"}
      subTitle="Disable a Portal User account"
      isResultError={isError}
      isSubmitDisabled={!allowSubmit()}
      onSubmitClick={() => submit()}
      isFetching={isFetching}
      resultMessage={resultMessage}
    >
      <VStack align={"flex-start"} p="2">
        <FormControl>
          <FormLabel htmlFor="userName">User Name</FormLabel>
          <Select
            id="userName"
            options={users}
            onChange={(e) => {
              setUserName(e.value);
            }}
          />
        </FormControl>
      </VStack>
    </GenericFormContainer>
  );
};

export default DisableUser;
