import React, { useState } from "react";
import {
  Box,
  Button,
  FormControl,
  FormLabel,
  HStack,
  Text,
  Textarea,
  useToast,
  Popover,
  PopoverTrigger,
  PopoverContent,
  PopoverArrow,
  PopoverCloseButton,
  PopoverBody,
  PopoverHeader,
} from "@chakra-ui/react";
import { getDefaultFetchOptions, getBaseUrl } from "../../utils";
import { HTTP_VERB } from "../../enums";
import ButtonDatePicker from "../../common/ButtonDatePicker";

const PaymentForm = ({ onSubmit }) => {
  const [maxSaleDate, setMaxSaleDate] = useState(new Date());
  const [paymentDate, setPaymentDate] = useState(new Date());
  const [notes, setNotes] = useState("");
  const [isSubmitting, setSubmitting] = useState(false);
  const toast = useToast();
  const handleSubmit = async () => {
    setSubmitting(true);
    let body = {
      maxSaleDate: maxSaleDate,
      paymentDate: paymentDate,
      notes: notes,
    };
    const uri = `${getBaseUrl()}/consignorpayments/generatePayment`;
    let request = await fetch(uri, {
      ...getDefaultFetchOptions(HTTP_VERB.POST),
      body: JSON.stringify(body),
    });
    if (request.ok) {
      toast({
        title: "Payment generation complete",
        description: "Payment reports generated.",
        status: "success",
        duration: 3000,
        isClosable: true,
      });
      onSubmit();
    } else {
      let errResponse = await request.json();
      toast({
        title: "Error generating Payment reports.",
        description: errResponse?.detail,
        status: "error",
        duration: 3000,
        isClosable: true,
      });
    }
    setSubmitting(false);
  };
  const handleConfirmClick = (onClose) => {
    onClose();
    handleSubmit();
  };
  return (
    <Box borderWidth={1} borderRadius={4} mb={5} p={2}>
      <HStack>
        <FormControl width={"200px"}>
          <FormLabel>Max Sale Date</FormLabel>
          <ButtonDatePicker
            date={maxSaleDate}
            onDateChange={(d) => setMaxSaleDate(d)}
          />
        </FormControl>
        <FormControl width={"200px"}>
          <FormLabel>Payment Date</FormLabel>
          <ButtonDatePicker
            date={paymentDate}
            onDateChange={(d) => setPaymentDate(d)}
          />
        </FormControl>
      </HStack>
      <FormControl mb={2}>
        <FormLabel>Notes</FormLabel>
        <Textarea
          placeholder="Enter notes here."
          value={notes}
          onChange={(e) => setNotes(e.target.value)}
        />
      </FormControl>
      <HStack>
        <Popover>
          {({ isOpen, onClose }) => (
            <>
              <PopoverTrigger>
                <Button
                  isDisabled={isSubmitting}
                  isLoading={isSubmitting}
                  colorScheme={"blue"}
                  width="250px"
                >
                  Submit
                </Button>
              </PopoverTrigger>
              <PopoverContent
                color="white"
                bg="blue.800"
                borderColor="blue.800"
              >
                <PopoverArrow />
                <PopoverHeader pt={4} fontWeight="bold">
                  Please verify your dates are correct
                </PopoverHeader>
                <PopoverCloseButton />
                <PopoverBody>
                  <Button
                    colorScheme={"blue"}
                    onClick={() => handleConfirmClick(onClose)}
                  >
                    My dates are correct.
                  </Button>
                </PopoverBody>
              </PopoverContent>
            </>
          )}
        </Popover>
        {isSubmitting && (
          <Text>Generating reports. This could take several minutes.</Text>
        )}
      </HStack>
    </Box>
  );
};

export default PaymentForm;
