import React, { useState, useEffect } from "react";
import { getBaseUrl, getDefaultFetchOptions } from "../utils";
import { HTTP_VERB } from "../enums";
import CompanySelectCore from "./CompanySelectCore";

const CompanySelectById = ({ companyId, onCompanyChange }) => {
  const [companies, setCompanies] = useState([]);
  const [fetching, setFetching] = useState(false);
  useEffect(() => {
    async function fetchData() {
      setFetching(true);
      const uri = `${getBaseUrl()}/companies`;
      let result = await fetch(uri, getDefaultFetchOptions(HTTP_VERB.GET));
      let data = await result.json();
      setCompanies(data);
      setFetching(false);
    }
    fetchData();
  }, []);
  return (
    <CompanySelectCore
      selectedValue={companyId}
      onValueChange={onCompanyChange}
      companies={companies}
      fetching={fetching}
      useIdAsValue={true}
    />
  );
};

export default CompanySelectById;
