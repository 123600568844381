import {
  Checkbox,
  FormControl,
  FormLabel,
  HStack,
  Input,
  Select,
  VStack,
} from "@chakra-ui/react";
import React, { useState, useEffect, useRef } from "react";
import GenericFormContainer from "../../common/GenericForm/GenericFormContainer";
import { getBaseUrl, getDefaultFetchOptions } from "../../utils";
import { Editor } from "@tinymce/tinymce-react";

const Blast = () => {
  const [content, setContent] = useState("");
  const [notificationType, setNotificationType] = useState(1);
  const [mustAccept, setMustAccept] = useState(false);
  const [subject, setSubject] = useState("");
  const [notificationTypes, setNotificationTypes] = useState([]);
  const [expirationDays, setExpirationDays] = useState(1);
  const [isFetching, setIsFetching] = useState(false);
  const [resultMessage, setResultMessage] = useState("");
  const [isError, setIsError] = useState(false);
  const [versionName, setVersionName] = useState("");
  const [isNotificationTypeTC, setIsNotificationTypeTC] = useState(false);
  const [isTC_Skip, setIsTC_Skip] = useState(false);
  const [tcContent, setTCContent] = useState("");
  const [isPrivacyPolicySkip, setIsPrivacyPolicySkip] = useState(false);
  const [privacypolicyContent, setPrivacyPolicyContent] = useState("");
  const [versionErrorMessage, setVersionErrorMessage] = useState("");
  const [defaultVersion, setDefaultVersion] = useState("");

  const versionInputRef = useRef(null);

  useEffect(() => {
    async function getNotificationTypes() {
      setIsFetching(true);
      try {
        const uri = `${getBaseUrl()}/portal/notificationTypes`;
        let result = await fetch(uri, getDefaultFetchOptions("GET"));
        if (result.ok) {
          let types = await result.json();
          let options = [];
          for (const type of types) {
            options.push({ name: type.type, id: type.type_ID });
          }
          setNotificationTypes(options);
        } else {
          setIsError(true);
          setResultMessage("Error fetching Notification Types");
        }
      } catch (error) {
        console.log(error);
      }
      setIsFetching(false);
    }
    getNotificationTypes();
  }, []);
  const submit = async () => {
    setIsError(false);
    setResultMessage("");
    const uri = `${getBaseUrl()}/portal/blasts`;
    const body = {
      notificationType: notificationType,
      daysToExpiration: expirationDays,
      subject: subject,
      mustAccept: mustAccept,
      content: content,
      versionName: versionName,
      tc_Content: tcContent,
      privacyPolicy_Content: privacypolicyContent
    };
    setIsFetching(true);
    try {

      if (notificationType === "10" && isVersionLessThan(versionName, defaultVersion)) {
        setVersionErrorMessage('Version cannot be lower than the default version');
        versionInputRef.current.scrollIntoView({ behavior: 'smooth' });
        setTimeout(() => {
          setVersionErrorMessage('');
        }, 3000);
        setIsFetching(false); 
        return; 
      }

      let result = await fetch(uri, {
        ...getDefaultFetchOptions("POST"),
        body: JSON.stringify(body),
      });
      if (result.ok) {
        setIsError(false);
        setResultMessage(`Notification submitted.`);
        setExpirationDays(1);
        setSubject("");
        setContent("");
        setVersionName("");
        setPrivacyPolicyContent("");
        setTCContent("")
        setNotificationType(1);
        setIsNotificationTypeTC(false);
        setIsTC_Skip(false)
        setIsPrivacyPolicySkip(false)
      } else {
        setIsError(true);
        setResultMessage(
          `Error encountered creating portal notification.  Status Code: ${result.status}`
        );
      }
    } catch (error) {
      console.log(error);
    }
    setIsFetching(false);
  };

  const isVersionLessThan = (version, defaultversion) => {
    const components1 = version.split(".");
    const components2 = defaultversion.split(".");

    for (let i = 0; i < Math.max(components1.length, components2.length); i++) {
        const num1 = parseInt(components1[i]) || 0;
        const num2 = parseInt(components2[i]) || 0;

        if (num1 < num2) {
            return true;
        } else if (num1 > num2) {
            return false;
        }
    }

    return false; // Equal or version1 is higher
};

  const OnNotificationChange = async (e) => {
    setNotificationType(e.target.value);
    if (e.target.value === "10") {
      setVersionDefaultValue();
      setIsNotificationTypeTC(true);
    }
    else {
      setIsNotificationTypeTC(false);
    }
  }

  const setVersionDefaultValue = async () => {
    setIsFetching(true);
    try {
      const uri = `${getBaseUrl()}/portal/getDefaultVersionValue`;
      let result = await fetch(uri, getDefaultFetchOptions("GET"));
      if (result.ok) {
        let version = await result.text();
        setDefaultVersion(version)
        setVersionName(version);
      } else {
        setIsError(true);
        setResultMessage("Error fetching Notification Types");
      }
    } catch (error) {
      console.log(error);
    }
    setIsFetching(false);
  }


  const handleChange = (event) => {
    const inputValue = event.target.value;

    const regex = /^[0-9]*\.?[0-9]*$/;

    if (regex.test(inputValue) || inputValue === '') {
      setVersionName(inputValue);
    }
  };

  const handleKeyDown = (event) => {
    const allowedKeys = ['0', '1', '2', '3', '4', '5', '6', '7', '8', '9', '.', 'ArrowLeft', 'ArrowRight', 'End', 'Home', 'Delete', 'Backspace'];
    if (!allowedKeys.includes(event.key)) {
      event.preventDefault();
    }
  };

  const allowSubmit = () => {
    if (isNotificationTypeTC) {
      return versionName.length > 0 ?
        (isTC_Skip ? privacypolicyContent.length > 0 :
          (isPrivacyPolicySkip ? tcContent.length > 0 :
            (tcContent.length > 0 || privacypolicyContent.length > 0)))
        : false;
    }
    else
      return content.length > 0;
  };
  const renderSelectItems = () => {
    if (notificationTypes.length === 0) {
      return;
    }
    return notificationTypes.map((v) => {
      return (
        <option key={v.id} value={v.id}>
          {v.name}
        </option>
      );
    });
  };
  const renderExpirationDays = () => {
    let output = [];
    for (let i = 1; i <= 30; i++) {
      output.push(
        <option key={i} value={i}>
          {i}
        </option>
      );
    }
    return output;
  };
  return (
    <GenericFormContainer
      formMaxWidth={"800px"}
      title={"Portal Blast"}
      subTitle="To preview notification select File then select Preview"
      isResultError={isError}
      isSubmitDisabled={!allowSubmit()}
      onSubmitClick={() => submit()}
      isFetching={isFetching}
      resultMessage={resultMessage}
    >
      <VStack spacing={3} align="start">
        <HStack spacing={3} p={"2"}>
          <FormControl >
            <FormLabel htmlFor="notificationSelect">Notification Type</FormLabel>
            <Select
              id="notificationSelect"
              isDisabled={isFetching}
              variant={"filled"}
              value={notificationType}
              onChange={OnNotificationChange}
            >
              {renderSelectItems()}
            </Select>
          </FormControl>
          <FormControl>
            <FormLabel htmlFor="expirationSelect">
              Days until Expiration
            </FormLabel>
            <Select
              id="expirationSelect"
              variant={"filled"}
              onChange={(e) => setExpirationDays(e.target.value)}
              value={expirationDays}
            >
              {renderExpirationDays()}
            </Select>
          </FormControl>
          <FormControl verticalAlign={"middle"}>
            <FormLabel>&nbsp;</FormLabel>
            <Checkbox onChange={(e) => setMustAccept(e.target.checked)}>
              Accept Required
            </Checkbox>
          </FormControl>
        </HStack>
      </VStack>
      {isNotificationTypeTC &&
        <VStack spacing={3} align="end">
          <HStack spacing={1} p={"2"}>
            <FormControl>
              <FormLabel htmlFor="versionInput">Type a version name</FormLabel>
              <Input
                id="versionInput"
                type="text"
                variant="filled"
                disabled={isFetching}
                value={versionName}
                onChange={handleChange}
                onKeyDown={handleKeyDown}
                ref={versionInputRef}
                placeholder="Ex 1.0"
              />
              <p style={{ color: "red" }}>{versionErrorMessage}</p>
            </FormControl>
          </HStack>
        </VStack>
      }
      <FormControl p="2">
        <FormLabel htmlFor="subjectInput">Subject</FormLabel>
        <Input
          id="subjectInput"
          type="text"
          maxLength={255}
          variant="filled"
          placeholder="Enter Subject"
          value={subject}
          onChange={(e) => setSubject(e.target.value)}
        />
      </FormControl>

      {!isNotificationTypeTC && <VStack align={"flex-start"} p="2" minHeight={"300px"}>
        <Editor
          tinymceScriptSrc={process.env.PUBLIC_URL + "/tinymce/tinymce.min.js"}
          value={content}
          onEditorChange={(v) => setContent(v)}
          init={{
            height: 300,
            menubar: true,
            plugins: [
              "advlist autoresize autolink lists image charmap link preview anchor",
              "searchreplace",
              "insertdatetime table paste",
            ],
            toolbar:
              "undo redo | formatselect | " +
              "bold italic backcolor | alignleft aligncenter " +
              "alignright alignjustify | bullist numlist outdent indent | " +
              "removeformat",
            content_style:
              "body { font-family:Helvetica,Arial,sans-serif; font-size:14px }",
          }}
        />
      </VStack>
      }
      {isNotificationTypeTC &&
        <>
          <HStack p={"2"}>
            <FormControl>
              <FormLabel mb={0}>Terms and Conditions</FormLabel>
            </FormControl>
            <FormControl align="end">
              <Checkbox disabled={isPrivacyPolicySkip} onChange={(e) => setIsTC_Skip(e.target.checked)}>
                Check to skip this update
              </Checkbox>
            </FormControl>
          </HStack>
          <VStack align={"flex-start"} p="2" minHeight={"300px"}>
            <Editor
              tinymceScriptSrc={process.env.PUBLIC_URL + "/tinymce/tinymce.min.js"}
              value={isTC_Skip ? "" : tcContent}
              onEditorChange={(v) => setTCContent(v)}
              disabled={isTC_Skip}
              init={{
                //height: 300,
                menubar: true,
                plugins: [
                  "advlist autoresize autolink lists image charmap link preview anchor",
                  "searchreplace",
                  "insertdatetime table paste",
                ],
                toolbar:
                  "undo redo | formatselect | " +
                  "bold italic backcolor | alignleft aligncenter " +
                  "alignright alignjustify | bullist numlist outdent indent | " +
                  "removeformat",
                //autoresize_bottom_margin: 16,
                content_style:
                  "body { font-family:Helvetica,Arial,sans-serif; font-size:14px;  height: 32px !important; overflow: auto !important; }",
              }}
            />
          </VStack>

          <HStack p={"2"}>
            <FormControl>
              <FormLabel mb={0} >Privacy Policy</FormLabel>
            </FormControl>
            <FormControl align="end">
              <Checkbox disabled={isTC_Skip} onChange={(e) => setIsPrivacyPolicySkip(e.target.checked)}>
                Check to skip this update
              </Checkbox>
            </FormControl>
          </HStack>
          <VStack align={"flex-start"} p="2" minHeight={"300px"}>
            <Editor
              tinymceScriptSrc={process.env.PUBLIC_URL + "/tinymce/tinymce.min.js"}
              value={isPrivacyPolicySkip ? "" : privacypolicyContent}
              onEditorChange={(v) => setPrivacyPolicyContent(v)}
              disabled={isPrivacyPolicySkip}
              init={{
                //height: 300,
                menubar: true,
                plugins: [
                  "advlist autoresize autolink lists image charmap link preview anchor",
                  "searchreplace",
                  "insertdatetime table paste",
                ],
                toolbar:
                  "undo redo | formatselect | " +
                  "bold italic backcolor | alignleft aligncenter " +
                  "alignright alignjustify | bullist numlist outdent indent | " +
                  "removeformat",
                content_style:
                  "body { font-family:Helvetica,Arial,sans-serif; font-size:14px; height: 32px !important; overflow: auto !important;}",
              }}
            />
          </VStack>
        </>
      }
    </GenericFormContainer>
  );
};

export default Blast;
