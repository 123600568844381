import React, { useState } from "react";
import { FormControl, FormLabel, VStack, Input, useToast } from "@chakra-ui/react";
import GenericFormContainer from "../../common/GenericForm/GenericFormContainer";
import { HTTP_VERB } from "../../enums";
import { getBaseUrl, getDefaultFetchOptions } from "../../utils";
import EmailOptOutList from "./EmailOptOutList";

const EmailOptOut = ({ username }) => {
    const [isFetching, setIsFetching] = useState(false);
    const [resultMessage, setResultMessage] = useState("");
    const [isError, setIsError] = useState(false);
    const [email, setEmail] = useState("");
    const [reloadList, setReloadList] = useState(true);
    const toast = useToast();

    const allowSubmit = () => {
        return true;
    };

    const submit = async () => {
        setIsFetching(true);
        setReloadList(false);
        
        const uri = `${getBaseUrl()}/users/OptOutNotficationsByEmailAddress?emailAddress=${email}&UserName=${username}`;
        let result = await fetch(uri, getDefaultFetchOptions(HTTP_VERB.POST));
        try {
            if (result.ok) {
                setIsError(false);
                setEmail('');
                toast({
                    title: `Successfully Opted Out Notifications from ${email}.`,
                    status: "success",
                    duration: 3000,
                    isClosable: true,
                });
                setReloadList(true);
            } else {
                setIsError(true);
                let responseMessage = await result.text();
                setResultMessage(
                    `Unable to Opt Out Email Address ${email} ${responseMessage}`
                );
            }
        } catch (error) {
            console.log("ERROR: \n" + error);
        }
        setIsFetching(false);
    };

    return (
        <>
            <GenericFormContainer
                formMaxWidth={"500px"}
                title={"Email OptOut User"}
                subTitle="Opting Out Email Address for Delete Listings"
                isResultError={isError}
                isSubmitDisabled={!allowSubmit()}
                onSubmitClick={() => submit()}
                isFetching={isFetching}
                resultMessage={resultMessage}
            >
                <VStack>
                    <FormControl>
                        <FormLabel htmlFor="cloneSource" style={{ padding: "10px 0px 0px 10px" }}>
                            Email Address
                        </FormLabel>
                        <Input
                            id="emailAddress"
                            type="text"
                            value={email}
                            onChange={(e) => setEmail(e.target.value)}
                            placeholder="Enter Email Address"
                            variant={"filled"}
                        />
                    </FormControl>
                </VStack>
            </GenericFormContainer>
            {reloadList && <EmailOptOutList />}
        </>
    );
};

export default EmailOptOut;
