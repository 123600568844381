import React from "react";
import { FormLabel, FormControl, Select } from "@chakra-ui/react";

const ExchangeSelectCore = ({
  title,
  exchanges,
  fetching,
  onValueChange,
  selectedValue,
  useIdAsValue,
  width,
}) => {
  const renderSelectItems = () => {
    if (!exchanges || exchanges.length === 0) {
      return;
    }
    let options = [];
    for (const exchange of exchanges) {
      if (useIdAsValue) {
        options.push({
          name: exchange.exchange_Name,
          id: exchange.exchange_ID,
        });
      } else {
        options.push({ name: exchange, id: exchange });
      }
    }
    return options.map((v, i) => {
      return (
        <option key={i} value={v.id}>
          {v.name}
        </option>
      );
    });
  };
  const handleChange = (val) => {
    if (onValueChange) {
      onValueChange(val);
    }
  };
  return (
    <FormControl width={width}>
      <FormLabel htmlFor="exchangeSelect">{title}</FormLabel>
      <Select
        width={width}
        id="companySelect"
        isDisabled={fetching}
        variant={"filled"}
        onChange={(e) => handleChange(e.target.value)}
        value={selectedValue}
      >
        <option value="">Select Company</option>
        {renderSelectItems()}
      </Select>
    </FormControl>
  );
};

export default ExchangeSelectCore;
