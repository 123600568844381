import {
  Box,
  HStack,
  Stat,
  StatLabel,
  StatNumber,
  useToast,
} from "@chakra-ui/react";
import React, { useEffect, useState, useCallback } from "react";
import FormHeader from "../../../common/FormHeader";
import { HTTP_VERB } from "../../../enums";
import { getBaseUrl, getDefaultFetchOptions } from "../../../utils";

const initialMetrics = {
  total: 0,
  completed: 0,
  incomplete: 0,
  failed: 0,
  completedPercent: 0,
};

const OneTicketTransferMetrics = () => {
  const toast = useToast();
  const renderToast = useCallback((toast, isError, title, desc) => {
    let status = "success";
    if (isError) {
      status = "error";
    }
    toast({
      title: title,
      description: desc,
      status: status,
      duration: 3000,
      isClosable: true,
    });
  }, []);
  useEffect(() => {
    (async () => {
      const uri = `${getBaseUrl()}/invoice/metrics`;
      let request = await fetch(uri, getDefaultFetchOptions(HTTP_VERB.GET));
      if (request.ok) {
        let response = await request.json();
        setMetrics(response);
      } else {
        let response = await request.json();
        renderToast(toast, true, "Error fetching metrics", response?.detail);
      }
    })();
  }, [renderToast, toast]);
  const [metrics, setMetrics] = useState(initialMetrics);
  return (
    <Box mb={10}>
      <FormHeader
        title={"1Ticket Transfer Metrics"}
        subTitle="Statistics gathered for past 24hours"
      />
      <HStack padding={2} spacing={5}>
        <Stat textAlign={"center"}>
          <StatLabel>Total Invoices</StatLabel>
          <StatNumber>{metrics.total}</StatNumber>
        </Stat>
        <Stat textAlign={"center"}>
          <StatLabel>Completed</StatLabel>
          <StatNumber>{metrics.completed}</StatNumber>
        </Stat>
        <Stat textAlign={"center"}>
          <StatLabel>Pending</StatLabel>
          <StatNumber>{metrics.incomplete}</StatNumber>
        </Stat>
        <Stat textAlign={"center"}>
          <StatLabel>Failed</StatLabel>
          <StatNumber>{metrics.failed}</StatNumber>
        </Stat>
        <Stat textAlign={"center"}>
          <StatLabel>Complete Percentage</StatLabel>
          <StatNumber>{Math.round(metrics.completedPercent * 100)}%</StatNumber>
        </Stat>
      </HStack>
    </Box>
  );
};

export default OneTicketTransferMetrics;
