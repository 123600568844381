import React, { useEffect, useState } from "react";
import TableCore from "../../../common/TableCore";
import { Box } from "@chakra-ui/react";

const createInvoice = (invoice) => {
  return {
    id: invoice.invoice_ID,
    ...invoice,
  };
};
const InvoicesTable = ({ invoices, onRowSelect, mode }) => {
  const [local, setLocal] = useState();
  useEffect(() => {
    if (!invoices || !invoices.length) {
      return;
    }
    let mapped = invoices.map((x) => createInvoice(x));
    setLocal(mapped);
  }, [invoices]);
  const [search, setSearch] = useState("");
  const getData = () => {
    if (!search) {
      return local;
    }
    return local.filter((x) => {
      return JSON.stringify(x).toLowerCase().includes(search.toLowerCase());
    });
  };
  let data = () => {
    return {
      nodes: getData(),
    };
  };
  const handleRowSelect = (action, state) => {
    if (onRowSelect) {
      onRowSelect(action, state);
    }
  };

  const columns = [
    {
      label: "Invoice ID",
      renderCell: (item) => item.invoice_ID,
      select: onRowSelect !== undefined,
    },
    {
      label: "Event Date",
      renderCell: (item) => new Date(item.event_Date).toLocaleDateString(),
    },
    {
      label: "Company",
      renderCell: (item) => item.company_Name,
    },
    {
      label: "Exchange",
      renderCell: (item) => item.exchange_Name,
    },
    {
      label: "Headliner",
      renderCell: (item) => item.event_Name,
    },
  ];
  return (
    <Box mt={5}>
      {local && (
        <TableCore
          columns={columns}
          data={data()}
          height={"800px"}
          width={"980px"}
          onSearchChange={(e) => setSearch(e)}
          pageSize={12}
          showSearch={true}
          mode={mode}
          onRowSelect={handleRowSelect}
        />
      )}
    </Box>
  );
};

export default InvoicesTable;
