import React, { useState, useEffect, useCallback } from "react";
import { Box, Heading, HStack, Icon, Text, useToast } from "@chakra-ui/react";
import { FcInfo } from "react-icons/fc";
import { getDefaultFetchOptions, getBaseUrl } from "../../utils";
import PaymentPendingForm from "./PaymentPendingForm";
import PaymentPendingFiles from "./PaymentPendingFiles";
import { HTTP_VERB } from "../../enums";

const PaymentPending = () => {
  const [files, setFiles] = useState([]);
  const toast = useToast();
  const getFiles = useCallback(() => {
    (async () => {
      const uri = `${getBaseUrl()}/consignorpayments/paymentpendingfiles`;
      let request = await fetch(uri, getDefaultFetchOptions(HTTP_VERB.GET));
      if (request.ok) {
        let response = await request.json();
        setFiles(response);
      } else {
        let errResponse = await request.json();
        toast({
          title: "Error fetching existing Payment Pending files.",
          description: errResponse?.detail,
          status: "error",
          duration: 3000,
          isClosable: true,
        });
      }
    })();
  }, [toast]);
  useEffect(() => {
    getFiles();
  }, [getFiles]);
  const handleSubmit = () => {
    getFiles();
  };
  return (
    <Box
      width={"950px"}
      height={"500px"}
      mb={"10px"}
      mr="10px"
      borderWidth={1}
      borderRadius={4}
      boxShadow="lg"
      textAlign="left"
      p={2}
    >
      <Heading as={"h2"} size="md" mb="1" ml={2}>
        Step 1: Payment Pending
      </Heading>
      <HStack ml={2} mr={2} mb={5} borderWidth={1} borderRadius={4} p={2}>
        <Icon as={FcInfo} />
        <Text fontSize={"xs"} m="0" as={"span"}>
          Generate and View Payment Pending Reports
        </Text>
      </HStack>
      <PaymentPendingForm onSubmit={handleSubmit} />
      <PaymentPendingFiles files={files} />
    </Box>
  );
};

export default PaymentPending;
