import React from "react";
import { HStack, Heading, Icon, Text } from "@chakra-ui/react";
import { FcInfo } from "react-icons/fc";

const FormHeader = ({ title, subTitle }) => {
  return (
    <>
      <Heading as={"h2"} size="md" mb="1" ml={2}>
        {title}
      </Heading>
      {subTitle && (
        <HStack ml={2} mr={2} borderWidth={1} borderRadius={4} p={2}>
          <Icon as={FcInfo} />
          <Text fontSize={"xs"} m="0" as={"span"}>
            {subTitle}
          </Text>
        </HStack>
      )}
    </>
  );
};

export default FormHeader;
