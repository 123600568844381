import React from "react";
import {
  Button,
  Text,
  VStack,
  Alert,
  AlertIcon,
  AlertDescription,
} from "@chakra-ui/react";
import FormHeader from "../FormHeader";
import ComponentContainer from "../ComponentContainer";

const GenericFormContainer = ({
  formMaxWidth,
  isFetching,
  isResultError,
  isSubmitDisabled,
  onSubmitClick,
  resultMessage,
  subTitle,
  title,
  children,
  ...rest
}) => {
  const getStatus = () => {
    if (isResultError) {
      return "error";
    }
    return "success";
  };
  const displaySupportLabel = () => {
    if (isResultError) {
      return (
        <>
          {resultMessage.toLowerCase().indexOf("status code: 403") > -1 && (
            <Text ml={"5px"} fontSize={"sm"} color="red">
              Your account does not have permision for this feature.
            </Text>
          )}
          <Text ml={"5px"} fontSize={"sm"} color="red">
            Contact IT Support at support@stagefronttickets.com
          </Text>
        </>
      );
    }
  };
  return (
    <ComponentContainer
      maxWidth={formMaxWidth}
      mb={"10px"}
      mr="10px"
      borderWidth={1}
      borderRadius={4}
      boxShadow="lg"
      textAlign="left"
      p={2}
      {...rest}
    >
      <FormHeader title={title} subTitle={subTitle} />
      {children}
      <VStack p="2" align={"flex-start"}>
        {onSubmitClick && (
          <Button
            isDisabled={isSubmitDisabled}
            colorScheme={"teal"}
            isLoading={isFetching}
            onClick={() => onSubmitClick()}
          >
            Submit
          </Button>
        )}
        {resultMessage.length > 0 && (
          <Alert status={getStatus()}>
            <AlertIcon />
            <AlertDescription>{resultMessage}</AlertDescription>
          </Alert>
        )}
        {displaySupportLabel()}
      </VStack>
    </ComponentContainer>
  );
};

export default GenericFormContainer;
