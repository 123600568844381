import React from "react";
import { Box, Text, HStack } from "@chakra-ui/react";
import { Icon } from "@chakra-ui/react";
import { FaWrench } from "react-icons/fa";

const Logo = (props) => {
  return (
    <Box {...props}>
      <HStack>
        <Icon as={FaWrench} boxSize={"1.5em"} />
        <Text fontSize="lg" fontWeight={"bold"}>
          SF Admin
        </Text>
      </HStack>
    </Box>
  );
};

export default Logo;
