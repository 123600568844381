import React, { useState } from "react";
import {
  Alert,
  AlertDescription,
  AlertIcon,
  Badge,
  Box,
  HStack,
  List,
  ListItem,
  Text,
  UnorderedList,
  VStack,
} from "@chakra-ui/react";
import { DateTime } from "luxon";

const EventMapping = ({
  posnetEvent,
  existingMapping,
  events,
  onEventSelect,
}) => {
  const [selectedEventId, setSelectedEventId] = useState();
  const handleEventSelect = (eventId) => {
    let eventToSet = eventId;
    if (selectedEventId === eventId) {
      eventToSet = undefined;
    }
    setSelectedEventId(eventToSet);
    onEventSelect(eventToSet);
  };
  const renderSearchResult = (item) => {
    let style = {};
    if (item.id === selectedEventId) {
      style = {
        fontWeight: "bold",
      };
    }
    return (
      <ListItem key={item.id} onClick={() => handleEventSelect(item.id)}>
        <List {...style} cursor={"pointer"} userSelect={"none"}>
          <ListItem>Name: {item.name}</ListItem>
          <ListItem>EventId: {item.id}</ListItem>
          <ListItem>StubHubId: {item.legacyStubHubEventId}</ListItem>
          <ListItem>Type: {item.type}</ListItem>
          <ListItem>
            Date:{" "}
            {DateTime.fromISO(item.eventDate).toLocaleString(
              DateTime.DATETIME_MED
            )}
          </ListItem>
          <ListItem>Venue: {item.venue}</ListItem>
          <ListItem>City/State: {`${item.city} ${item.state}`}</ListItem>
          <ListItem>StubHub Url: {item.stubHubUrl}</ListItem>
        </List>
      </ListItem>
    );
  };
  const renderSearchResults = () => {
    if (!events || events.length === 0) {
      return <Text>No Events found matching search criteria</Text>;
    }
    return events.map((v, i) => {
      return renderSearchResult(v);
    });
  };
  return (
    <VStack align={"left"}>
      {posnetEvent && (
        <Box p={3} borderWidth="1px" borderRadius="lg">
          <HStack spacing={20} align={"flex-start"}>
            <VStack align={"left"}>
              <Badge variant="outline" colorScheme="green">
                Event Searched
              </Badge>
              <List>
                <ListItem>Posnet EventID: {posnetEvent.event_ID}</ListItem>
                <ListItem>Name: {posnetEvent.event_Name}</ListItem>
                <ListItem>
                  Date: {new Date(posnetEvent.event_Date).toLocaleDateString()}{" "}
                  {posnetEvent.event_Time}
                </ListItem>
                <ListItem>Venue: {posnetEvent.venue_Name}</ListItem>
              </List>
            </VStack>

            <VStack align={"left"}>
              <Badge variant="outline" colorScheme="green">
                Existing Mapping
              </Badge>
              {existingMapping && (
                <List>
                  <ListItem>Viagogo EventID: {existingMapping.id}</ListItem>
                  <ListItem>
                    StubHubId: {existingMapping.legacyStubHubEventId}
                  </ListItem>
                  <ListItem>Name: {existingMapping.name}</ListItem>
                  <ListItem>
                    Date:{" "}
                    {DateTime.fromISO(existingMapping.eventDate).toLocaleString(
                      DateTime.DATETIME_MED
                    )}
                  </ListItem>
                  <ListItem>Venue: {existingMapping.venue}</ListItem>
                  <ListItem>
                    City/State:{" "}
                    {`${existingMapping.city} ${existingMapping.state}`}
                  </ListItem>
                  <ListItem>StubHub Url: {existingMapping.stubHubUrl}</ListItem>
                </List>
              )}
              {!existingMapping && <Text>None</Text>}
            </VStack>
          </HStack>
        </Box>
      )}
      <Alert status={"info"}>
        <AlertIcon />
        <AlertDescription>
          Select an event from the table below to map with EventID entered.
        </AlertDescription>
      </Alert>
      <Box p={3} borderWidth="1px" borderRadius="lg">
        <Badge variant="outline" colorScheme="green">
          Search Results
        </Badge>
        <UnorderedList spacing={"10px"}>{renderSearchResults()}</UnorderedList>
      </Box>
    </VStack>
  );
};

export default EventMapping;
