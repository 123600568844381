import React, { useEffect, useState, useCallback } from "react";
import TableCore from "../../common/TableCore";
import { Box, Button, useToast } from "@chakra-ui/react";
import { getDefaultFetchOptions, getBaseUrl } from "../../utils";
import { HTTP_VERB, TABLE_MODE } from "../../enums";

const CredentialsList = () => {
  const [search, setSearch] = useState("");
  const [credentials, setCredentials] = useState([]);
  const toast = useToast();
  const renderToast = useCallback((toast, isError, title, desc) => {
    let status = "success";
    if (isError) {
      status = "error";
    }
    toast({
      title: title,
      description: desc,
      status: status,
      duration: 3000,
      isClosable: true,
    });
  }, []);
  useEffect(() => {
    async function getUsers() {
      const uri = `${getBaseUrl()}/api/credentials/mlb`;
      let request = await fetch(uri, getDefaultFetchOptions(HTTP_VERB.GET));
      if (request.ok) {
        let response = await request.json();
        response = response.map((v, i) => {
          v.key = i;
          v.showPassword = false;
          return v;
        });
        setCredentials(response);
      } else {
        renderToast(
          toast,
          false,
          "Fetching accounts failed",
          `Error fetching MLB Ballpark accounts`
        );
      }
    }
    getUsers();
  }, [renderToast, toast]);
  const handlePasswordShowClick = (data) => {
    let temp = [...credentials];
    temp = temp.map((v) => {
      if (v.credentialId === data.credentialId) {
        v.showPassword = true;
      } else {
        v.showPassword = false;
      }
      return v;
    });
    setCredentials(temp);
  };
  const renderCrawlButton = (data) => {
    return <Button onClick={() => handleCrawlClick(data)}>CRAWL</Button>;
  };
  const renderPassword = (data) => {
    if (data.showPassword) {
      return <span>{data.password}</span>;
    }
    return <Button onClick={() => handlePasswordShowClick(data)}>Show</Button>;
  };
  const renderLock = () => {
    if (data.loginLock) {
      return "Y";
    }
    return "N";
  };
  const handleCrawlClick = async (data) => {
    const uri = `${getBaseUrl()}/api/mlbcrawler/${data.credentialId}`;
    let request = await fetch(uri, getDefaultFetchOptions(HTTP_VERB.POST));
    if (request.ok) {
      renderToast(
        toast,
        false,
        "Crawling started",
        `MLB Ballpark Crawler launched for ${data.username}`
      );
    } else {
      let errResponse = await request.json();
      renderToast(toast, true, "Error launching crawler.", errResponse?.detail);
    }
  };
  const columns = [
    {
      label: "Username",
      renderCell: (item) => item.username,
      resize: true,
    },
    {
      label: "Password",
      renderCell: (item) => renderPassword(item),
      resize: true,
    },
    {
      label: "LastUpdated",
      renderCell: (item) => new Date(item.lastUpdatedDate).toLocaleDateString(),
      resize: true,
    },
    {
      label: "Company",
      renderCell: (item) => item.company,
      resize: true,
    },
    {
      label: "Locked",
      renderCell: (item) => renderLock(item),
      resize: true,
    },
    {
      label: "Crawl",
      renderCell: (item) => renderCrawlButton(item),
      resize: true,
    },
  ];

  const data = () => {
    let output = { nodes: [] };
    if (!credentials || credentials.length === 0) {
      return output;
    }
    if (!search) {
      output.nodes = credentials;
    }
    let data = credentials.filter((x) => {
      var searchTerm = search.toLowerCase();
      return (
        x.username.toLowerCase().includes(searchTerm) ||
        x.company.toLowerCase().includes(searchTerm)
      );
    });
    output.nodes = data;
    return output;
  };
  return (
    <Box>
      <TableCore
        columns={columns}
        data={data()}
        height={"800px"}
        width={"1200px"}
        onSearchChange={(e) => setSearch(e)}
        pageSize={10}
        mode={TABLE_MODE.VIEW_ONLY}
        showSearch={true}
      />
    </Box>
  );
};

export default CredentialsList;
