import React from "react";
import { useNavigate } from "react-router-dom";
import CompanySelectById from "../../common/CompanySelectById";

const SelectCustomer = ({ path }) => {
  const navigate = useNavigate();
  const handleCompanySelect = (val) => {
    navigate(`${path}${val}`);
  };

  return <CompanySelectById onCompanyChange={(e) => handleCompanySelect(e)} />;
};

export default SelectCustomer;
