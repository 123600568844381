import React, { useState, useEffect } from "react";
import {
  Table,
  Thead,
  Tbody,
  Tr,
  Th,
  Td,
  Button,
  Box,
  Text,
  useColorModeValue,
  Heading,
  Divider,
  Flex,
  Input,
} from "@chakra-ui/react";
import { HTTP_VERB } from "../../enums";
import { getBaseUrl, getDefaultFetchOptions } from "../../utils";
import VMActionButton from "./VMActionButton";

const ManageVM = () => {
  const [vms, setVms] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [isFetching, setIsFetching] = useState(false);
  const [error, setError] = useState(null);
  const [filterText, setFilterText] = useState("");
  const [resultMessage, setResultMessage] = useState("");
  const [isError, setIsError] = useState(false);

  const handleStartStopRestart = async (vm, event) => {
    const action = event.target.innerText.toLowerCase();
    setIsLoading(true);
    const uri = `${getBaseUrl()}/vm/manage`;
    try {
      const response = await fetch(uri, {
        ...getDefaultFetchOptions(HTTP_VERB.POST),
        body: JSON.stringify({
          id: vm.id,
          resourceGroup: vm.resourceGroup,
          vmName: vm.vmName,
          action: action,
        }),
      });

      if (!response.ok) {
        const responseJson = await response.json();
        const errorMessage = responseJson.detail
          ? responseJson.detail
          : responseJson.title;
        setIsError(true);
        setResultMessage(
          `Error encountered while ${action}ing VM ${vm.vmName} with Resource Group ${vm.resourceGroup}. Error Message: ${errorMessage}`
        );
      }

      const result = await fetchVms();
      setResultMessage(
        `${action} VM ${vm.vmName} with Resource Group ${vm.resourceGroup} Requested Successfully. Check back in a few minutes for status.`
      );
    } catch (error) {
      setIsError(true);
      setResultMessage(error.message);
    } finally {
      setIsLoading(false);
    }
  };

  const handleFilterChange = (event) => {
    setFilterText(event.target.value);
  };

  const filteredVms = vms.filter((vm) =>
    vm.vmName.toLowerCase().includes(filterText.toLowerCase())
  );

  const fetchVms = async () => {
    setResultMessage("");
    setIsFetching(true);
    setError(null);
    const uri = `${getBaseUrl()}/vm/fetch`;
    try {
      const response = await fetch(uri, getDefaultFetchOptions(HTTP_VERB.GET));
      if (!response.ok) {
        throw new Error("Failed to fetch VMs");
      }

      const data = await response.json();
      setVms(data);
    } catch (error) {
      setError(error.message);
    } finally {
      setIsFetching(false);
    }
  };

  useEffect(() => {
    fetchVms();
  }, []);

  const color = useColorModeValue("green", "red");

  return (
    <Box>
      <Heading as="h2" size="lg" padding={4}>
        Manage VMs
      </Heading>
      <Divider />
      {isFetching ? (
        <Text>Loading VMs...</Text>
      ) : error ? (
        <Text>Error: {error}</Text>
      ) : (
        <Flex direction="column">
          <Flex direction="row">
            <Input
              placeholder="Filter by VM Name"
              onChange={handleFilterChange}
              padding={4}
            />
            <Button
              ml={4}
              colorScheme="purple"
              onClick={fetchVms}              
            >
              Refresh
            </Button>
          </Flex>

          {resultMessage && (
            <Text color={isError ? "Red" : "Green"}>{resultMessage}</Text>
          )}
          <Table variant="striped" colorScheme="gray">
            <Thead>
              <Tr>
                <Th>ID</Th>
                <Th>Resource Group</Th>
                <Th>VM Name</Th>
                <Th>Status</Th>
                <Th>Start/Stop</Th>
                <Th>Restart</Th>
              </Tr>
            </Thead>
            <Tbody>
              {filteredVms.map((vm, index) => (
                <Tr key={index}>
                  <Td>{index + 1}</Td>
                  <Td>{vm.resourceGroup}</Td>
                  <Td>{vm.vmName}</Td>
                  <Td>{vm.status}</Td>
                  <Td>
                    {/* {["stopped", "running"].includes(vm.status) && (
                      <Button
                        onClick={(event) => handleStartStopRestart(vm, event)}
                        colorScheme={vm.status === "stopped" ? "green" : "red"}
                        isLoading={isLoading}
                        //variant={vm.status === "stopped" ? "solid" : "solid"}
                      >
                        {vm.status === "stopped" ? "Start" : "Stop"}
                      </Button>
                    )} */}
                    <VMActionButton
                      vm={vm}
                      onClickHandler={handleStartStopRestart}
                    />
                  </Td>
                  <Td>
                    {["stopped", "running", "updating", "error"].includes(vm.status) && (
                      <Button
                        onClick={(event) => handleStartStopRestart(vm, event)}
                        colorScheme="blue"
                        variant="solid"
                        isDisabled={
                          vm.status === "stopped" || vm.status === "updating"|| vm.status === "error"
                        }
                      >
                        Restart
                      </Button>
                    )}
                    {/* <VMActionButton vm={vm} onClickHandler={handleStartStopRestart} /> */}
                  </Td>
                </Tr>
              ))}
            </Tbody>
          </Table>
        </Flex>
      )}
    </Box>
  );
};

export default ManageVM;
