import React from "react";
import Sidebar from "../../common/Sidebar/Sidebar";
import { Box, Flex, Icon } from "@chakra-ui/react";
import Blast from "./Blast";
import { FaHome } from "react-icons/fa";
import { Routes, Route } from "react-router-dom";
import { Outlet } from "react-router-dom";

const subMenu = [
  {
    icon: <Icon as={FaHome} />,
    path: "",
    label: "Home",
  },
];

const PortalHome = () => {
  return (
    <Flex direction={"row"}>
      <Sidebar isOpen={true} title="Portal Management" linkItems={subMenu} />
      <Box h="full" p="4">
        <Flex direction={"column"}>
        <Routes>
            <Route path="/" element={<Blast />} />
          </Routes>
          <Outlet />
        </Flex>
      </Box>
    </Flex>
  );
};

export default PortalHome;
