import React, { useEffect, useState } from "react";
import TableCore from "../../common/TableCore";
import { useNavigate } from "react-router-dom";
import { getDefaultFetchOptions, getBaseUrl } from "../../utils";
import { HTTP_VERB, TABLE_MODE } from "../../enums";
import { Box, Spinner, Button, Input, HStack } from "@chakra-ui/react";
import { RepeatIcon, Search2Icon } from '@chakra-ui/icons'
const columns = [
  { label: "", renderCell: (item) => item.to },
  {
    label: "",
    renderCell: (item) => item.subject.toString(),
  },
  {
    label: "",
    renderCell: (item) => item.date.toString(),
  }
];

const AXSAuthCodes = () => {
  const [search, setSearch] = useState("");
  const [count, setCounts] = useState(0);
  const navigate = useNavigate();
  const [emailAXS, setEmailAXS] = useState([]);
  const [nextPageAXSToken, setNextPageAXSToken] = useState("");
  const [isFetching, setIsFetching] = useState(false);
  useEffect(() => {
    getLatestEmails();
  }, []);

  const getLatestEmails = () => {
    async function getAuthEmails() {
      setIsFetching(true);
      const uri = `${getBaseUrl()}/GMail/GetReadLabelAXSAuthCode`;
      let request = await fetch(uri, getDefaultFetchOptions(HTTP_VERB.GET));
      if (request.ok) {
        let response = await request.json();
        setEmailAXS(response);
        if (response.length > 0) {
          setCounts(response.length);
          setNextPageAXSToken(response[0]?.nextPageToken);
        }
      } else {
        console.log("Error fetching emailAXS");
      }
      setIsFetching(false);
    }
    getAuthEmails();
  };
  const data = () => {
    let output = { nodes: [] };
    if (!emailAXS || emailAXS.length === 0) {
      return output;
    }
    output.nodes = emailAXS;
    return output;
  };

  const fetchMoreRecords = () => {
    async function getNextEmails() {
      setIsFetching(true);
      if (nextPageAXSToken !== "") {
        const uri = `${getBaseUrl()}/GMail/GetNewEmailsListByPagination?type=AXS&pageToken=${nextPageAXSToken}&searchItem=${search}`;
        let request = await fetch(uri, getDefaultFetchOptions(HTTP_VERB.GET));
        if (request.ok) {
          let response = await request.json();
          if (response.length > 0) {
            setNextPageAXSToken(response[0]?.nextPageToken);
            setCounts(response.length);

          }
          setEmailAXS(prevRecords => [...emailAXS, ...response]);

        } else {
          console.log("Error fetching emails");
        }
        setIsFetching(false);
      }
    }
    getNextEmails();
  };

  const clearSearch = () => {
    setSearch("");
    setNextPageAXSToken("");
    setCounts(0);
    getLatestEmails();
    setIsFetching(false);
  };

  const searchRecords = () => {
    async function getSearchedEmails() {
      setIsFetching(true);
      if (nextPageAXSToken !== "") {
        const uri = `${getBaseUrl()}/GMail/GetReadLabelAXSAuthCode?searchEmail=${search}`;
        let request = await fetch(uri, getDefaultFetchOptions(HTTP_VERB.GET));
        if (request.ok) {
          let response = await request.json();
          setEmailAXS(response);
          if (response.length > 0) {
            setNextPageAXSToken(response[0]?.nextPageToken);
            setCounts(response.length);

          }
        }
        setIsFetching(false);
      }
    }
    getSearchedEmails();
  };

  const handleRowClick = (action, state) => {
    localStorage.removeItem("GmailAXSId");
    localStorage.setItem("GmailAXSId", `${action.payload.id}`);
    navigate({
      pathname: '/orders/axsView',
      search: `${action.payload.id}`,
    });
  };

  return (
    <Box>
      {isFetching && <Spinner stylr size='xl' />}
      <HStack spacing={2}>
        <Input
          id="searchId"
          placeholder="Search.."
          type="text"
          variant={"filled"}
          value={search}
          onChange={(e) => setSearch(e.target.value)}
        />
        <Button
          onClick={searchRecords}
          colorScheme={"teal"}
          variant="solid"
        >
          <Search2Icon />
        </Button>
        <Button colorScheme={"teal"} variant="solid" onClick={clearSearch}><RepeatIcon /></Button>
        {count > 14  &&
        <Button onClick={fetchMoreRecords} colorScheme={"teal"} variant="solid">
          More Records
        </Button> }
      </HStack>
      <TableCore
        columns={columns}
        data={data()}
        height={"800px"}
        width={"800px"}
        onRowSelect={handleRowClick}
        pageSize={15}
        mode={TABLE_MODE.CLICKABLE}
      />
    </Box>
  );
};

export default AXSAuthCodes;
