import React, { useEffect, useState } from "react";
import {
  Box,
  Icon,
  AlertDialog,
  AlertDialogOverlay,
  AlertDialogContent,
  AlertDialogHeader,
  AlertDialogBody,
  AlertDialogFooter,
  Button,
  useToast,
} from "@chakra-ui/react";
import TableCore from "../../common/TableCore";
import { getDefaultFetchOptions, getBaseUrl } from "../../utils";
import { HTTP_VERB, TABLE_MODE } from "../../enums";
import { FaMinusCircle } from "react-icons/fa";

const EmailOptOutList = () => {
  const [search, setSearch] = useState("");
  const [emails, setEmails] = useState([]);
  const [isOpen, setIsOpen] = useState(false);
  const [selectedEmail, setSelectedEmail] = useState(null);
  const toast = useToast();

  const columns = [
    {
      label: "Opt Out ID",
      renderCell: (item) => item.id,
    },
    {
      label: "Email Address",
      renderCell: (item) => item.emailAddress,
    },
    {
      label: "Notification Type",
      renderCell: (item) => item.notificationType,
    },
    {
      label: "Remove Opt Out",
      renderCell: (item) => (
        <span onClick={() => openConfirmDialog(item)}>
          <Icon as={FaMinusCircle} />
        </span>
      ),
    },
  ];

  useEffect(() => {
    getOptedOutEmailList();
  }, []);

  async function getOptedOutEmailList() {
    const uri = `${getBaseUrl()}/Users/NotficationsOptedOutList`;
    let request = await fetch(uri, getDefaultFetchOptions(HTTP_VERB.GET));
    if (request.ok) {
      let response = await request.json();
      setEmails(response);
    } else {
      console.log("Error fetching emailAddresses");
    }
  }

  const openConfirmDialog = (email) => {
    setSelectedEmail(email);
    setIsOpen(true);
  };

  const closeConfirmDialog = () => {
    setSelectedEmail(null);
    setIsOpen(false);
  };

  const handleDelete = async () => {
    if (selectedEmail) {
      const uri = `${getBaseUrl()}/users/ActiveNotficationsOptedOut?id=${
        selectedEmail.id
      }`;
      let request = await fetch(uri, getDefaultFetchOptions(HTTP_VERB.PUT));
      try {
        if (request.ok) {
          toast({
            title: `Successfully removed ${selectedEmail.emailAddress} from Notification Opt Out.`,
            status: "success",
            duration: 3000,
            isClosable: true,
          });
          getOptedOutEmailList();
        } else {
          let response = await request.json();
          toast({
            title: "Error removing Email from Notification Opt Out.",
            description: response?.detail,
            status: "error",
            duration: 3000,
            isClosable: true,
          });
        }
      } catch (error) {
        console.log(error);
        toast({
          title: "Error removing Email from Notification Opt Out.",
          status: "error",
          duration: 3000,
          isClosable: true,
        });
      }
    }
    setSelectedEmail(null);
    setIsOpen(false);
  };

  const data = () => {
    let output = { nodes: [] };
    if (!emails || emails.length === 0) {
      return output;
    }
    if (!search) {
      output.nodes = emails;
    }
    let data = emails.filter((x) => {
      return x.emailAddress.toLowerCase().includes(search.toLowerCase());
    });
    output.nodes = data;
    return output;
  };

  return (
    <Box>
      <TableCore
        columns={columns}
        data={data()}
        height={"800px"}
        width={"900px"}
        onSearchChange={(e) => setSearch(e)}
        pageSize={14}
        mode={TABLE_MODE.VIEW_ONLY}
        showSearch={true}
      />
      <AlertDialog isOpen={isOpen} onClose={closeConfirmDialog}>
        <AlertDialogOverlay />
        <AlertDialogContent>
          <AlertDialogHeader fontSize="lg" fontWeight="bold">
            Confirm Deletion
          </AlertDialogHeader>
          <AlertDialogBody>
            Are you sure you want to remove Email Opt Out?
          </AlertDialogBody>
          <AlertDialogFooter>
            <Button onClick={closeConfirmDialog}>Cancel</Button>
            <Button colorScheme="red" onClick={handleDelete} ml={3}>
              Delete
            </Button>
          </AlertDialogFooter>
        </AlertDialogContent>
      </AlertDialog>
    </Box>
  );
};

export default EmailOptOutList;
