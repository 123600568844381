import React from "react";
import Hero from "./Hero";
import { Box } from "@chakra-ui/react";
import logoImg from "../images/logo.png";

const Home = () => {
  return (
    <Box>
      <Hero
        headingText={"StageFront Admin Home"}
        detailsText="Welcome to the StageFront Admin Application, built to automate your tedious tasks."
        imgSrc={logoImg}
      />
    </Box>
  );
};

export default Home;
