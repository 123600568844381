import React, { useState } from "react";
import {
  FormControl,
  FormLabel,
  Input,
  VStack,
  Modal,
  ModalOverlay,
  ModalContent,
  Spinner,
  ModalFooter,
  ModalBody,
  Button,
  ModalCloseButton,
  useDisclosure,
  ModalHeader,
} from "@chakra-ui/react";
import GenericFormContainer from "../../common/GenericForm/GenericFormContainer";
import { HTTP_VERB } from "../../enums";
import { getBaseUrl, getDefaultFetchOptions } from "../../utils";
import EventMapping from "./EventMapping";

const EventSearch = () => {
  const [isFetching, setIsFetching] = useState(false);
  const [resultMessage, setResultMessage] = useState("");
  const [isError, setIsError] = useState(false);
  const [eventId, setEventId] = useState("");
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [searchResults, setSearchResults] = useState();
  const [posnetEvent, setPosnetEvent] = useState();
  const [existingMapping, setExistingMapping] = useState();
  const [selectedEventId, setSelectedEventId] = useState();
  const [searchTerm,setSearchTerm] = useState("");
  const allowSubmit = () => {
    if (!eventId || eventId.length < 2) {
      return false;
    }
    return true;
  };
  const search = async () => {
    setIsFetching(true);
    let encodedSearchTerm = encodeURIComponent(searchTerm);
    const uri = `${getBaseUrl()}/viagogoevents/search?posnetEventId=${eventId}&searchTerm=${encodedSearchTerm}`;
    try {
      let result = await fetch(uri, getDefaultFetchOptions(HTTP_VERB.GET));
      if (result.ok) {
        setIsError(false);
        setResultMessage("");
        let response = await result.json();
        setPosnetEvent(response.posnetEvent);
        setSearchResults(response.searchResults);
        setExistingMapping(response.existingMapping);
        onOpen();
      } else {
        setIsError(true);
        let errResponse = await result.json();
        setResultMessage(
          `Error searching Viagogo for EventId ${eventId}. ${errResponse.detail}`
        );
      }
    } catch (error) {
      console.log(error);
    }
    setIsFetching(false);
  };
  const submitMapping = async () => {
    setIsFetching(true);
    const uri = `${getBaseUrl()}/viagogoevents/mapEvent`;
    const viagogoEvent = searchResults.find((x) => x.id === selectedEventId);
    try {
      const body = {
        posnetEventId: eventId,
        viagogoEvent: viagogoEvent,
      };
      let result = await fetch(uri, {
        ...getDefaultFetchOptions(HTTP_VERB.POST),
        body: JSON.stringify(body),
      });
      if (result.ok) {
        setIsError(false);
        setResultMessage(
          `PosnetEvent ${eventId} mapped to ViagogoEvent ${selectedEventId}`
        );
        setSelectedEventId();
        setEventId("");
        setSearchTerm("");
      } else {
        setIsError(true);
        let errorResponse = await result.json();
        setResultMessage(
          `Error encountered when mapping PosnetEvent ${eventId} to ViagogoEvent ${selectedEventId}. ${errorResponse.detail}`
        );
      }
    } catch (error) {
      console.log(error);
    }
    setIsFetching(false);
    onClose();
  };
  return (
    <>
      <GenericFormContainer
        formMaxWidth={"500px"}
        width={"500px"}
        title={"Viagogo Search"}
        subTitle="Search for Event on Viagogo to map with StageFront event"
        isResultError={isError}
        isSubmitDisabled={!allowSubmit()}
        onSubmitClick={() => search()}
        isFetching={isFetching}
        resultMessage={resultMessage}
      >
        <VStack>
          <FormControl>
            <FormLabel htmlFor="eventIdInput">Event ID</FormLabel>
            <Input
              id="eventIdInput"
              onChange={(e) => setEventId(e.target.value)}
              placeholder={"Enter Event ID"}
              required={true}
              type="number"
              value={eventId}
              variant={"filled"}
            />
          </FormControl>
          <FormControl>
            <FormLabel htmlFor="searchTermInput">Event Name Search Term</FormLabel>
            <Input
              id="searchTermInput"
              onChange={(e) => setSearchTerm(e.target.value)}
              placeholder={"Enter Name Search Term"}
              required={false}
              type="text"
              value={searchTerm}
              variant={"filled"}
            />
          </FormControl>
        </VStack>
      </GenericFormContainer>
      <Modal
        isOpen={isOpen}
        onClose={onClose}
        scrollBehavior={"inside"}
        size={"4xl"}
      >
        <ModalOverlay />
        <ModalContent>
          {isFetching && <Spinner size="xl" />}
          {!isFetching}{" "}
          {
            <>
              <ModalHeader>Search Results</ModalHeader>
              <ModalBody>
                <EventMapping
                  posnetEvent={posnetEvent}
                  events={searchResults}
                  existingMapping={existingMapping}
                  onEventSelect={(e) => setSelectedEventId(e)}
                />
              </ModalBody>
            </>
          }
          <ModalCloseButton />
          <ModalFooter>
            {selectedEventId && (
              <Button colorScheme="teal" mr={3} onClick={submitMapping}>
                Map Event
              </Button>
            )}
            <Button colorScheme="red" mr={3} onClick={onClose}>
              Close
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </>
  );
};

export default EventSearch;
