import React, { useState, useRef } from "react";
import * as XLSX from "xlsx";
import GenericFormContainer from "../../common/GenericForm/GenericFormContainer";
import { HTTP_VERB } from "../../enums";
import { getBaseUrl, getDefaultFetchOptions } from "../../utils";
import InventoryList from "./InventoryList";
import { Button, List, ListItem, Tooltip, Icon, useToast } from "@chakra-ui/react";
import { FaQuestionCircle } from "react-icons/fa";

const ImportInventory = ({ username }) => {
    const [isFetching, setIsFetching] = useState(false);
    const [resultMessage, setResultMessage] = useState("");
    const [isError, setIsError] = useState(false);
    const [fileData, setFileData] = useState([]);
    const [fileId, setFileId] = useState(0);
    const [showInventories, setShowInventories] = useState(false);
    const [fileStatus, setFileStatus] = useState("Rejected");
    const [fileName, setFileName] = useState("");
    const toast = useToast();
    const toastIdRef = useRef();

    const allowSubmit = () => fileStatus === "Accepted" ? true : false;

    async function callLogFileStatusAPI(file, isValid) {
        setIsFetching(true);
        setFileName(file.name);
        const formData = new FormData();
        formData.append("file", file); // Add the file to the form data
        formData.append("uploadedBy", username);
        formData.append("fileId", file.name);
        formData.append("fileStatus", isValid ? "Accepted" : "Rejected");

        const uri = `${getBaseUrl()}/Companies/logFileStatus`;
        try {
            let result = await fetch(uri, {
                method: HTTP_VERB.POST,
                mode: "cors",
                credentials: "include",
                headers: { 'Accept': 'application/json' },
                body: formData
            });
            if (result.ok) {
                let response = await result.text();
                setFileId(parseInt(response));
            } else {
                console.log("ERROR Logging File Status: \n" + await result.text());
            }
        } catch (error) {
            console.log("ERROR: \n" + error);
        }
        setIsFetching(false);
    }

    const validateFile = (event) => {
        const file = event.target.files[0];

        if (!file) return;

        if (
            file.type !==
            "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet" &&
            file.type !== "text/csv"
        ) {
            setFileStatus("Rejected");
            setIsError(true);
            setResultMessage(`Invalid file type. Please choose a .csv or .xlsx file`);
            callLogFileStatusAPI(file);
        } else {
            const requiredColumns = [
                "Company_Name",
                "Customer_Id",
                "Event_Name",
                "Event_Date",
                "Event_Time",
                "Venue_Name",
                "Section",
                "Row",
                "First_Seat",
                "Last_Seat",
                "Is_Odd_Even(Y/N)",
                "Quantity",
                "Cost",
                "Is_EDelivery(Y/N)",
                "Stock_Type",
            ];
            const reader = new FileReader();
            reader.onload = (event) => {
                const data = event.target.result;
                const workbook = XLSX.read(data, { type: "binary" });

                const sheetName = workbook.SheetNames[0];
                const worksheet = workbook.Sheets[sheetName];

                const jsonData = XLSX.utils.sheet_to_json(worksheet);

                const headerRow = XLSX.utils.sheet_to_json(worksheet, { header: 1 })[0];

                const missingColumns = requiredColumns.filter(
                    (col) => !headerRow.includes(col)
                );

                if (missingColumns.length > 0) {
                    setFileStatus("Rejected");
                    setIsError(true);
                    setResultMessage(
                        `Missing Required Columns: ${missingColumns.join(", ")}`
                    );
                    callLogFileStatusAPI(file);
                } else {
                    const excelDateToJSDate = (excelDate) => {
                        const utcDays = excelDate - 25569; // Excel date to UTC days offset
                        const millisecondsPerDay = 24 * 60 * 60 * 1000;
                        const excelEpoch = new Date(1970, 0, 1); // Excel epoch date
                        const utcMilliseconds = utcDays * millisecondsPerDay;
                        const date = new Date(excelEpoch.getTime() + utcMilliseconds);

                        const options = {
                            month: "2-digit",
                            day: "2-digit",
                            year: "numeric",
                        };
                        return date.toLocaleDateString("en-US", options);
                    };

                    function convertDecimalToTime(decimalValue) {
                        const hours = Math.floor(decimalValue * 24);
                        const minutes = Math.floor(decimalValue * 24 * 60) % 60;
                        const seconds = Math.floor(decimalValue * 24 * 60 * 60) % 60;
                        const isPM = hours >= 12;
                        const formattedHours = hours % 12 === 0 ? 12 : hours % 12;
                        const formattedTime = `${formattedHours}:${padZero(
                            minutes
                        )}:${padZero(seconds)} ${isPM ? "PM" : "AM"}`;
                        return formattedTime;
                    }

                    function padZero(number) {
                        return number.toString().padStart(2, "0");
                    }

                    function hasAllKeys(jsonObj, keys) {
                        for (let i = 0; i < keys.length; i++) {
                            if (!(keys[i] in jsonObj)) {
                                if (
                                    !(
                                        keys[i] === "Event_Time" &&
                                        !Number.isInteger(jsonObj.Event_Date)
                                    )
                                ) {
                                    setFileStatus("Rejected");
                                    setIsError(true);
                                    setResultMessage(`Data Missing for: ${keys[i]}`);
                                    return false;
                                }
                            }
                        }
                        return true;
                    }

                    var exitWithBreak = false;

                    /** 
                     * Looping through every Row/Ticket
                     */
                    for (let i = 0; i < jsonData.length; i++) {
                        const item = jsonData[i];

                        // Checking if Mandatory column's Data is present
                        const containsAllKeys = hasAllKeys(item, requiredColumns);
                        if (!containsAllKeys) {
                            exitWithBreak = true;
                            break;
                        }

                        if (Number.isFinite(item.Event_Date)) {
                            item.Event_Time = convertDecimalToTime(item.Event_Date % 1);
                        } else {
                            let dateParts = item.Event_Date.split(" ");
                            item.Event_Time = dateParts[1] + " " + dateParts[2];
                        }

                        //Check if the value is String or Decimal
                        if (Number.isFinite(item.Event_Time)) { //false if its String
                            item.Event_Time = convertDecimalToTime(item.Event_Time);
                        }

                        // Mandatory Columns

                        item.Company_Name = item.Company_Name.toString().slice(0, 50);
                        item.Customer_Id = parseInt(item.Customer_Id);
                        item.Event_Date = Number.isFinite(item.Event_Date) ?
                            excelDateToJSDate(item.Event_Date) : item.Event_Date.split(' ')[0];
                        item["Is_EDelivery(Y/N)"] = item["Is_EDelivery(Y/N)"] === "Y" ? true : false;
                        item["Is_Odd_Even(Y/N)"] = item["Is_Odd_Even(Y/N)"] === "Y" ? true : false;
                        item.Section = item.Section.toString().slice(0, 50);
                        item.Row = item.Row.toString().slice(0, 50);
                        item.Event_Time = item.Event_Time.toString().trim();
                        item.Event_Name = item.Event_Name.toString().slice(0, 100);
                        item.Venue_Name = item.Venue_Name.toString().slice(0, 100);
                        item.First_Seat = parseInt(item.First_Seat);
                        item.Last_Seat = parseInt(item.Last_Seat);
                        item.Quantity = parseInt(item.Quantity);
                        item.Cost = parseInt(item.Cost);
                        item.Stock_Type = item.Stock_Type.toString().slice(0, 15);

                        // Non-Mandatory columns

                        /*If Is_Edelivery = True, then overwrite this as -2 days from the event date, 
                        If Is_Edelivery = False, then overwrite this as -7 days from the event date*/
                        var tempDate = new Date(item.Event_Date);
                        tempDate.setDate(tempDate.getDate() - (item["Is_EDelivery(Y/N)"] ? 2 : 7));
                        item.Expected_Availability_Date = (tempDate.getMonth() + 1)
                            + '/' + tempDate.getDate() + '/' + tempDate.getFullYear();

                        item.Purchase_Date = item.hasOwnProperty("Purchase_Date")
                            ? excelDateToJSDate(item.Purchase_Date)
                            : "";
                        item.Event_Id = item.hasOwnProperty("Event_Id")
                            ? parseInt(item.Event_Id)
                            : 0;
                        item.Venue_City = item.hasOwnProperty("Venue_City")
                            ? item.Venue_City.toString().slice(0, 50)
                            : "";
                        item.Venue_State = item.hasOwnProperty("Venue_State")
                            ? item.Venue_State.toString().slice(0, 20)
                            : "";
                        item.Face = item.hasOwnProperty("Face")
                            ? parseInt(item.Face)
                            : 0;
                        item["Is_Available(Y/N)"] = item.hasOwnProperty("Is_Available(Y/N)")
                            ? item["Is_Available(Y/N)"] === "Y"
                                ? true
                                : false
                            : false;
                        item.External_Notes = item.hasOwnProperty("External_Notes")
                            ? item.External_Notes.toString().slice(0, 250)
                            : "";
                        item.Procurement_Notes = item.hasOwnProperty("Procurement_Notes")
                            ? item.Procurement_Notes.toString().slice(0, 50)
                            : "";
                        item.Internal_Notes = item.hasOwnProperty("Internal_Notes")
                            ? item.Internal_Notes.toString().slice(0, 250)
                            : "";
                        item.Tracking_Number = item.hasOwnProperty("Tracking_Number")
                            ? item.Tracking_Number.toString().slice(0, 50)
                            : "";
                        item.Confirmation_Number = item.hasOwnProperty("Confirmation_Number")
                            ? item.Confirmation_Number.toString().slice(0, 50)
                            : "";
                        item.CC_Last_Digits = item.hasOwnProperty("CC_Last_Digits")
                            ? item.CC_Last_Digits.toString().slice(0, 50)
                            : "";
                        item.Credit_Card_Group = item.hasOwnProperty("Credit_Card_Group")
                            ? item.Credit_Card_Group.toString().slice(0, 50)
                            : "";
                        item.Purchase_Id = item.hasOwnProperty("Purchase_Id")
                            ? item.Purchase_Id.toString().slice(0, 50)
                            : "";
                    }

                    setFileData(jsonData);

                    if (exitWithBreak) {
                        callLogFileStatusAPI(file);
                    } else {
                        setIsError(false);
                        setResultMessage("");
                        setFileStatus("Accepted");
                        // API is getting called before FileStatus is set
                        // Hence sending true
                        callLogFileStatusAPI(file, true);
                    }
                }
            };
            reader.readAsBinaryString(file);
        }
    };
    const submitFile = async () => {
        setIsFetching(true);
        setShowInventories(false);
        const uri = `${getBaseUrl()}/Companies/importInventory`;
        const body = {
            fileId: fileId,
            importInventories: fileData,
        };
        try {
            let result = await fetch(uri, {
                ...getDefaultFetchOptions(HTTP_VERB.POST),
                body: JSON.stringify(body),
            });
            if (result.ok) {
                setShowInventories(true);
            } else {
                console.log("ERROR Submitting File: \n" + await result.text());
            }
        } catch (error) {
            console.log("ERROR: \n" + error);
        }
        setIsFetching(false);
    };

    const handleTooltipClick = () => {
        if (toastIdRef.current) {
            toast.close(toastIdRef.current);
        }

        const toastId = toast({
            title: "Help",
            description: (
                <>
                    <ul>
                        <li>The file must be in either XLSX or CSV format.</li>
                        <li>
                            To expedite the creation process, restrict the number of rows to less than 200.
                        </li>
                        <li>
                            Make sure to input values for the mandatory columns; otherwise, the file upload will be rejected. The mandatory columns include: Company_Name, Customer_Id, Event_Name, Event_Date, Event_Time, Venue_Name, First_Seat, Last_Seat, Is_Odd_Even (Y/N), Quantity, Is_EDelivery (Y/N), Stock_Type.
                        </li>
                        <li>
                            If you have Event_Id in your file, be sure to verify that the Event_Id and event details match accurately before uploading the file.
                        </li>
                        <li>
                            Be cautious of events that have the same headlines, date, and venues but different times (e.g., Broadway & NCAA events). The logic doesn’t match event time, thus, this can result in duplicates. Check in the Portal for duplicates and delete them if necessary.
                        </li>
                        <li>
                            When searching for created POs in the Portal, utilize the PO start and end date filter.
                        </li>
                    </ul>
                </>
            ),
            status: "info",
            duration: null,
            isClosable: true,
        });

        toastIdRef.current = toastId;
    };

    return (
        <>
            <GenericFormContainer
                formMaxWidth={"500px"}
                width={"500px"}
                title={"Import Inventory"}
                subTitle="Bulk Upload of Inventories"
                isResultError={isError}
                isSubmitDisabled={!allowSubmit()}
                onSubmitClick={() => submitFile()}
                isFetching={isFetching}
                resultMessage={resultMessage}
            >
                <span style={{ padding: '10px' }}>
                    <input type="file" onChange={validateFile} />
                    <Tooltip
                        maxW="500px"
                        label={
                            <List styleType="disc" paddingLeft={4}>
                                <ListItem>
                                    The file must be in either XLSX or CSV format.
                                </ListItem>
                                <ListItem>
                                    To expedite the creation process, restrict the number of rows to less than 200.
                                </ListItem>
                                <ListItem>
                                    Make sure to input values for the mandatory columns; otherwise, the file upload will be rejected. The mandatory columns include: Company_Name, Customer_Id, Event_Name, Event_Date, Event_Time, Venue_Name, First_Seat, Last_Seat, Is_Odd_Even (Y/N), Quantity, Is_EDelivery (Y/N), Stock_Type.
                                </ListItem>
                                <ListItem>
                                    If you have Event_Id in your file, be sure to verify that the Event_Id and event details match accurately before uploading the file.
                                </ListItem>
                                <ListItem>
                                    Be cautious of events that have the same headlines, date, and venues but different times (e.g., Broadway & NCAA events). The logic doesn’t match event time, thus, this can result in duplicates. Check in the Portal for duplicates and delete them if necessary.
                                </ListItem>
                                <ListItem>
                                    When searching for created POs in the Portal, utilize the PO start and end date filter.
                                </ListItem>
                            </List>
                        }
                    >
                        <Button
                            type="button"
                            float="right"
                            bg="#4299e1"
                            color="#ffffff"
                            h="30px"
                            px={1}
                            fontWeight="normal"
                            variant="outline"
                            borderColor="#4299e1"
                            _hover={{ bg: "#dcdcdc", color: "#000000" }}
                            _active={{ bg: "#c4c4c4" }}
                            onClick={handleTooltipClick}
                        >
                            Help <Icon as={FaQuestionCircle} ml={1} />
                        </Button>
                    </Tooltip>
                </span>
            </GenericFormContainer>
            {showInventories && <InventoryList username={username} fileId={fileId} filename={fileName} />}
        </>
    );
};

export default ImportInventory;
