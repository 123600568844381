import React from "react";
import { CompactTable } from "@table-library/react-table-library/compact";
import { useTheme } from "@table-library/react-table-library/theme";
import {
  DEFAULT_OPTIONS,
  getTheme,
} from "@table-library/react-table-library/chakra-ui";
import { usePagination } from "@table-library/react-table-library/pagination";
import { useRowSelect } from "@table-library/react-table-library/select";
import { Box, Input, HStack, IconButton, VStack } from "@chakra-ui/react";
import { FaAngleRight } from "react-icons/fa";
import { FaAngleLeft } from "react-icons/fa";
import { TABLE_MODE } from "../enums";

const chakraTheme = getTheme(DEFAULT_OPTIONS, { isVirtualized: true });

const TableCore = ({
  columns,
  data,
  height,
  mode,
  onRowSelect,
  onSearchChange,
  onSearchReturn,
  pageSize,
  showSearch,
  width,
}) => {
  const theme = useTheme({ ...chakraTheme, Table: `height: 100%` });
  const pagination = usePagination(data, {
    state: {
      page: 0,
      size: pageSize,
    },
  });
  const getTotalPages = () => {
    return pagination.state.getTotalPages(data.nodes);
  };
  const select = useRowSelect(data, {
    onChange: onSelectChange,
  });
  function onSelectChange(action, state) {
    if (onRowSelect) {
      onRowSelect(action, state);
    }
  }
  const handleSearchChange = (value) => {
    pagination.fns.onSetPage(0);
    onSearchChange(value);
  };
  const handleSearchReturn = (event) => {
    if (onSearchReturn && event.key === "Enter") {
      onSearchReturn();
    }
  };
  return (
    <Box
      p={3}
      borderWidth="1px"
      borderRadius="lg"
      height={height}
      width={width}
    >
      {showSearch && (
        <Input
          variant="filled"
          placeholder="Search"
          onChange={(e) => handleSearchChange(e.target.value)}
          mb={"10px"}
          onKeyUp={(e) => handleSearchReturn(e)}
        />
      )}
      <VStack align={"left"}>
        {(mode === TABLE_MODE.SELECTABLE || mode === TABLE_MODE.CLICKABLE) && (
          <CompactTable
            columns={columns}
            data={data}
            pagination={pagination}
            select={select}
            theme={theme}
          />
        )}
        {mode === TABLE_MODE.VIEW_ONLY && (
          <CompactTable
            columns={columns}
            data={data}
            pagination={pagination}
            theme={theme}
          />
        )}
        <HStack>
          <span>Total Pages: {getTotalPages()}</span>
          <IconButton
            colorScheme={"teal"}
            icon={<FaAngleLeft />}
            isDisabled={pagination.state.page === 0}
            onClick={() => pagination.fns.onSetPage(pagination.state.page - 1)}
          />
          <span>Page: {pagination.state.page + 1}</span>
          <IconButton
            colorScheme={"teal"}
            icon={<FaAngleRight />}
            isDisabled={pagination.state.page === getTotalPages() - 1}
            onClick={() => pagination.fns.onSetPage(pagination.state.page + 1)}
          />
        </HStack>
      </VStack>
    </Box>
  );
};

export default TableCore;
