import React from "react";
import { Link } from "react-router-dom";
import { Text } from "@chakra-ui/react";
import { useMatch, useResolvedPath } from "react-router-dom";

const MenuItem = ({
  children,
  isVisible = true,
  isActive,
  isLast,
  to = "/",
  ...rest
}) => {
  let resolved = useResolvedPath(to);
  let match = useMatch({ path: resolved.pathname, end: true });
  const showUnderline = () => {
    if (match) {
      return "underline";
    }
    return "none";
  };
  return (
    <>
      {isVisible && (
        <Link to={to} href={to}>
          <Text display="block" {...rest} textDecoration={showUnderline()}>
            {children}
          </Text>
        </Link>
      )}
    </>
  );
};

export default MenuItem;
