import { Box, Flex, Icon } from "@chakra-ui/react";
import React from "react";
import Sidebar from "../../common/Sidebar/Sidebar";
import Merge from "./Merge";
import {
  FaArrowsAlt,
  FaCodeBranch,
  FaFileImport,
  FaLink,
  FaSearch,
} from "react-icons/fa";
import { Routes, Route } from "react-router-dom";
import { Outlet } from "react-router-dom";

const subMenu = [
  {
    icon: <Icon as={FaCodeBranch} />,
    path: "",
    label: "Merge Events",
  },
  {
    icon: <Icon as={FaArrowsAlt} />,
    path: "moveEvents",
    label: "Move Events",
  },
  {
    icon: <Icon as={FaLink} />,
    path: "mapShEvent",
    label: "Map Stubhub Event",
  },
  {
    icon: <Icon as={FaFileImport} />,
    path: "alfredtmlink",
    label: "Link Alfred to Ticketmaster Event",
  },
  {
    icon: <Icon as={FaArrowsAlt} />,
    path: "transferInventory",
    label: "Transfer inventory",
  },
  {
    icon: <Icon as={FaSearch} />,
    path: "eventsearch",
    label: "Viagogo Event Search",
  },
];

const EventsHome = () => {
  return (
    <Flex direction={"row"}>
      <Sidebar isOpen={true} title={"Event Management"} linkItems={subMenu} />
      <Box h="full" p="4">
        <Flex direction={"column"}>
          <Routes>
            <Route path="/" element={<Merge />} />
          </Routes>
          <Outlet />
        </Flex>
      </Box>
    </Flex>
  );
};

export default EventsHome;
