import React, { useState } from "react";
import { HTTP_VERB } from "../../enums";
import { getBaseUrl, getDefaultFetchOptions } from "../../utils";
import CompanyCore from "./CompanyCore";

const CreateCompany = () => {
  const [isError, setIsError] = useState(false);
  const [resultMessage, setResultMessage] = useState("");
  const [isFetching, setIsFetching] = useState(false);

  const handleSubmit = async (data) => {
    setIsFetching(true);
    try {
      let uri = `${getBaseUrl()}/companies`;
      let body = {
        brokerageId: data.brokerageId,
        companyName: data.companyName,
        companyId: data.companyId,
        emails: data.emails,
        oneTicketUserName: data.oneTicketUserName,
        oneTicketPassword: data.oneTicketPassword,
        oneTicketFolderName: data.oneTicketFolderName,
        oneTicketUserId: data.oneTicketUserId,
        oneTicketEmails: data.oneTicketEmails,
        consignorEmail: data.consignorEmail,
        oneTicketApiKey: data.oneTicketApiKey,
        isAcceptedTermsandConditions: data.isAcceptedTermsandConditions,
        purchasingEmails: data.purchasingEmails
      };
      let result = await fetch(uri, {
        ...getDefaultFetchOptions(HTTP_VERB.POST),
        body: JSON.stringify(body),
      });

      if (result.ok) {
        setIsError(false);
        setResultMessage(`Successfully added Company ${data.companyName}`);
      } else {
        setIsError(true);
        let responseMessage = await result.text();
        setResultMessage(
          `Error adding Company ${data.companyName}. ${responseMessage}`
        );
      }
    } catch (error) {
      console.log(error);
    }
    setIsFetching(false);
  };
  return (
    <CompanyCore
      brokerageId={""}
      companyId={""}
      emails={[""]}
      isEdit={false}
      isError={isError}
      isFetching={isFetching}
      title={"Add Company"}
      subTitle={"Create a New Company"}
      oneTicketEmails={[""]}
      oneTicketFolderName={""}
      oneTicketPassword={""}
      oneTicketUserId={""}
      oneTicketUsername={""}
      consignorEmail={""}
      oneTicketApiKey={""}
      onSubmit={(data) => handleSubmit(data)}
      name={""}
      resultMessage={resultMessage}
      isAcceptedTermsandConditions={false}
      purchasingEmails={[""]}
    />
  );
};

export default CreateCompany;
