import React, { useEffect, useState, useCallback } from "react";
import { getBaseUrl, getDefaultFetchOptions } from "../../../utils";
import { useParams } from "react-router-dom";
import { HTTP_VERB } from "../../../enums";
import {
  Box,
  Spinner,
  Table,
  Thead,
  Tbody,
  Tr,
  Th,
  TableContainer,
  IconButton,
  Text,
  HStack,
  useToast,
} from "@chakra-ui/react";
import "../../../styles/Table.css";
import ConsignorRate from "./ConsignorRate";
import ExchangeSelectCore from "../../../common/ExchangeSelectCore";
import { FaPlus } from "react-icons/fa";

const ConsignorRatesList = () => {
  let params = useParams();
  let companyId = params.company;
  const [company, setCompany] = useState();
  const [rates, setRates] = useState();
  const [isFetching, setIsFetching] = useState(false);
  const [fetchingId, setFetchingId] = useState();
  const [selectedExchange, setSelectedExchange] = useState();
  const [exchanges, setExchanges] = useState([]);
  const toast = useToast();
  const renderToast = useCallback((toast, isError, title, desc) => {
    let status = "success";
    if (isError) {
      status = "error";
    }
    toast({
      title: title,
      description: desc,
      status: status,
      duration: 3000,
      isClosable: true,
    });
  }, []);
  useEffect(() => {
    (async () => {
      const uri = `${getBaseUrl()}/companies/id/${companyId}`;
      let result = await fetch(uri, getDefaultFetchOptions(HTTP_VERB.GET));
      if (result.ok) {
        let response = await result.json();
        setCompany(response);
      }
    })();
    (async () => {
      const uri = `${getBaseUrl()}/customerrates/${companyId}`;
      let result = await fetch(uri, getDefaultFetchOptions(HTTP_VERB.GET));
      if (result.ok) {
        let response = await result.json();
        let customerExchanges = [];
        let unmappedExchanges = [];
        response.forEach(v => {
          if (v.rate) {
            customerExchanges.push(v);
          } else {
            unmappedExchanges.push({exchange_Name: v.exchange.name, exchange_ID: v.exchange.id})
          }
        })
        setRates(customerExchanges);
        setExchanges(unmappedExchanges);
      }
    })();
  }, [companyId]);
  const renderError = (error) => {
    renderToast(toast, true, "Error Saving Rates", error?.detail);
  };
  const handleSave = async (data) => {
    setIsFetching(true);
    setFetchingId(data.exchange.id);
    let body = {
      companyId: company.company_ID,
      companyName: company.company_Name,
      consignorRateExchangeId: data.exchange.id,
      retailerFee: data.updatedRetailerFee,
      markup: data.updatedMarkup,
      notes: data.updatedNotes,
      commissionRate: data.updatedFee,
    };
    let uri = `${getBaseUrl()}/customerRates`;
    try {
      let request = await fetch(uri, {
        ...getDefaultFetchOptions(HTTP_VERB.PATCH),
        body: JSON.stringify(body),
      });
      let response = await request.json();
      if (request.ok) {
        const fee = response.sfcFee;
        const priceChangePct = response.priceChange;
        let temp = rates.map((v) => {
          if (v.exchange.id === response.consignorRateExchangeId) {
            if (!v.rate) {
              v.rate = {};
            }
            v.rate.commissionRate = fee;
            v.rate.markup = priceChangePct;
          }
          return v;
        });
        setRates(temp);
      } else {
        renderError(response);
      }
    } catch (error) {
      console.log(error);
    }
    setIsFetching(false);
    setFetchingId(undefined);
  };
  const handleDelete = async (data) => {
    console.log(data);
    let body = {
      customerId: company.company_ID,
      customerName: company.company_Name,
      consignorRateExchangeId: data.exchange.id
    };
    console.log(body);
    const uri = `${getBaseUrl()}/customerRates`;
    let result = await fetch(uri, {...getDefaultFetchOptions(HTTP_VERB.DELETE), body: JSON.stringify(body)});
    if (result.ok) {
      let temp = rates.filter((v) => v.exchange.id !== data.exchange.id);
      setRates(temp);
    }
  };
  const handleExchangeAdd = () => {
    let selectedExchangeId = parseInt(selectedExchange);
    let foundExchange = exchanges.find(v => {
      return v.exchange_ID === selectedExchangeId;
    })
    let add = {
      exchange: {
        id: selectedExchangeId,
        name: foundExchange.exchange_Name
      },
      rate: null
    }
    let temp = rates.concat([add]);
    setRates(temp);

    temp = exchanges.filter(x => x.exchange_ID !== selectedExchangeId);
    setExchanges(temp);
  };
  const renderRows = () => {
    if (!rates) {
      return;
    }
    return rates.map((v, i) => {
      return (
        <ConsignorRate
          key={i}
          data={v}
          isFetching={v.exchange.id === fetchingId}
          onSave={(data) => handleSave(data)}
          onDelete={(data) => handleDelete(data)}
        />
      );
    });
  };
  return (
    <Box>
      {!rates && <Spinner />}
      {company && <Text p={2}>{company.company_Name}</Text>}
      <TableContainer>
        <Table size="sm">
          <Thead>
            <Tr>
              <Th width={"150px"}>Exchange</Th>
              <Th width={"80px"} isNumeric>
                Markup/Down
              </Th>
              <Th width={"80px"} isNumeric>
                Retailer Fee
              </Th>
              <Th width={"80px"}>SF Fee</Th>
              <Th width={"350px"}>Notes</Th>
              <Th width={"100"}>&nbsp;</Th>
              <Th isNumeric> SFC Fee</Th>
              <Th isNumeric>Price Change%</Th>
            </Tr>
          </Thead>
          <Tbody>{renderRows()}</Tbody>
        </Table>
      </TableContainer>
      {exchanges.length > 0 && (
        <HStack alignItems={"flex-end"} mt={5}>
          <ExchangeSelectCore
            useIdAsValue={true}
            onValueChange={(e) => setSelectedExchange(e)}
            exchanges={exchanges}
            width={"300px"}
            title={"Unmapped Exchanges"}
          />
          <IconButton
            mr={2}
            isLoading={isFetching}
            onClick={handleExchangeAdd}
            colorScheme={"green"}
            aria-label="Add Exchange"
            icon={<FaPlus />}
          />
        </HStack>
      )}
    </Box>
  );
};

export default ConsignorRatesList;
