import React from "react";
import { Flex, useColorModeValue } from "@chakra-ui/react";

const NavBarContainer = ({ children, ...props }) => {
  return (
    <Flex
      as="nav"
      align="center"
      justify="space-between"
      wrap="wrap"
      w="100%"
      minH={"60px"}
      py={{ base: 2 }}
      px={{ base: 4 }}
      bg={"purple.700"}
      color={"#fffffe"}
      borderBottom={1}
      borderStyle={"solid"}
      borderColor={useColorModeValue("gray.200", "gray.900")}
      boxShadow={"sm"}
      {...props}
    >
      {children}
    </Flex>
  );
};

export default NavBarContainer;
