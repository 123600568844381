import React, { useState, useEffect, useCallback } from "react";
import { Box, Heading, HStack, Icon, Text, useToast } from "@chakra-ui/react";
import { FcInfo } from "react-icons/fc";
import PaymentForm from "./PaymentForm";
import PaymentFiles from "./PaymentFiles";
import { getDefaultFetchOptions, getBaseUrl } from "../../utils";
import { HTTP_VERB } from "../../enums";
const Payments = () => {
  const [files, setFiles] = useState([]);
  const toast = useToast();
  const getFiles = useCallback(() => {
    (async () => {
      const uri = `${getBaseUrl()}/ConsignorPayments/paymentFiles`;
      let request = await fetch(uri, getDefaultFetchOptions(HTTP_VERB.GET));
      if (request.ok) {
        let response = await request.json();
        setFiles(response);
      } else {
        let errResponse = await request.json();
        toast({
          title: "Error fetching existing Payment files.",
          description: errResponse?.detail,
          status: "error",
          duration: 3000,
          isClosable: true,
        });
      }
    })();
  }, [toast]);
  useEffect(() => {
    getFiles();
  }, [getFiles]);
  const handleSubmit = () => {
    getFiles();
  };
  return (
    <Box
      width={"950px"}
      height={"650px"}
      mb={"10px"}
      mr="10px"
      borderWidth={1}
      borderRadius={4}
      boxShadow="lg"
      textAlign="left"
      p={2}
    >
      <Heading as={"h2"} size="md" mb="1" ml={2}>
        Step 2: Payments
      </Heading>
      <HStack ml={2} mr={2} mb={5} borderWidth={1} borderRadius={4} p={2}>
        <Icon as={FcInfo} />
        <Text fontSize={"xs"} m="0" as={"span"}>
          Generate and View Payment Reports
        </Text>
      </HStack>
      <PaymentForm onSubmit={handleSubmit} />
      <PaymentFiles files={files} />
    </Box>
  );
};

export default Payments;
