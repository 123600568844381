import React, { useEffect, useState, useCallback } from "react";
import ComponentContainer from "../../common/ComponentContainer";
import FormHeader from "../../common/FormHeader";
import { HTTP_VERB } from "../../enums";
import { getBaseUrl, getDefaultFetchOptions } from "../../utils";
import { FormLabel, HStack, Switch, VStack, useToast } from "@chakra-ui/react";

const EditAdminAppRoles = ({ userId }) => {
  const [roles, setRoles] = useState([]);
  const [userRoles, setUserRoles] = useState(undefined);
  const toast = useToast();
  const renderToast = useCallback((toast, isError, title, desc) => {
    let status = "success";
    if (isError) {
      status = "error";
    }
    toast({
      title: title,
      description: desc,
      status: status,
      duration: 3000,
      isClosable: true,
    });
  }, []);
  useEffect(() => {
    (async () => {
      const uri = `${getBaseUrl()}/adminToolRoles`;
      let request = await fetch(uri, getDefaultFetchOptions(HTTP_VERB.GET));
      if (request.ok) {
        let response = await request.json();
        setRoles(response);
      } else {
        console.log("Error fetching roles");
      }
    })();
    (async () => {
      const uri = `${getBaseUrl()}/adminToolRoles/userId/${userId}`;
      let request = await fetch(uri, getDefaultFetchOptions(HTTP_VERB.GET));
      if (request.ok) {
        let response = await request.json();

        let roleIds = response.map((v) => v.adminToolRoleId);
        setUserRoles(roleIds);
      } else {
        let response = await request.json();
        renderToast(toast, true, "Error", response?.detail);
      }
    })();
  }, [userId, renderToast, toast]);
  const updateRole = async (id, checked) => {
    const uri = `${getBaseUrl()}/adminToolRoles/editRole`;
    let body = {
      userId: userId,
      roleId: id,
      isChecked: checked,
    };
    let request = await fetch(uri, {
      ...getDefaultFetchOptions(HTTP_VERB.PATCH),
      body: JSON.stringify(body),
    });
    if (request.ok) {
      setUserRoles((userRoles) => {
        let temp = [...userRoles];
        if (checked === true) {
          temp.push(id);
        } else {
          temp = temp.filter(function (value) {
            return value !== id;
          });
        }
        return temp;
      });
    } else {
      let response = await request.json();
      renderToast(toast, true, "Error", response?.detail);
    }
  };
  return (
    <ComponentContainer>
      <FormHeader
        title={"Edit Admin App Roles"}
        subTitle={"Assign or remove Admin App roles"}
      />
      {roles && userRoles && (
        <VStack align={"flex-start"} p="2">
          {roles.map((role) => (
            <HStack key={role.adminToolRoleId}>
              <Switch
                isChecked={userRoles.indexOf(role.adminToolRoleId) !== -1}
                onChange={(e) =>
                  updateRole(role.adminToolRoleId, e.target.checked)
                }
              />
              <FormLabel>{role.name}</FormLabel>
            </HStack>
          ))}
        </VStack>
      )}
    </ComponentContainer>
  );
};

export default EditAdminAppRoles;
