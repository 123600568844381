import React from "react";
import Sidebar from "../../common/Sidebar/Sidebar";
import { Box, Flex, Icon } from "@chakra-ui/react";
import AddVendor from "./AddVendor";
import { FaPlusCircle } from "react-icons/fa";
import { FaBinoculars } from "react-icons/fa6";
import { Routes, Route } from "react-router-dom";
import { CiBarcode } from "react-icons/ci";
import { Outlet } from "react-router-dom";

const subMenu = [
  {
    icon: <Icon as={FaPlusCircle} />,
    path: "",
    label: "Add Vendor",
  },
  {
    icon: <Icon as={FaPlusCircle} />,
    path: "addCompany",
    label: "Add Company",
  },
  {
    icon: <Icon as={FaBinoculars} />,
    path: "launchCrawler",
    label: "Crawler Manager",
  },
  {
    icon: <Icon as={CiBarcode} />,
    path: "importBarcodes",
    label: "Import Barcodes",
  },
];

const MarvinHome = () => {
  return (
    <Flex direction={"row"}>
      <Sidebar isOpen={true} title="Marvin Management" linkItems={subMenu} />
      <Box h="full" p="4">
        <Flex direction={"column"}>
          <Routes>
            <Route path="/" element={<AddVendor />} />
          </Routes>
          <Outlet />
        </Flex>
      </Box>
    </Flex>
  );
};

export default MarvinHome;
