import { useTable, usePagination } from "react-table";
import React, { useState, useMemo, useRef, useEffect } from "react";
import { getDefaultFetchOptions, getBaseUrl } from "../../utils";
import { HTTP_VERB } from "../../enums";
import { Button, Spinner, useToast, Icon, Tooltip } from "@chakra-ui/react";
import styled from "styled-components";
import { FaFileExport } from "react-icons/fa";

const PrimaryBtn = styled.button`
  /* Define your button styles here */
  padding: 5px;
  font-size: 16px;
  background-color: #e7e7e7;
  color: #000;
  border: 1px solid;
  border-radius: 2px;
  cursor: pointer;
  margin-right: 5px;
  margin-bottom: 5px;

  /* Add hover and focus styles */
  &:hover,
  &:focus {
    background-color: #b2d8d8;
  }

  /* Add styles for disabled state */
  &:disabled {
    background-color: #ccc;
    cursor: not-allowed;
  }
`;

const SecondaryBtn = styled.button`
  /* Define your button styles here */
  padding: 1px;
  font-size: 12px;
  background-color: #e7e7e7;
  color: #000;
  border: 1px solid;
  border-radius: 2px;
  cursor: pointer;
  margin-right: 5px;
  margin-top: 5px;

  /* Add styles for disabled state */
  &:disabled {
    background-color: #ccc;
    cursor: not-allowed;
  }
`;

const InventoryList = ({ username, fileId, filename }) => {
  const [dirtyRows, setDirtyRows] = useState(new Set());
  const [data, setData] = useState([]);
  const [isFirstRender, setIsFirstRender] = useState(true);
  const [isLoading, setIsLoading] = useState(false);
  const [poCreated, setPoCreated] = useState(false);
  const [tabSelected, setTabSelected] = useState("unmap");
  const [rowIndexToClick, setRowIndexToClick] = useState(0);
  const [isEventEdited, setIsEventEdited] = useState(false);
  const poBtnRef = useRef(null);
  const eventInputRefs = useRef([]);
  const toast = useToast();

  const columns = useMemo(
    () => [
      { Header: "PO ID", accessor: "po_Id" },
      {
        Header: "PO Created Date",
        accessor: "pO_Date",
        Cell: ({ value }) => {
          const dateObj = new Date(value);
          const formattedDate = `${
            dateObj.getMonth() + 1
          }/${dateObj.getDate()}/${dateObj.getFullYear()}`;
          return formattedDate;
        },
      },
      { Header: "Company Name", accessor: "company_Name" },
      { Header: "Customer ID", accessor: "customer_Id" },
      { Header: "Event ID", accessor: "Event_Id" },
      { Header: "Event Name", accessor: "event_Name" },
      {
        Header: "Event Date",
        accessor: "event_Date",
        Cell: ({ value }) => (value ? value.split(" ")[0] : ""),
      },
      { Header: "Event Time", accessor: "event_Time" },
      { Header: "Venue Name", accessor: "venue_Name" },
      { Header: "Venue City", accessor: "venue_City" },
      { Header: "Venue State", accessor: "venue_State" },
      { Header: "Section", accessor: "section" },
      { Header: "Row", accessor: "row" },
      { Header: "First Seat", accessor: "first_Seat" },
      { Header: "Last Seat", accessor: "last_Seat" },
      {
        Header: "Is Odd Even",
        accessor: "Is_Odd_Even(Y/N)",
        Cell: ({ value }) => (value ? "Y" : "N"),
      },
      { Header: "Quantity", accessor: "quantity" },
      {
        Header: "Cost",
        accessor: "cost",
        Cell: ({ value }) => "$" + value,
      },
      {
        Header: "Face",
        accessor: "face",
        Cell: ({ value }) => "$" + value,
      },
      {
        Header: "Is Available",
        accessor: "Is_Available(Y/N)",
        Cell: ({ value }) => (value ? "Y" : "N"),
      },
      {
        Header: "Expected Availability Date",
        accessor: "expected_Availability_Date",
        Cell: ({ value }) => (value ? value.split(" ")[0] : ""),
      },
      { Header: "Tracking Number", accessor: "tracking_Number" },
      {
        Header: "Is EDelivery",
        accessor: "Is_EDelivery(Y/N)",
        Cell: ({ value }) => (value ? "Y" : "N"),
      },
      { Header: "Stock Type", accessor: "stock_Type" },
      { Header: "Internal Notes", accessor: "internal_Notes" },
      { Header: "External Notes", accessor: "external_Notes" },
      { Header: "Procurement Notes", accessor: "procurement_Notes" },
      { Header: "Confirmation Number", accessor: "confirmation_Number" },
      { Header: "Purchase ID", accessor: "Purchase_Id" },
      { Header: "Purchase Date", accessor: "purchase_Date" },
      { Header: "Credit Card Group", accessor: "credit_Card_Group" },
      { Header: "CC Last Digits", accessor: "cC_Last_Digits" },
      { Header: "ID", accessor: "import_id" },
    ],
    []
  );

  async function handleMappedUnmapped(tab) {
    // Clearing dirty rows on tab change
    setDirtyRows(new Set());

    setTabSelected(tab);

    const uri = `${getBaseUrl()}/Companies/getMappedUnmapped`;
    const body = {
      fileId: fileId,
      list: tab,
    };
    let result = await fetch(uri, {
      ...getDefaultFetchOptions(HTTP_VERB.POST),
      body: JSON.stringify(body),
    });
    if (result.ok) {
      let payload = await result.json();
      setData(payload);
    } else {
      console.log(
        "FAILED to Fetch Mapped/Unmapped Tickets: \n" + (await result.text())
      );
    }
  }

  if (isFirstRender) {
    handleMappedUnmapped("unmap");
    setIsFirstRender(false);
  }

  const mapTickets = async () => {
    const dirtyRecords = [];
    const ticketList = [];

    dirtyRows.forEach((rowIndex) => {
      dirtyRecords.push(data[rowIndex]);

      ticketList.push({
        event_id:
          data[rowIndex].Event_Id !== ""
            ? parseInt(data[rowIndex].Event_Id)
            : 0,
        import_id: data[rowIndex].import_id,
      });
    });

    try {
      let uri = `${getBaseUrl()}/Companies/updateEventId`;
      let body = {
        eventIds: ticketList,
        userName: username,
      };
      let result = await fetch(uri, {
        ...getDefaultFetchOptions(HTTP_VERB.POST),
        body: JSON.stringify(body),
      });

      if (result.ok) {
        handleMappedUnmapped(tabSelected);
        toast({
          title: "Mapping Complete",
          description: "Successfully Mapped Tickets to Events",
          status: "success",
          duration: 5000,
          isClosable: true,
        });
      } else {
        console.log(
          "FAILED to Map Tickets to Events: \n" + (await result.text())
        );
      }
    } catch (error) {
      console.log("ERROR: \n" + error);
    }

    return dirtyRecords;
  };

  async function createPOHandler() {
    setIsLoading(true); // Start loading
    try {
      let uri = `${getBaseUrl()}/Companies/createPO`;
      let body = {
        fileId: fileId,
        userName: username,
      };
      let result = await fetch(uri, {
        ...getDefaultFetchOptions(HTTP_VERB.POST),
        body: JSON.stringify(body),
      });

      if (result.ok) {
        toast({
          title: "PO Creation Complete",
          description: "Purchase Order has been Created Successfully.",
          status: "success",
          duration: 10000,
          isClosable: true,
        });
        setPoCreated(true);
      } else {
        console.log("FAILED to Create PO: \n" + (await result.text()));
      }
    } catch (error) {
      console.log("ERROR: \n" + error);
    }
    setIsLoading(false); // Stop loading
  }

  const exportCSV = () => {
    const csvData = rows.map((row) => {
      prepareRow(row);
      const modifiedData = row.cells.map((cell) => {
        if (cell.column.Header === "PO Created Date") {
          const dateObj = new Date(cell.value);
          const formattedDate = `${
            dateObj.getMonth() + 1
          }/${dateObj.getDate()}/${dateObj.getFullYear()}`;
          return formattedDate;
        } else if (
          cell.column.Header === "Event Date" ||
          cell.column.Header === "Expected Availability Date"
        ) {
          return cell.value ? cell.value.split(" ")[0] : "";
        } else if (
          cell.column.Header === "Is Odd Even" ||
          cell.column.Header === "Is Available" ||
          cell.column.Header === "Is EDelivery"
        ) {
          return cell.value ? "Y" : "N";
        } else {
          return cell.value;
        }
      });
      return modifiedData;
    });
    const csvHeaders = columns.map((column) => column.Header);

    const csvFormattedData = [csvHeaders, ...csvData];

    const csvContent = csvFormattedData.map((row) => row.join(",")).join("\n");

    const blob = new Blob([csvContent], { type: "text/csv;charset=utf-8;" });

    // Formatting today's date to append in filename
    const today = new Date();
    const month = String(today.getMonth() + 1).padStart(2, "0");
    const day = String(today.getDate()).padStart(2, "0");
    const year = today.getFullYear();
    const formattedDate = `${month}/${day}/${year}`;

    const fileName = `${
      filename.split(".")[0]
    }_PO_Created_${formattedDate}.csv`;

    if (typeof window !== "undefined") {
      const downloadLink = document.createElement("a");
      downloadLink.href = URL.createObjectURL(blob);
      downloadLink.download = fileName;
      downloadLink.click();
    }
  };

  useEffect(() => {
    if (poCreated) {
      poBtnRef.current.click();
    }
  }, [poCreated]);

  const handleCellChange = (rowIndex, columnId, value, rowId) => {
    setRowIndexToClick(rowId);
    setIsEventEdited(true);

    const updatedData = [...data];
    updatedData[rowIndex][columnId] = value;
    setData(updatedData);

    const updatedDirtyRows = new Set(dirtyRows);
    updatedDirtyRows.add(rowIndex);
    setDirtyRows(updatedDirtyRows);
  };

  useEffect(() => {
    const timer = setTimeout(() => {
      if (eventInputRefs[rowIndexToClick]) {
        const inputElement = eventInputRefs[rowIndexToClick].current;
        if (isEventEdited && inputElement) {
          inputElement.focus();
          setIsEventEdited(false);
        }
      }
    }, 100);
    return () => clearTimeout(timer);
  }, [data, isEventEdited, rowIndexToClick]);

  const columnsToShow = useMemo(() => {
    if (tabSelected !== "po") {
      return columns.filter(
        (column) => column.accessor !== "po_Id" && column.accessor !== "pO_Date"
      );
    } else {
      return columns.filter(
        (column) =>
          column.accessor !== "section" &&
          column.accessor !== "row" &&
          column.accessor !== "first_Seat" &&
          column.accessor !== "last_Seat" &&
          column.accessor !== "Is_Odd_Even(Y/N)" &&
          column.accessor !== "quantity" &&
          column.accessor !== "cost" &&
          column.accessor !== "face" &&
          column.accessor !== "Is_Available(Y/N)" &&
          column.accessor !== "expected_Availability_Date" &&
          column.accessor !== "tracking_Number" &&
          column.accessor !== "Is_EDelivery(Y/N)" &&
          column.accessor !== "stock_Type" &&
          column.accessor !== "internal_Notes" &&
          column.accessor !== "external_Notes" &&
          column.accessor !== "procurement_Notes" &&
          column.accessor !== "confirmation_Number" &&
          column.accessor !== "Purchase_Id" &&
          column.accessor !== "purchase_Date" &&
          column.accessor !== "credit_Card_Group" &&
          column.accessor !== "cC_Last_Digits" &&
          column.accessor !== "import_id"
      );
    }
  }, [columns, tabSelected]);

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    rows,
    page,
    prepareRow,
    // Add pagination related properties and methods
    pageOptions,
    state: { pageIndex },
    canPreviousPage,
    canNextPage,
    previousPage,
    nextPage,
    gotoPage,
  } = useTable(
    {
      columns: columnsToShow,
      data,
    },
    usePagination // Enable pagination
  );

  useEffect(() => {
    const timer = setTimeout(() => {
      gotoPage(pageIndex);
    }, 25);
    return () => clearTimeout(timer);
  }, [data, gotoPage, pageIndex]);

  return (
    <div>
      {isLoading ? (
        <Spinner size="xl" />
      ) : (
        <>
          <PrimaryBtn
            onClick={() => handleMappedUnmapped("unmap")}
            style={{
              backgroundColor: tabSelected === "unmap" ? "#b2d8d8" : "#e7e7e7",
            }}
          >
            Unmapped Tickets
          </PrimaryBtn>
          <PrimaryBtn
            onClick={() => handleMappedUnmapped("map")}
            style={{
              backgroundColor: tabSelected === "map" ? "#b2d8d8" : "#e7e7e7",
            }}
          >
            Review Mapped Tickets
          </PrimaryBtn>
          {poCreated && (
            <PrimaryBtn
              ref={poBtnRef}
              onClick={() => handleMappedUnmapped("po")}
              style={{
                backgroundColor: tabSelected === "po" ? "#b2d8d8" : "#e7e7e7",
              }}
            >
              PO Created
            </PrimaryBtn>
          )}
          <Tooltip label="Export Table to CSV">
            <span>
              {tabSelected === "po" && (
                <PrimaryBtn onClick={() => exportCSV()}>
                  <Icon as={FaFileExport} />
                </PrimaryBtn>
              )}
            </span>
          </Tooltip>

          <table
            {...getTableProps()}
            style={{ border: "1px solid black", fontSize: "12px" }}
          >
            <thead>
              {headerGroups.map((headerGroup) => (
                <tr {...headerGroup.getHeaderGroupProps()}>
                  {headerGroup.headers.map((column) => (
                    <th
                      {...column.getHeaderProps()}
                      style={{ border: "1px solid black", padding: "5px" }}
                    >
                      {column.render("Header")}
                    </th>
                  ))}
                </tr>
              ))}
            </thead>
            <tbody {...getTableBodyProps()}>
              {page.map((row, rowIndex) => {
                prepareRow(row);

                const eventInputRef = React.createRef();
                eventInputRefs[rowIndex] = eventInputRef;

                return (
                  <tr
                    {...row.getRowProps()}
                    className={dirtyRows.has(rowIndex) ? "dirty-row" : ""}
                  >
                    {row.cells.map((cell, cellIndex) => {
                      return (
                        <td
                          {...cell.getCellProps()}
                          style={{ border: "1px solid black", padding: "5px" }}
                        >
                          {tabSelected !== "po" &&
                          cell.column.id === "Event_Id" ? (
                            <input
                              type="number"
                              value={cell.value}
                              id={`event_id_${rowIndex}`}
                              ref={eventInputRef}
                              onChange={(e) => {
                                const input = e.target.value.replace(/\D/g, ""); // Remove non-numeric characters
                                handleCellChange(
                                  row.index,
                                  cell.column.id,
                                  input,
                                  rowIndex
                                );
                              }}
                            />
                          ) : (
                            cell.render("Cell")
                          )}
                        </td>
                      );
                    })}
                  </tr>
                );
              })}
            </tbody>
          </table>
          {/* Add pagination buttons */}
          <div>
            <SecondaryBtn
              onClick={() => previousPage()}
              disabled={!canPreviousPage}
            >
              {"<<Prev"}
            </SecondaryBtn>
            <span style={{ fontSize: "12px" }}>
              Page{" "}
              <strong>
                {pageIndex + 1} of {pageOptions.length}
              </strong>
            </span>
            <SecondaryBtn
              onClick={() => nextPage()}
              disabled={!canNextPage}
              style={{ marginLeft: "5px" }}
            >
              {"Next>>"}
            </SecondaryBtn>
          </div>
          {tabSelected !== "po" && (
            <Button
              mt={2}
              style={{ marginRight: "10px" }}
              width="200px"
              colorScheme="teal"
              isDisabled={dirtyRows.size === 0}
              onClick={() => mapTickets()}
            >
              {tabSelected === "map"
                ? "Update Tickets"
                : "Map Tickets to Events"}
            </Button>
          )}
          {tabSelected === "map" && (
            <Button
              mt={2}
              width="100px"
              colorScheme="teal"
              onClick={() => createPOHandler()}
            >
              Create PO
            </Button>
          )}
        </>
      )}
    </div>
  );
};

export default InventoryList;
