import { Box, Flex, Icon } from "@chakra-ui/react";
import React from "react";
import { Outlet } from "react-router-dom";
import Sidebar from "../../common/Sidebar/Sidebar";
import CompanyList from "./CompanyList";
import { IoPulseSharp } from "react-icons/io5";
import { TiTicket } from "react-icons/ti";
import { Routes, Route } from "react-router-dom";
import { FaHome, FaPlusCircle, FaFileImport } from "react-icons/fa";
import { MdDisabledByDefault } from "react-icons/md";

const subMenu = [
  {
    icon: <Icon as={FaHome} />,
    path: "",
    label: "Home",
  },
  {
    icon: <Icon as={FaPlusCircle} />,
    path: "add",
    label: "Add Company",
  },
  {
    icon: <Icon as={IoPulseSharp} />,
    path: "vivid",
    label: "Vivid 1.5",
  },
  {
    icon: <Icon as={TiTicket} />,
    path: "1ticket",
    label: "Set 1Ticket Info",
  },
  {
    icon: <Icon as={FaFileImport} />,
    path: "importInventory",
    label: "Import Inventory",
  },
  {
    icon: <Icon as={MdDisabledByDefault} />,
    path: "disableCompany",
    label: "Disable Company",
  },
];

const CompanyHome = () => {
  return (
    <Flex direction={"row"}>
      <Sidebar isOpen={true} title={"Company Setup"} linkItems={subMenu} />
      <Box h="full" p="4">
        <Flex flexDirection={"column"} wrap={"wrap"}>
          <Routes>
            <Route path="/" element={<CompanyList />} />
          </Routes>
          <Outlet />
        </Flex>
      </Box>
    </Flex>
  );
};

export default CompanyHome;
