import React, { useState } from "react";
import {
  FormControl,
  FormLabel,
  FormErrorMessage,
  FormHelperText,
  UnorderedList,
  ListItem,
  Input,
} from "@chakra-ui/react";
import { passwordStrengthRegex } from "../../utils";

const UserPassword = ({
  defaultPassword,
  onPasswordChange,
  onValidityChange,
}) => {
  const [isPasswordMatch, setIsPasswordMatch] = useState(true);
  const [isPasswordValid, setIsPasswordValid] = useState(true);
  const [password, setPassword] = useState(defaultPassword);
  const [confirmPassword, setConfirmPassword] = useState(defaultPassword);
  const checkPasswordMatch = () => {
    let isMatch = password === confirmPassword;
    if (!isMatch && onValidityChange) {
      onValidityChange(isMatch);
    }
    return isMatch;
  };
  const handlePasswordChange = (value) => {
    setPassword(value);
    if (onPasswordChange) {
      onPasswordChange(value);
    }
  };
  const handlePasswordValidityChange = (value) => {
    setIsPasswordValid(value);
    if (onValidityChange) {
      onValidityChange(value);
    }
  };
  return (
    <>
      <FormControl isInvalid={!isPasswordMatch || !isPasswordValid}>
        <FormLabel htmlFor="userPasswordInput">Password</FormLabel>
        <Input
          id="userPasswordInput"
          type="password"
          variant="filled"
          maxLength={50}
          value={password}
          onChange={(e) => handlePasswordChange(e.target.value)}
          onBlur={(e) =>
            handlePasswordValidityChange(password.match(passwordStrengthRegex))
          }
          placeholder="Enter Password"
        />
        <FormHelperText>
          Password requirements:
          <UnorderedList p="2">
            <ListItem>
              Password is currently set to the default password
            </ListItem>
            <ListItem>Min length 6 characters</ListItem>
            <ListItem>At least one digit</ListItem>
            <ListItem>At least one special character</ListItem>
            <ListItem>At least one uppercase character</ListItem>
            <ListItem>At least one lowercase character</ListItem>
          </UnorderedList>
        </FormHelperText>
        {!isPasswordValid && (
          <FormErrorMessage>
            Password does not meet requirements
          </FormErrorMessage>
        )}
      </FormControl>
      <FormControl isInvalid={!isPasswordMatch}>
        <FormLabel htmlFor="userConfirmPasswordInput">
          Confirm Password
        </FormLabel>
        <Input
          id="userConfirmPasswordInput"
          type="password"
          variant="filled"
          maxLength={50}
          value={confirmPassword}
          onBlur={() => setIsPasswordMatch(checkPasswordMatch())}
          onChange={(e) => setConfirmPassword(e.target.value)}
          placeholder="Confirm Password"
        />
        <FormErrorMessage>Password does not match</FormErrorMessage>
      </FormControl>
    </>
  );
};

export default UserPassword;
