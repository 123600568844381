import { FormControl, FormLabel, VStack } from "@chakra-ui/react";
import { useEffect, useState } from "react";
import GenericFormContainer from "../../common/GenericForm/GenericFormContainer";
import { HTTP_VERB } from "../../enums";
import { getBaseUrl, getDefaultFetchOptions } from "../../utils";
import Select from "react-select";

const DisableCompany = () => {
    const [company, setCompany] = useState("");
    const [isFetching, setIsFetching] = useState(false);
    const [resultMessage, setResultMessage] = useState("");
    const [isError, setIsError] = useState(false);
    const [companyNames, setCompanyNames] = useState([]);

    useEffect(() => {
        async function fetchUsers() {
            const uri = `${getBaseUrl()}/companies/activeNames/?Status=Active`;
            let result = await fetch(uri, getDefaultFetchOptions(HTTP_VERB.GET));
            let names = await result.json();
            if (result.ok) {
                let options = names.map((v) => {
                    return { value: v.company_ID, label: v.company_Name };
                });
                setCompanyNames(options);
            }
        }
        fetchUsers();
    }, []);
    const submit = async () => {
        const uri = `${getBaseUrl()}/companies/${company}/disable`;
        setIsFetching(true);
        try {
            let result = await fetch(uri, getDefaultFetchOptions(HTTP_VERB.PUT));
            if (result.ok) {
                setIsError(false);
                setCompany("");
                setResultMessage(`Company ${company} disabled succesfully.`);
            } else {
                setIsError(true);
                setResultMessage(
                    `Error encountered disabling Company ${company}.  Status Code: ${result.status}`
                );
            }
        } catch (error) {
            console.log(error);
        }
        setIsFetching(false);
    };

    const allowSubmit = !!company.length;
    return (
        <GenericFormContainer
            formMaxWidth={"500px"}
            title={"Disable Company"}
            subTitle="Disable a Company account"
            isResultError={isError}
            isSubmitDisabled={!allowSubmit}
            onSubmitClick={submit}
            isFetching={isFetching}
            resultMessage={resultMessage}
        >
            <VStack align={"flex-start"} p="2">
                <FormControl>
                    <FormLabel htmlFor="userName">Company</FormLabel>
                    <Select
                        id="company"
                        options={companyNames}
                        onChange={(e) => {
                            setCompany(e.label);
                        }}
                    />
                </FormControl>
            </VStack>
        </GenericFormContainer>
    )
}

export default DisableCompany;