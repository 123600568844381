import React, { useState } from "react";
import {
  FormControl,
  FormLabel,
  InputGroup,
  InputRightElement,
  Icon,
  Button,
  Input,
} from "@chakra-ui/react";
import { FaPlusCircle } from "react-icons/fa";
import { MdRemoveCircleOutline } from "react-icons/md";

const DynamicInputList = ({
  initialValues,
  formLabel,
  onInputAdd,
  onInputRemove,
  onValueChange,
  validityRegex,
}) => {
  const [inputValues, setInputValues] = useState(initialValues);
  const [inputValidity, setInputValidity] = useState(
    initialValues.map((v) => {
      return true;
    })
  );
  const handleInputAdd = () => {
    if (onInputAdd) {
      onInputAdd();
    }
    setInputValues((values) => [...values, ""]);
    setInputValidity((values) => [...values, true]);
  };
  const removeInput = (index) => {
    if (onInputRemove) {
      onInputRemove();
    }
    let temp = [...inputValues];
    temp.splice(index, 1);
    setInputValues(temp);

    let tempValidity = [...inputValidity];
    tempValidity.splice(index, 1);
    setInputValidity(tempValidity);

    if (onValueChange) {
      onValueChange(temp);
    }
  };
  const isEmailIndexValueValid = (index) => {
    let value = inputValues[index];
    if (value.length === 0) {
      return true;
    }
    let isValid = true;
    if(validityRegex){
      isValid = value.match(validityRegex) !== null;
    }
    let temp = [...inputValidity];
    temp[index] = isValid;
    setInputValidity(temp);
  };
  const createInput = (key, value, onChange, showRemove) => {
    let isInvalid = !inputValidity[key];
    return (
      <FormControl isInvalid={isInvalid} key={key}>
        <InputGroup>
          <Input
            key={key}
            onBlur={() => isEmailIndexValueValid(key)}
            mb={"2"}
            type="email"
            value={value}
            onChange={(e) => onChange(e.target.value)}
            variant="filled"
          />
          {showRemove && (
            <InputRightElement>
              <Icon
                onClick={() => removeInput(key)}
                as={MdRemoveCircleOutline}
              />
            </InputRightElement>
          )}
        </InputGroup>
      </FormControl>
    );
  };
  const handleInputChange = (index, value) => {
    let temp = [...inputValues];
    temp[index] = value;
    setInputValues(temp);
    if (onValueChange) {
      onValueChange(temp);
    }
  };
  const renderInputs = () => {
    return inputValues.map((v, i) => {
      let showRemove = true;
      if (i === 0) {
        showRemove = false;
      }
      return createInput(i, v, (v) => handleInputChange(i, v), showRemove);
    });
  };
  return (
    <>
      <FormControl>
        <FormLabel>
          {formLabel}
          <Button
            size={"sm"}
            colorScheme={"green"}
            ml={"5px"}
            rightIcon={<Icon as={FaPlusCircle} />}
            onClick={() => handleInputAdd()}
          >
            Add Another
          </Button>
        </FormLabel>
      </FormControl>
      {renderInputs()}
    </>
  );
};

export default DynamicInputList;
