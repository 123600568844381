import { LogLevel } from "@azure/msal-browser";

export function getHostname() {
  let hostname = `${window.location.protocol}//${window.location.hostname}`;

  if (window.location.port === "3000") {
    hostname = `${hostname}:${window.location.port}`;
  }

  return hostname;
}

export const msalConfig = {
  auth: {
    clientId: "8e68495e-e4d1-4f1e-a45c-18eb0a2087b6",
    authority:
      "https://login.microsoftonline.com/1cb4dca1-7fc7-4198-9d6c-f4bc2506782f",
    redirectUri: getHostname(),
  },
  cache: {
    cacheLocation: "localStorage",
    storeAuthStateInCookie: false,
  },
  system: {
    loggerOptions: {
      loggerCallback: (level, message, containsPii) => {
        if (containsPii) {
          return;
        }
        switch (level) {
          case LogLevel.Error:
            console.error(message);
            return;
          case LogLevel.Info:
            console.info(message);
            return;
          case LogLevel.Verbose:
            console.debug(message);
            return;
          case LogLevel.Warning:
          default:
            console.warn(message);
            return;
        }
      },
    },
  },
};

export const loginRequest = {
  scopes: [
    "User.Read",
    "api://8e68495e-e4d1-4f1e-a45c-18eb0a2087b6/access_as_user",
  ],
};

export const tokenRequest = {
  scopes: ["api://8e68495e-e4d1-4f1e-a45c-18eb0a2087b6/access_as_user"],
};
