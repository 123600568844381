import React, { useEffect, useState } from "react";
import {
  FormControl,
  FormLabel,
  Text,
  Input,
  VStack,
  Button,
  useDisclosure,
  ModalContent,
  ModalOverlay,
  Modal,
  ModalHeader,
  ModalFooter,
  ModalCloseButton,
  Alert,
  AlertIcon,
  AlertDescription,
} from "@chakra-ui/react";
import GenericFormContainer from "../../common/GenericForm/GenericFormContainer";
import DynamicInputList from "../../common/DynamicInputList";
import { emailValidationRegex, ifEmptyReturnNull } from "../../utils";

const CompanyCore = ({
  brokerageId,
  emails,
  isEdit,
  isError,
  isFetching,
  name,
  onSubmit,
  oneTicketUsername,
  oneTicketPassword,
  oneTicketFolderName,
  oneTicketUserId,
  oneTicketEmails,
  oneTicketApiKey,
  resultMessage,
  subTitle,
  title,
  companyId,
  consignorEmail,
  isAcceptedTermsandConditions,
  purchasingEmails,
}) => {
  const [companyName, setCompanyName] = useState(name);
  const [notificationEmails, setNotificationEmails] = useState(emails);
  const [localOneTicketUsername, setOneTicketUsername] =
    useState(oneTicketUsername);
  const [localOneTicketPassword, setOneTicketPassword] =
    useState(oneTicketPassword);
  const [localOneTicketFolderName, setOneTicketFolderName] =
    useState(oneTicketFolderName);
  const [localOneTicketUserId, setOneTicketUserId] = useState(oneTicketUserId);
  const [localOneTicketEmails, setOneTicketEmails] = useState(oneTicketEmails);
  const [localBrokerageId, setBrokerageId] = useState(brokerageId);
  const [localCompanyId, setCompanyId] = useState(companyId);
  const [localconsignorEmail, setconsignorEmail] = useState(consignorEmail);
  const [localOneTicketApiKey, setOneTicketApiKey] = useState(oneTicketApiKey);
  const [localisAcceptedTermsandConditions, setIsAcceptedTermsandConditions] =
    useState(isAcceptedTermsandConditions);
  const [
    onAcceptTermsandConditionsMessage,
    setonAcceptTermsandConditionsMessage,
  ] = useState("");
  const [localPurchasingEmails, setPurchasingEmails] = useState([
    purchasingEmails,
  ]);
  const { isOpen, onOpen, onClose } = useDisclosure();
  const allowSubmit = () => {
    return companyName.length > 0;
  };

  const handleSubmit = () => {
    const filteredEmails = notificationEmails.filter((x) => x.length > 1);
    const filteredOneTicketEmails = localOneTicketEmails.filter(
      (x) => x.length > 1
    );
    const filteredPurchasingEmails = localPurchasingEmails.filter(
      (x) => x.length > 1
    );
    let output = {
      brokerageId: ifEmptyReturnNull(localBrokerageId),
      companyName: companyName,
      companyId: localCompanyId,
      emails: filteredEmails,
      oneTicketUserName: ifEmptyReturnNull(localOneTicketUsername),
      oneTicketPassword: ifEmptyReturnNull(localOneTicketPassword),
      oneTicketFolderName: ifEmptyReturnNull(localOneTicketFolderName),
      oneTicketUserId: ifEmptyReturnNull(localOneTicketUserId),
      oneTicketEmails: filteredOneTicketEmails,
      consignorEmail: ifEmptyReturnNull(localconsignorEmail),
      oneTicketApiKey: ifEmptyReturnNull(localOneTicketApiKey),
      isAcceptedTermsandConditions: localisAcceptedTermsandConditions,
      purchasingEmails: filteredPurchasingEmails,
    };
    onSubmit(output);
  };

  const handleAcceptClick = () => {
    setIsAcceptedTermsandConditions(true);
    setonAcceptTermsandConditionsMessage(
      "Thank you for agreeing to terms and conditions"
    );
    onClose();
  };

  const handleDeclineClick = () => {
    setIsAcceptedTermsandConditions(false);
    setonAcceptTermsandConditionsMessage("");
    onClose();
  };

  useEffect(() => {
    if (isAcceptedTermsandConditions) {
      setonAcceptTermsandConditionsMessage(
        "You have already accepted the terms and conditions"
      );
    }
  }, [isAcceptedTermsandConditions]);

  return (
    <GenericFormContainer
      formMaxWidth={"500px"}
      title={title}
      subTitle={subTitle}
      isResultError={isError}
      isSubmitDisabled={!allowSubmit()}
      onSubmitClick={() => handleSubmit()}
      isFetching={isFetching}
      resultMessage={resultMessage}
      width={"800px"}
    >
      <VStack align={"flex-start"} p="2">
        <FormControl>
          {isEdit && (
            <>
              <FormLabel htmlFor="companyName">Company Name </FormLabel>
              <Text mb={"10px"}>{companyName}</Text>
              <FormLabel htmlFor="companyId">Company Id </FormLabel>
              <Text mb={"10px"}>{companyId}</Text>
            </>
          )}

          {!isEdit && (
            <>
              <FormLabel htmlFor="companyId">Company Id </FormLabel>
              <Input
                id="companyId"
                placeholder="Enter Company ID"
                type="number"
                isDisabled={isEdit}
                variant={"filled"}
                value={localCompanyId}
                onChange={(e) => setCompanyId(e.target.value)}
              />
              <FormLabel htmlFor="companyName">Company Name </FormLabel>
              <Input
                id="companyName"
                isDisabled={isEdit}
                type="text"
                maxLength={128}
                placeholder="Enter Company Name"
                variant={"filled"}
                value={companyName}
                onChange={(e) => setCompanyName(e.target.value)}
              />
            </>
          )}
        </FormControl>

        <Button mt={5} mb={5} colorScheme="green" onClick={() => onOpen()}>
          Agree to Terms and Conditions
        </Button>
        {localisAcceptedTermsandConditions && (
          <Alert status="success">
            <AlertIcon />
            <AlertDescription>
              {onAcceptTermsandConditionsMessage}
            </AlertDescription>
          </Alert>
        )}

        <DynamicInputList
          initialValues={emails}
          formLabel={"Notification Email"}
          onValueChange={(v) => setNotificationEmails(v)}
          validityRegex={emailValidationRegex}
        />
        <FormControl>
          <FormLabel htmlFor="brokerageInput">Brokerage Id</FormLabel>
          <Input
            variant="filled"
            type="number"
            placeholder="Enter Brokerage ID"
            value={localBrokerageId}
            onChange={(e) => setBrokerageId(e.target.value)}
          />
        </FormControl>
        <FormControl>
          <FormLabel htmlFor="userName">1Ticket API User Name</FormLabel>
          <Input
            id="userName"
            type="text"
            placeholder="Enter API User Name"
            maxLength={128}
            variant={"filled"}
            value={localOneTicketUsername}
            onChange={(e) => setOneTicketUsername(e.target.value)}
          />
        </FormControl>
        <FormControl>
          <FormLabel htmlFor="password">1Ticket API Password</FormLabel>
          <Input
            id="password"
            type="text"
            maxLength={50}
            placeholder="Enter API Password"
            variant={"filled"}
            value={localOneTicketPassword}
            onChange={(e) => setOneTicketPassword(e.target.value)}
          />
        </FormControl>
        <FormControl>
          <FormLabel htmlFor="folderName">
            1Ticket Dropbox Folder Name
          </FormLabel>
          <Input
            id="folderName"
            type="text"
            maxLength={50}
            placeholder="Enter Dropbox Folder Name"
            variant={"filled"}
            value={localOneTicketFolderName}
            onChange={(e) => setOneTicketFolderName(e.target.value)}
          />
        </FormControl>
        <FormControl>
          <FormLabel htmlFor="userID">1Ticket AutoPO_User_ID</FormLabel>
          <Input
            id="userID"
            type="number"
            maxLength={50}
            placeholder="Enter AutoPO_User_ID"
            variant={"filled"}
            value={localOneTicketUserId}
            onChange={(e) => setOneTicketUserId(e.target.value)}
          />
        </FormControl>
        <DynamicInputList
          initialValues={oneTicketEmails}
          formLabel={"1Ticket Email"}
          onValueChange={(v) => setOneTicketEmails(v)}
          validityRegex={emailValidationRegex}
        />

        <FormControl>
          <FormLabel htmlFor="consignorEmail">
            StubHub Consignor Email
          </FormLabel>
          <Input
            id="consignorEmail"
            type="email"
            maxLength={100}
            placeholder="Enter StubHub Consignor Email"
            variant={"filled"}
            value={localconsignorEmail}
            onChange={(e) => setconsignorEmail(e.target.value)}
          />
        </FormControl>
        <FormControl>
          <FormLabel htmlFor="oneTicketApiKeyInput">1Ticket API key</FormLabel>
          <Input
            id="oneTicketApiKeyInput"
            type="text"
            maxLength={50}
            placeholder="Enter 1Ticket API Key"
            variant={"filled"}
            value={localOneTicketApiKey}
            onChange={(e) => setOneTicketApiKey(e.target.value)}
          />
        </FormControl>
        <DynamicInputList
          initialValues={purchasingEmails}
          formLabel={"Purchasing Emails"}
          onValueChange={(v) => setPurchasingEmails(v)}
          validityRegex={emailValidationRegex}
        />
      </VStack>
      {isOpen && (
        <Modal
          onClose={onClose}
          isOpen={isOpen}
          scrollBehavior={"inside"}
          size={"md"}
        >
          <ModalOverlay />
          <ModalContent>
            <ModalHeader>Agree Terms and Conditions</ModalHeader>
            <ModalCloseButton />
            <ModalFooter>
              <Button mr={3} onClick={handleDeclineClick}>
                Decline
              </Button>
              <Button colorScheme="blue" onClick={handleAcceptClick}>
                Accept
              </Button>
            </ModalFooter>
          </ModalContent>
        </Modal>
      )}
    </GenericFormContainer>
  );
};

export default CompanyCore;
