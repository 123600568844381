import { FormControl, FormLabel, Input, VStack } from "@chakra-ui/react";
import React, { useState } from "react";
import GenericFormContainer from "../../common/GenericForm/GenericFormContainer";
import { HTTP_VERB } from "../../enums";
import { getBaseUrl, getDefaultFetchOptions } from "../../utils";

const MapStubhub = () => {
  const [sfEventId, setSfEventId] = useState("");
  const [shEventId, setShEventId] = useState("");
  const [isFetching, setIsFetching] = useState(false);
  const [resultMessage, setResultMessage] = useState("");
  const [isError, setIsError] = useState(false);
  const submit = async () => {
    const uri = `${getBaseUrl()}/events/map`;
    setIsFetching(true);
    try {
      const body = {
        sfEventId: sfEventId,
        shEventId: shEventId
      };
      let result = await fetch(uri, {
        ...getDefaultFetchOptions(HTTP_VERB.POST),
        body: JSON.stringify(body),
      });
      if (result.ok) {
        setIsError(false);
        setResultMessage(`Mapped StageFront event ${sfEventId} to StubHub event ${shEventId} succesfully.`);
      } else {
        setIsError(true);
        setResultMessage(
          `Error encountered mapping StageFront event ${sfEventId} to StubHub event ${shEventId}. Status Code: ${result.status}`
        );
      }
    } catch (error) {
      console.log(error);
    }
    setIsFetching(false);
    setSfEventId("");
    setShEventId("");
  };
  const allowSubmit = () => {
    return sfEventId.length > 0 && shEventId.length > 0;
  };
  return (
    <GenericFormContainer
      formMaxWidth={"500px"}
      title={"Map Stubhub Event"}
      subTitle="Manually map Stubhub event"
      isResultError={isError}
      isSubmitDisabled={!allowSubmit()}
      onSubmitClick={() => submit()}
      isFetching={isFetching}
      resultMessage={resultMessage}
    >
      <VStack align={"flex-start"} p="2">
        <FormControl>
          <FormLabel htmlFor="sfEventId">StageFront Event ID</FormLabel>
          <Input
            id="sfEventId"
            type="number"
            value={sfEventId}
            onChange={(e) => setSfEventId(e.target.value)}
            placeholder="Enter StageFront Event ID"
            variant={"filled"}
            required={true}
          />
        </FormControl>
        <FormControl>
          <FormLabel htmlFor="shEventId">Stubhub Event ID</FormLabel>
          <Input
            id="shEventId"
            type="number"
            value={shEventId}
            onChange={(e) => setShEventId(e.target.value)}
            placeholder="Enter Stubhub Event ID"
            variant={"filled"}
            required={true}
          />
        </FormControl>
      </VStack>
    </GenericFormContainer>
  );
};

export default MapStubhub;
