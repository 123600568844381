import { Box, Flex, Icon } from "@chakra-ui/react";
import React from "react";
import Sidebar from "../../common/Sidebar/Sidebar";
import {
    FaArrowsAlt,
    FaCodeBranch
  } from "react-icons/fa";
import { Routes, Route } from "react-router-dom";
import { Outlet } from "react-router-dom";
import AddDeleteVM from "./AddDeleteVM";

const subMenu = [
    {
      icon: <Icon as={FaCodeBranch} />,
      path: "",
      label: "Add/Delete VM",
    },
    {
      icon: <Icon as={FaArrowsAlt} />,
      path: "mangeVM",
      label: "Manage VM",
    },
  ];

const VMHome = () => {
  return (
    <Flex direction={"row"}>
      <Sidebar isOpen={true} title={"VM Management"} linkItems={subMenu} />
      <Box h="full" p="4">
        <Flex direction={"column"}>
          <Routes>
            <Route path="/" element={<AddDeleteVM />} />
          </Routes>
          <Outlet />
        </Flex>
      </Box>
    </Flex>
  )
}

export default VMHome