import React, { useState, useEffect } from "react";
import { useMsal } from "@azure/msal-react";
import { loginRequest, tokenRequest } from "../authConfig";
import "../styles/App.css";
import Header from "./Header/Header";
import { Routes, Route, useLocation } from "react-router-dom";
import Home from "./Home";
import { Flex } from "@chakra-ui/react";
import CompanyHome from "../features/companySetup/CompanyHome";
import EventsHome from "../features/events/EventsHome";
import CreateCompany from "../features/companySetup/CreateCompany";
import MarvinHome from "../features/marvin/MarvinHome";
import UsersHome from "../features/users/UsersHome";
import TMAuthCodes from "../features/orders/TMAuthCodes";
import AddUser from "../features/users/AddUser";
import PortalHome from "../features/portal/PortalHome";
import DisableUser from "../features/users/DisableUser";
import CloneUser from "../features/users/CloneUser";
import EmailOptOut from "../features/users/EmailOptOut";
import ImportInventory from "../features/companySetup/ImportInventory";
import DisableCompany from "../features/companySetup/DisableCompany";
import UpdateCredentials from "../features/companySetup/UpdateCredentials";
import Vivid from "../features/companySetup/Vivid";
import EditCompany from "../features/companySetup/EditCompany";
import EditUser from "../features/users/EditUser";
import OrdersHome from "../features/orders/OrdersHome";
import AddMarvinCompany from "../features/marvin/AddMarvinCompany";
import Move from "../features/events/Move";
import MapStubhub from "../features/events/MapStubhub";
import AlfredTicketMasterLink from "../features/events/AlfredTicketMasterLink";
import TransferInventory from "../features/events/TransferInventory";
import EventSearch from "../features/events/EventSearch";
import AccountingHome from "../features/accounting/AccountingHome";
import ConsignorRatesHome from "../features/accounting/ConsignorRates/ConsignorRatesHome";
import ConsignorRatesList from "../features/accounting/ConsignorRates/ConsignorRatesList";
import { getDefaultFetchOptions, getBaseUrl, allowByRole } from "../utils";
import { HTTP_VERB, ROLES } from "../enums";
import ProtectedRoute from "./ProtectedRoute";
import OneTicketTransferHome from "../features/orders/OneTicketTransfer/OneTicketTransferHome";
import OneTicketTransferSearch from "../features/orders/OneTicketTransfer/OneTicketTransferSearch";
import OneTicketBatchTransfer from "../features/orders/OneTicketTransfer/OneTicketBatchTransfer";
import OneTicketPendingInvoices from "../features/orders/OneTicketTransfer/OneTicketPendingInvoices";
import OneTicketManualSearch from "../features/orders/OneTicketTransfer/OneTicketManualSearch";
import OneTicketEventMapManagement from "../features/orders/OneTicketTransfer/OneTicketEventMapManagement";
import TMAuthCodeView from "../features/orders/TMAuthCodeView";
import AXSAuthCodes from "../features/orders/AXSAuthCodes";
import AXSAuthCodeView from "../features/orders/AXSAuthCodeView";
import WEXHome from "../features/wex/WEXHome";
import AlfredHome from "../features/alfred/alfredHome";
import AddDeleteVM from "../features/vm/AddDeleteVM";
import ManageVM from "../features/vm/ManageVM";
import VMHome from "../features/vm/VMHome";
import CredentialsList from "../features/marvin/CredentialsList";
import ImportBarcodes from "../features/companySetup/ImportBarcodes";
import Intervention from "../features/orders/Intervention";
import { useCookies } from "react-cookie";

function App() {
  const location = useLocation();
  const [roles, setRoles] = useState([]);
  const { instance, accounts } = useMsal();
  const [cookies, setCookie, removeCookie] = useCookies(["msalToken"]);
  const [userName, setUserName] = useState("");

  const setupUser = async (account) => {
    const request = {
      ...tokenRequest,
      account: account,
    };
    const response = await instance.acquireTokenSilent(request);
    setCookie("msalToken", response.accessToken);
    let name = account.username.replace("@stagefront.com", "");
    setUserName(name);
    const uri = `${getBaseUrl()}/adminToolRoles/userName/${name}`;
    const rolesResponse = await fetch(uri, getDefaultFetchOptions(HTTP_VERB.GET));
    if (rolesResponse.ok) {
      let result = await rolesResponse.json();
      setRoles(result.map((v) => v.adminToolRoleId));
    }
  }

  const handleLogin = () => {
    instance.loginPopup(loginRequest)
    .then(async (result)=>{
      await setupUser(result.account);
    })
    .catch((e) => {
      console.error(e);
    });
  };

  const handleLogout = () => {
    instance
      .logoutPopup()
      .then(() => {
        removeCookie("msalToken");
        setUserName("");
        setRoles([]);
      })
      .catch((e) => {
        console.error(e);
      });
  };

  const isLoggedIn = () => {
    return userName !== "";
  }

  useEffect(()=>{
    const msalToken = cookies['msalToken'];
    if(msalToken){
      if(!userName){
        setupUser(accounts[0]);
      }
    }
  });

  return (
    <div className="App">
      <Header
        username={userName}
        roles={roles}
        handlelogin={handleLogin}
        handlelogout={handleLogout}
        isLoggedIn={isLoggedIn}
      />
      <Flex
        direction="column"
        maxW={{
          xl: `${
            location.pathname === "/password-manager" ? "none" : "1200px"
          }`,
        }}
      >
        <Routes>
          <Route path="/" element={<Home />} />
          <Route
            element={
              <ProtectedRoute isAllowed={allowByRole(roles, ROLES.Support)} />
            }
          >
            <Route path="company/*" element={<CompanyHome />}>
              <Route path="add" element={<CreateCompany />} />
              <Route path="1ticket" element={<UpdateCredentials />} />
              <Route path="vivid" element={<Vivid />} />
              <Route path=":company" element={<EditCompany />} />
              <Route
                path="importInventory"
                element={<ImportInventory username={cookies.userName} />}
              />
              <Route path="disableCompany" element={<DisableCompany />} />
            </Route>
          </Route>
          <Route element={<ProtectedRoute isAllowed={true} />}>
            <Route path="marvin/*" element={<MarvinHome />}>
              <Route path="addCompany" element={<AddMarvinCompany />} />
              <Route path="launchCrawler" element={<CredentialsList />} />
              <Route path="importBarcodes" element={<ImportBarcodes />} />
            </Route>
          </Route>
          <Route element={<ProtectedRoute isAllowed={true} />}>
            <Route path="events/*" element={<EventsHome />}>
              <Route path="moveEvents" element={<Move />} />
              <Route path="mapShEvent" element={<MapStubhub />} />
              <Route path="alfredtmlink" element={<AlfredTicketMasterLink />} />
              <Route path="transferInventory" element={<TransferInventory />} />
              <Route path="eventsearch" element={<EventSearch />} />
            </Route>
          </Route>
          <Route
            element={
              <ProtectedRoute isAllowed={allowByRole(roles, ROLES.IT)} />
            }
          >
            <Route path="users/*" element={<UsersHome />}>
              <Route path="add" element={<AddUser />} />
              <Route path="disable" element={<DisableUser />} />
              <Route path="clone" element={<CloneUser />} />
              <Route
                path="emailOptOut"
                element={<EmailOptOut username={userName} />}
              />
              <Route path=":user" element={<EditUser />} />
            </Route>
          </Route>
          <Route path="portal/*" element={<PortalHome />} />
          <Route path="orders/*" element={<OrdersHome />}>
            <Route path="oneticketxfer" element={<OneTicketTransferHome />} />
            <Route
              path="oneticketxfersearch"
              element={<OneTicketTransferSearch />}
            />
            <Route path="oneticketbatch" element={<OneTicketBatchTransfer />} />
            <Route
              path="oneticketeventsearch"
              element={<OneTicketManualSearch />}
            >
              <Route path={":invoiceId"} element={<OneTicketManualSearch />} />
            </Route>
            <Route
              path="oneticketpending"
              element={<OneTicketPendingInvoices />}
            />
            <Route
              path="oneticketeventmap"
              element={<OneTicketEventMapManagement />}
            />
            <Route path="tmAuth" element={<TMAuthCodes />} />
            <Route path=":tmAuth" element={<TMAuthCodeView />} />
            <Route path="axsAuth" element={<AXSAuthCodes />} />
            <Route path="axsView" element={<AXSAuthCodeView />} />
            <Route path="orderintervention" element={<Intervention />} />
          </Route>
          <Route
            element={
              <ProtectedRoute
                isAllowed={allowByRole(roles, ROLES.Accounting)}
              />
            }
          >
            <Route path="accounting/*" element={<AccountingHome />}>
              <Route path="consignorrates/*" element={<ConsignorRatesHome />}>
                <Route path=":company" element={<ConsignorRatesList />} />
              </Route>
            </Route>
          </Route>
          <Route
            element={
              <ProtectedRoute isAllowed={allowByRole(roles, ROLES.WEX)} />
            }
          >
            <Route
              path="wex/*"
              element={<WEXHome username={userName} />}
            ></Route>
            <Route path="alfred/*" element={<AlfredHome />}></Route>
          </Route>
          <Route
            element={
              <ProtectedRoute isAllowed={allowByRole(roles, ROLES.IT)} />
            }
          >
            <Route path="vm/*" element={<VMHome />}>
              <Route path="adddeletevm" element={<AddDeleteVM />} />
              <Route path="mangevm" element={<ManageVM />} />
            </Route>
          </Route>
        </Routes>
      </Flex>
    </div>
  );
}

export default App;
