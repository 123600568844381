import React, { useState, useEffect } from "react";
import ComponentContainer from "../../../common/ComponentContainer";
import FormHeader from "../../../common/FormHeader";
import { getBaseUrl, getDefaultFetchOptions } from "../../../utils";
import { HTTP_VERB, TABLE_MODE } from "../../../enums";
import TableCore from "../../../common/TableCore";
import { DateTime } from "luxon";

const columns = [
  { label: "Headliner", renderCell: (item) => item.event_Headliner },
  {
    label: "Event Date",
    renderCell: (item) => DateTime.fromISO(item.event_Date).toLocaleString(),
  },
  {
    label: "Invoice ID",
    renderCell: (item) => item.invoice_ID,
  },
  {
    label: "Company",
    renderCell: (item) => item.company,
  },
];

const createRow = (item) => {
  return {
    id: item.invoice_ID,
    ...item,
  };
};

const OneTicketNotFound = ({ onRowClick }) => {
  const [values, setValues] = useState();
  const [search, setSearch] = useState("");
  useEffect(() => {
    (async () => {
      const uri = `${getBaseUrl()}/invoice/notFound`;
      let result = await fetch(uri, getDefaultFetchOptions(HTTP_VERB.GET));
      if (result.ok) {
        let response = await result.json();
        let temp = [];
        for (const i of response) {
          temp.push(createRow(i));
        }
        setValues(temp);
      }
    })();
  }, []);
  const getData = () => {
    if (!search) {
      return values;
    }
    return values.filter((x) => {
      return JSON.stringify(x).toLowerCase().includes(search.toLowerCase());
    });
  };
  let data = () => {
    return {
      nodes: getData(),
    };
  };
  const handleRowClick = (action, state) => {
    if (onRowClick) {
      onRowClick(action);
    }
  };
  return (
    <ComponentContainer width={990} mt={2}>
      <FormHeader
        title={"Events Not Found on 1Ticket"}
        subTitle={
          "Displays a list of Event Headliners for events unable to be located on 1Ticket"
        }
      />
      {values && (
        <TableCore
          columns={columns}
          data={data()}
          height={"660px"}
          width={"980px"}
          onRowSelect={handleRowClick}
          onSearchChange={(e) => setSearch(e)}
          mode={TABLE_MODE.CLICKABLE}
          pageSize={10}
          showSearch={true}
        />
      )}
    </ComponentContainer>
  );
};

export default OneTicketNotFound;
