import React, { useEffect, useState } from "react";
import { Box, Spinner, Button, Input, HStack } from "@chakra-ui/react";
import TableCore from "../../common/TableCore";
import { useNavigate } from "react-router-dom";
import { getDefaultFetchOptions, getBaseUrl } from "../../utils";
import { HTTP_VERB, TABLE_MODE } from "../../enums";
import { RepeatIcon, Search2Icon } from '@chakra-ui/icons'
const columns = [
  { label: "", renderCell: (item) => item.to },
  {
    label: "",
    renderCell: (item) => item.subject.toString(),
  },
  {
    label: "",
    renderCell: (item) => item.date
  }
];

const TMAuthCodes = () => {
  const [search, setSearch] = useState("");
  const navigate = useNavigate();
  const [emails, setEmails] = useState([]);
  const [nextPageToken, setNextPageToken] = useState("");
  const [isFetching, setIsFetching] = useState(false);
  const [count, setCounts] = useState(0);

  useEffect(() => {
    getLatestEmails();
  }, []);

  const getLatestEmails = () => {
    async function getEmails() {
      setIsFetching(true);
      const uri = `${getBaseUrl()}/GMail/GetReadLabelTMAuthCode`;
      let request = await fetch(uri, getDefaultFetchOptions(HTTP_VERB.GET));
      if (request.ok) {
        let response = await request.json();
        setEmails(response);
        if (response.length > 0) {
          setNextPageToken(response[0]?.nextPageToken);
          setCounts(response.length);
        }

      }
      setIsFetching(false);
    }
    getEmails();
  };
  const fetchMoreRecords = () => {
    async function getNextEmails() {
      setIsFetching(true);
      if (nextPageToken !== "") {
        const uri = `${getBaseUrl()}/GMail/GetNewEmailsListByPagination?type=TM&pageToken=${nextPageToken}&searchItem=${search}`;
        let request = await fetch(uri, getDefaultFetchOptions(HTTP_VERB.GET));
        if (request.ok) {
          let response = await request.json();
          if (response.length > 0) {
            setCounts(response.length);
            setNextPageToken(response[0]?.nextPageToken);
          }
          setEmails(prevRecords => [...emails, ...response]);

        } else {
          console.log("Error fetching emails");
        }
        setIsFetching(false);
      }
    }
    getNextEmails();
  };
  const clearSearch = () => {
    setSearch("");
    setNextPageToken("");
    setCounts(0);
    getLatestEmails();
  };
  const searchRecords = () => {
    async function getSearchedEmails() {
      setIsFetching(true);
      if (nextPageToken !== "") {
        const uri = `${getBaseUrl()}/GMail/GetReadLabelTMAuthCode?searchEmail=${search}`;
        let request = await fetch(uri, getDefaultFetchOptions(HTTP_VERB.GET));
        if (request.ok) {
          let response = await request.json();
          setEmails(response);
          if (response.length > 0) {
            setCounts(response.length);
            setNextPageToken(response[0]?.nextPageToken);
          }
        }
      }
      setIsFetching(false);
    }
    getSearchedEmails();
  };

  const data = () => {
    let output = { nodes: [] };
    if (!emails || emails.length === 0) {
      return output;
    }
    if (!search) {
      output.nodes = emails;
    }
    let data = emails;
    output.nodes = data;
    return output;
  };
  const handleRowClick = (action, state) => {
    localStorage.removeItem("GmailId");
    localStorage.setItem("GmailId", `${action.payload.id}`);
    navigate({
      pathname: '/orders/tmview',
      search: `${action.payload.id}`,
    });
  };

  return (
    <Box>
      {isFetching && <Spinner stylr size='xl' />}
      <HStack spacing={2}>
        <Input
          id="searchId"
          placeholder="Search..."
          type="text"
          variant={"filled"}
          value={search}
          onChange={(e) => setSearch(e.target.value)}
        />
        <Button
          onClick={searchRecords}
          colorScheme={"teal"}
          variant="solid"
        >
          <Search2Icon />
        </Button>
        <Button colorScheme={"teal"}
          variant="solid" onClick={clearSearch}><RepeatIcon /></Button>
        {count > 14 &&
          <Button onClick={fetchMoreRecords} colorScheme={"teal"} variant="solid">
            More Records
          </Button>
        }
      </HStack>
      <TableCore
        columns={columns}
        data={data()}
        height={"800px"}
        width={"800px"}
        onRowSelect={handleRowClick}
        pageSize={15}
        mode={TABLE_MODE.CLICKABLE}
        isFetching={isFetching}
      />
    </Box>
  );
};

export default TMAuthCodes;
