import React from "react";
import { HStack, Link, Text } from "@chakra-ui/react";
import { Link as ReactRouterLink } from "react-router-dom";
import { useMatch, useResolvedPath } from "react-router-dom";

const SidebarMenuItem = ({ path, label, icon }) => {
  let resolved = useResolvedPath(path);
  let match = useMatch({ path: resolved.pathname, end: true });
  const showUnderline = () => {
    if (match) {
      return "#f9564f";
    }
    return "black";
  };
  return (
    <Link as={ReactRouterLink} to={path} mb={"10px"}>
      <HStack color={showUnderline()}>
        {icon}
        <Text>{label}</Text>
      </HStack>
    </Link>
  );
};

export default SidebarMenuItem;
