import React from "react";
import { Flex, Stack, Heading, Text } from "@chakra-ui/react";
import HeroImage from "./HeroImage";

const Hero = ({ headingText, detailsText, imgSrc }) => {
  return (
    <Flex
      align="center"
      justify={{ base: "center", md: "space-around", xl: "space-between" }}
      direction={{ base: "column-reverse", md: "row" }}
      wrap="no-wrap"
      minH="70vh"
      px={8}
      mb={16}
    >
      <Stack
        spacing={4}
        w={{ base: "80%", md: "40%" }}
        align={["center", "center", "flex-start", "flex-start"]}
      >
        <Heading
          as="h2"
          size="xl"
          color="primary.800"
          opacity="0.8"
          fontWeight="normal"
          lineHeight={1.5}
          textAlign={["center", "center", "left", "left"]}
        >
          {headingText}
        </Heading>
        <Text
          fontSize="xs"
          mt={2}
          textAlign="center"
          color="primary.800"
          opacity="0.6"
        >
          {detailsText}
        </Text>
      </Stack>
      <HeroImage imgSrc={imgSrc} />
    </Flex>
  );
};

export default Hero;
