import React, { useState } from "react";
import {
  FormControl,
  FormLabel,
  FormErrorMessage,
  Input,
  VStack,
} from "@chakra-ui/react";
import GenericFormContainer from "../../common/GenericForm/GenericFormContainer";
import { HTTP_VERB } from "../../enums";
import {
  getBaseUrl,
  getDefaultFetchOptions,
  emailValidationRegex,
} from "../../utils";
import CompanySelect from "../../common/CompanySelect";
import UserPassword from "./UserPassword";

const AddUser = () => {
  const [isFetching, setIsFetching] = useState(false);
  const [companyName, setCompanyName] = useState("");
  const [resultMessage, setResultMessage] = useState("");
  const [isError, setIsError] = useState(false);
  const [userName, setUserName] = useState("");
  const [password, setPassword] = useState("NewUser1!");
  const [emailAddress, setEmailAddress] = useState("");
  const [isPasswordValid, setIsPasswordValid] = useState(true);
  const [isEmailValid, setIsEmailValid] = useState(true);

  const allowSubmit = () => {
    return (
      isEmailValid &&
      isPasswordValid &&
      userName.length > 0 &&
      companyName.length
    );
  };
  const submit = async () => {
    const uri = `${getBaseUrl()}/users`;
    setIsFetching(true);
    let body = {
      username: userName,
      password: password,
      emailAddress: emailAddress,
      company: companyName,
    };
    try {
      let result = await fetch(uri, {
        ...getDefaultFetchOptions(HTTP_VERB.POST),
        body: JSON.stringify(body),
      });
      if (result.ok) {
        setIsError(false);
        let message = `Successfully added ${userName}.`;
        setResultMessage(message);
        setCompanyName("");
        setUserName("");
        setPassword("");
        setEmailAddress("");
      } else {
        setIsError(true);
        try {
          let responseMessage = await result.json();
          setResultMessage(
            `Error adding user ${userName}.  Status Code: ${result.status}.  ${responseMessage?.detail}`
          );
        } catch (error) {
          let responseText = await result.text();
          setResultMessage(`Error adding user ${responseText}.`);
        }
      }
    } catch (error) {
      console.log(error);
    }
    setIsFetching(false);
  };
  return (
    <GenericFormContainer
      formMaxWidth={"500px"}
      title={"Add User"}
      subTitle="Create a new Portal User"
      isResultError={isError}
      isSubmitDisabled={!allowSubmit()}
      onSubmitClick={() => submit()}
      isFetching={isFetching}
      resultMessage={resultMessage}
      width={"800px"}
    >
      <VStack align={"flex-start"} p="2">
        <CompanySelect
          companyName={companyName}
          onCompanyNameChange={(name) => setCompanyName(name)}
          shouldContainTransferKey={false}
        />
        <FormControl>
          <FormLabel htmlFor="userName">User Name</FormLabel>
          <Input
            id="userName"
            type="text"
            value={userName}
            onChange={(e) => setUserName(e.target.value)}
            placeholder="Enter User Name"
            variant={"filled"}
          />
        </FormControl>
        <UserPassword
          defaultPassword={"NewUser1!"}
          onPasswordChange={(e) => setPassword(e)}
          onValidityChange={(e) => setIsPasswordValid(e)}
        />
        <FormControl isInvalid={!isEmailValid}>
          <FormLabel htmlFor="userEmailAddressInput">Email Address</FormLabel>
          <Input
            id="userEmailAddressInput"
            type="email"
            variant="filled"
            maxLength={50}
            onBlur={() =>
              setIsEmailValid(emailAddress.match(emailValidationRegex))
            }
            value={emailAddress}
            onChange={(e) => setEmailAddress(e.target.value)}
            placeholder="Enter Email Address"
          />
          <FormErrorMessage>Email Address is invalid</FormErrorMessage>
        </FormControl>
      </VStack>
    </GenericFormContainer>
  );
};

export default AddUser;
