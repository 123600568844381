import React, { useState } from 'react'
import GenericFormContainer from '../../common/GenericForm/GenericFormContainer';
import { FormControl, FormLabel, Input, VStack } from '@chakra-ui/react';
import { HTTP_VERB } from "../../enums";
import { getBaseUrl, getDefaultFetchOptions } from "../../utils"

const AddDeleteVM = () => {
  
  const [resourcegroup, setResourceGroup] = useState("");
  const [vmname, setVmName] = useState("");
  const [isFetching, setIsFetching] = useState(false);
  const [resultMessage, setResultMessage] = useState("");
  const [isError, setIsError] = useState(false);
  const submit = async () => {
    const uri = `${getBaseUrl()}/vm/add`;
    setIsFetching(true);
    try {
      const body = {
        resourceGroup: resourcegroup,
        vmName: vmname
      };
      let result = await fetch(uri, {
        ...getDefaultFetchOptions(HTTP_VERB.POST),
        body: JSON.stringify(body),
      });
      if (result.ok) {
        setIsError(false);
        setResultMessage(`Added VM ${vmname} with Resource Group ${resourcegroup} succesfully.`);
      } else {
        const response = await result.json();
        const errorMessage = response.detail ? response.detail : response.title;
        setIsError(true);
        setResultMessage(
          `Error encountered adding VM ${vmname} with Resource Group ${resourcegroup}. Error Message: ${errorMessage}`
        );
      }
    } catch (error) {
      console.log(error);
    }
    setIsFetching(false);
    setResourceGroup("");
    setVmName("");
  };
  const allowSubmit = () => {
    return resourcegroup.length > 0 && vmname.length > 0;
  };

  return (
    <GenericFormContainer
      formMaxWidth={"500px"}
      title={"Add VM to Database"}
      subTitle="Add VM details to manage state"
      isResultError={isError}
      isSubmitDisabled={!allowSubmit()}
      onSubmitClick={() => submit()}
      isFetching={isFetching}
      resultMessage={resultMessage}
    >
      <VStack align={"flex-start"} p="2">
        <FormControl>
          <FormLabel htmlFor="resourcegroup">Resource Group</FormLabel>
          <Input
            id="resourcegroup"
            type="text"
            value={resourcegroup}
            onChange={(e) => setResourceGroup(e.target.value)}
            placeholder="Enter Resource Group Name"
            variant={"filled"}
            required={true}
          />
        </FormControl>
        <FormControl>
          <FormLabel htmlFor="vmname">Enter VM Name</FormLabel>
          <Input
            id="vmname"
            type="text"
            value={vmname}
            onChange={(e) => setVmName(e.target.value)}
            placeholder="Enter VM Name"
            variant={"filled"}
            required={true}
          />
        </FormControl>
      </VStack>
    </GenericFormContainer>
  );
}

export default AddDeleteVM