import { ROLES } from "./enums";
import { useToast } from "@chakra-ui/react";
import { Cookies } from 'react-cookie';

export function getBaseUrl() {
  return getApiUrl(window.location.protocol, window.location.hostname);
}

export function getWebUrl() {
  return (
    window.location.protocol +
    "//" +
    window.location.hostname +
    window.location.pathname
  );
}

export function getApiUrl(protocol, hostname) {
  var url = protocol + "//";
  if (window.location.port === "3000") {
    url = url + hostname + ":5007";
    return url;
  }
  url += `${hostname}/api`;
  return url;
}

export function getDefaultFetchOptions(HTTP_VERB, contentType = true) {
  const cookies = new Cookies();
  const msalToken = cookies.get('msalToken');
  const headers = {
    "Upgrade-Insecure-Requests": 1,
    "Authorization": `Bearer ${msalToken}`
  };
  if(contentType){
    headers["Content-Type"] = "application/json";
  }
  return {
    method: HTTP_VERB,
    credentials: "include",
    mode: "cors",
    headers: headers,
  };
}

export const emailValidationRegex = /^\S+@\S+\.\S+$/;
export const passwordStrengthRegex =
  /(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[^A-Za-z0-9])(?=.{6,})/;

export function ifEmptyReturnNull(value) {
  if (!value || value.length < 1) {
    return null;
  }
  return value;
}

export function allowByRole(roles, requiredRole) {
  if (!roles || !roles.length) {
    return false;
  }
  if (roles.includes(ROLES.IT)) {
    return true;
  }
  return roles.includes(requiredRole);
}

// TODO: Test this
export function useCustomToast(isError, title, desc) {
  const toast = useToast();
  let status = "success";
  if (isError) {
    status = "error";
  }
  toast({
    title: title,
    description: desc,
    status: status,
    duration: 3000,
    isClosable: true,
  });
}

export const convertFileSize = (sizeInBytes) => {
  if (sizeInBytes >= 1000000) {
    return (sizeInBytes / 1000000).toFixed(2) + " MB";
  } else if (sizeInBytes >= 1000) {
    return (sizeInBytes / 1000).toFixed(2) + " KB";
  } else {
    return sizeInBytes + " bytes";
  }
};
