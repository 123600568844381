import React from "react";
import { Box, Flex, Icon } from "@chakra-ui/react";
import Sidebar from "../../common/Sidebar/Sidebar";
import { Outlet, Routes, Route } from "react-router-dom";
import {CiCreditCard1} from "react-icons/ci"
import AlfredCards from "./alfredCard";
 

const AlfredHome = () => {

    const subMenu = [
        {
            icon: <Icon as={CiCreditCard1} />,
            path: "",
            label: "List",
        },
    ];

    return (
        <Flex direction={"row"}>
            <Sidebar isOpen={true} title={"Alfred"} linkItems={subMenu} />
            <Box h="full" p="4" w="100%">
                <Flex direction={"column"}>
                    <Routes>
                        <Route path="/" element={<AlfredCards />} />
                    </Routes>
                    <Outlet />
                </Flex>
            </Box>
        </Flex>
    );
}

export default AlfredHome;