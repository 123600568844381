import React from "react";
import { Box, Flex, Icon } from "@chakra-ui/react";
import Sidebar from "../../common/Sidebar/Sidebar";
import { Outlet, Routes, Route } from "react-router-dom";
import {CiCreditCard1} from "react-icons/ci"
import WEXCards from "./WEXCards";

const WEXHome = ({ username }) => {

    const subMenu = [
        {
            icon: <Icon as={CiCreditCard1} />,
            path: "",
            label: "Create Cards",
        },
    ];

    return (
        <Flex direction={"row"}>
            <Sidebar isOpen={true} title={"WEX Service"} linkItems={subMenu} />
            <Box h="full" p="4" w="100%">
                <Flex direction={"column"}>
                    <Routes>
                        <Route path="/" element={<WEXCards username={username} />} />
                    </Routes>
                    <Outlet />
                </Flex>
            </Box>
        </Flex>
    );
}

export default WEXHome;