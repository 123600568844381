import { VStack, List, ListItem } from "@chakra-ui/react";
import React from "react";
import { DateTime } from "luxon";
import PrettyPrintJson from "../../common/PrettyPrintJson";

const InvoiceDetails = ({ invoice }) => {
  if (!invoice) {
    return <></>;
  }
  return (
    <VStack align={"left"}>
      <List userSelect={"none"}>
        <ListItem>Status: {invoice.status}</ListItem>
        {invoice.transfer_ID && (
          <ListItem>TransferId: {invoice.transfer_ID}</ListItem>
        )}
        {invoice.transferUrl && (
          <ListItem>Transfer Url: {invoice.transferUrl}</ListItem>
        )}
        <ListItem>OrderId: {invoice.buyer_PO_ID}</ListItem>
        <ListItem>Exchange: {invoice.exchange_Name}</ListItem>
        <ListItem>Headliner: {invoice.event_Name}</ListItem>
        <ListItem>
          Event Date:{" "}
          {DateTime.fromISO(invoice.event_Date).toLocaleString(
            DateTime.DATE_SHORT
          )}{" "}
          {invoice.event_Time}
        </ListItem>
        <ListItem>Amount: ${invoice.invoice_Amount}</ListItem>
        <ListItem>Venue: {invoice.venue_Name}</ListItem>
        <ListItem>Section: {invoice.section}</ListItem>
        <ListItem>Row: {invoice.row}</ListItem>
        <ListItem>
          Seat: {invoice.seat_From} thru {invoice.seat_Thru}
        </ListItem>
        <ListItem>Internal Notes: {invoice.internal_Notes}</ListItem>
        <ListItem>External Notes: {invoice.external_Notes}</ListItem>
        <ListItem>Shipping Comments: {invoice.shipping_Comments}</ListItem>
      </List>
      {invoice.logResult && (
        <PrettyPrintJson data={JSON.parse(invoice.logResult)} />
      )}
    </VStack>
  );
};

export default InvoiceDetails;
