import React, { useEffect, useState } from "react";
import TableCore from "../../common/TableCore";
import { Box, Spinner, FormControl, FormLabel, Button, HStack } from "@chakra-ui/react";
import { getDefaultFetchOptions, getBaseUrl } from "../../utils";
import { HTTP_VERB, TABLE_MODE } from "../../enums";
import { DateTime } from "luxon";
import Select from "react-select";
import { RepeatIcon, Search2Icon } from '@chakra-ui/icons'

const columns = [
  { label: "Transfer Id", renderCell: (item) => item.transfer_Id },
  {
    label: "Invoice Id",
    renderCell: (item) => item.invoice_ID,
  },
  {
    label: "Error Code",
    renderCell: (item) => item.responses.errors[0].code.toString(),
  },
  {
    label: "Error",
    renderCell: (item) => item.responses.errors[0].message.toString(),

  },
  {
    label: "Created Date",
    renderCell: (item) => DateTime.fromISO(item.created_Date).toLocaleString(),
  }
];

const AlfredCards = () => {
  const [transferlist, setTransferList] = useState([]);
  const [invoiceList, setInvoiceList] = useState([]);
  const [transferId, setTransferId] = useState(0);
  const [invoiceId, setInvoiceId] = useState(0);
  const [search, setSearch] = useState("");
  const [errorList, setErrorList] = useState([]);
  const [isFetching, setIsFetching] = useState(false);

  useEffect(() => {
    getLatestErrorList();

  }, []);

  const getLatestErrorList = () => {
    async function getErrors() {
      setIsFetching(true);
      const uri = `${getBaseUrl()}/Alfred/GetAlfredErrorsList`;
      let request = await fetch(uri, getDefaultFetchOptions(HTTP_VERB.GET));
      if (request.ok) {
        let response = await request.json();
        
        let optionTransfers = [];
        optionTransfers = response.reduce((accumulator, v) => {
         const transfer_Id = v.transfer_Id;
         if (!accumulator.some((item) => item.value === transfer_Id)) {
           accumulator.push({ value: transfer_Id, label: transfer_Id });
         }
         return accumulator;
       }, []);

         let optionInvoices = [];
         optionInvoices = response.reduce((accumulator, v) => {
          const invoice_ID = v.invoice_ID;
          if (!accumulator.some((item) => item.value === invoice_ID)) {
            accumulator.push({ value: invoice_ID, label: invoice_ID });
          }
          return accumulator;
        }, []);  

        setErrorList(response); 
        setInvoiceList(optionInvoices);
        setTransferList(optionTransfers);
      } else {
        console.log("Error fetching errorList");
      }
      setIsFetching(false);
    }
    getErrors();
  };
 
  const searchRecords = () => {
    async function getErrorList() {
   
      setIsFetching(true);
      const uri = `${getBaseUrl()}/Alfred/GetAlfredErrorsList?transferId=${transferId}&invoiceId=${invoiceId}`;

      let request = await fetch(uri, getDefaultFetchOptions(HTTP_VERB.GET));
      if (request.ok) {
        setErrorList([]);
        let response = await request.json();
        setErrorList(response);
      } else {
        console.log("Error fetching error list ");
      }
      setIsFetching(false);

    }
    getErrorList();
  };

  const getData = () => {
    if (!search) {
      return errorList;
    }


    return errorList.filter((x) => {
      return String(x.responses.errors[0].code).includes(search);
    });

  };
  const clearSearch = () => {
    setInvoiceId(0);
    setTransferId(0); 
    getLatestErrorList();
    setIsFetching(false);
  };
  let data = () => {
    return {
      nodes: getData(),
    };
  };
  
  return (
    <Box> 
     {isFetching && <Spinner stylr size='xl' />}
      <HStack align={"flex-start"} p="2">
        <FormControl>
          <FormLabel htmlFor="userName">Transfer Id</FormLabel>
          <Select
            id="userName"
            options={[{ value: 0, label: '--Select--' }, ...transferlist]}
            onChange={(e) => {
              setTransferId(e.value);
            }}
          />
        </FormControl>
        <FormControl>
          <FormLabel htmlFor="invoiceId">Invoice Id</FormLabel>
          <Select
            id="invoiceId"
            options={[{ value: 0, label: '--Select--' }, ...invoiceList]}
            onChange={(e) => {
              setInvoiceId(e.value);
            }}
          />
        </FormControl> 
        <Button
          onClick={searchRecords}
          colorScheme={"teal"}
          variant="solid"
        >
          <Search2Icon />
        </Button>
        <Button colorScheme={"teal"} variant="solid" onClick={clearSearch}><RepeatIcon /></Button>

      </HStack>
      <TableCore
        columns={columns}
        data={data()}
        height={"100%"}
        width={"100%"}
        pageSize={15}
        showSearch={true}
        onSearchChange={(e) => setSearch(e)}
        mode={TABLE_MODE.VIEW_ONLY}
      />
    </Box>
  );
};

export default AlfredCards;
