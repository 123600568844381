import { Box, List, ListItem, Flex, Spacer } from "@chakra-ui/react";
import { DateTime } from "luxon";
import { CloseIcon } from "@chakra-ui/icons";
import React from "react";

const SearchResultDetail = ({ data, onRemove }) => {
  const handleRemoveClick = () => {
    if (onRemove) {
      onRemove(data.id);
    }
  };
  return (
    <Box maxWidth={350} p={2} borderWidth={1} borderRadius={4}>
      <Flex>
        <List>
          <ListItem>Event: {data.event}</ListItem>
          <ListItem>Venue: {data.venue}</ListItem>
          <ListItem>
            Date:{" "}
            {DateTime.fromISO(data.local_Date).toLocaleString(
              DateTime.DATETIME_MED
            )}
          </ListItem>
          <ListItem>Section: {data.section}</ListItem>
          <ListItem>Row: {data.row}</ListItem>
          <ListItem>Seat: {data.seat}</ListItem>
          <ListItem>AccountId: {data.account_Id}</ListItem>
          <ListItem>Account Type: {data.account_Type}</ListItem>
          <ListItem>EventId: {data.event_Id}</ListItem>
          <ListItem>Barcode: {data.barcode}</ListItem>
        </List>
        <Spacer />
        <CloseIcon cursor={"pointer"} onClick={() => handleRemoveClick()} />
      </Flex>
    </Box>
  );
};

export default SearchResultDetail;
