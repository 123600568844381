import React from "react";
import { Box, Flex } from "@chakra-ui/react";

const ComponentContainer = ({ formMaxWidth, children, ...rest }) => {
  return (
    <Box
      maxWidth={formMaxWidth}
      mb={"10px"}
      mr="10px"
      borderWidth={1}
      borderRadius={4}
      boxShadow="lg"
      textAlign="left"
      p={2}
      {...rest}
    >
      <Flex direction={"column"} align="left">
        {children}
      </Flex>
    </Box>
  );
};

export default ComponentContainer;
