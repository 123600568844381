import React, { useState, useEffect } from "react";
import { HTTP_VERB } from "../../enums";
import { getBaseUrl, getDefaultFetchOptions } from "../../utils";
import { FormControl, FormLabel, HStack, Text } from "@chakra-ui/react";
import GenericFormContainer from "../../common/GenericForm/GenericFormContainer";

const AXSAuthCodeView = () => {
  const userId = localStorage.getItem("GmailAXSId");
  const [resultMessage, setResultMessage] = useState("");
  const [isError, setIsError] = useState(false);
  const [subject, setSubject] = useState("");
  const [to, setTo] = useState("");
  const [content, setContent] = useState("");

  useEffect(() => {
    (async () => {
      const uri = `${getBaseUrl()}/GMail/GetEmailMessage?Id=${localStorage.getItem("GmailAXSId")}`;
      let request = await fetch(uri, getDefaultFetchOptions(HTTP_VERB.GET));
      if (request.ok) {
        let response = await request.json();
        setContent(response.body);
        setSubject(response.subject);
        setTo(response.consignerEmailId);
        setIsError(false);
      } else {
        setIsError(true);
        setResultMessage("Error fetching email");
      }
    })();
  }, [userId]);


  return ( <div>
    <GenericFormContainer
      formMaxWidth={"800px"}
      title={"AXS Auth View"}
      isResultError={isError}
      resultMessage={resultMessage}
    >

      <HStack p={"2"}> 
        <FormControl>
          <FormLabel htmlFor="expirationSelect">
            Consigner Email Id
          </FormLabel>
          <Text>{to}</Text>
        </FormControl>
        <FormControl p="2">
        <FormLabel htmlFor="subjectInput">Subject</FormLabel>
        <Text>{subject}</Text>
      </FormControl> 
      </HStack>
     
    </GenericFormContainer>
    <div dangerouslySetInnerHTML={{ __html: content }}></div>
  </div>);
};

export default AXSAuthCodeView;
