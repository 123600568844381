import React, { useState } from "react";
import { getDefaultFetchOptions, getBaseUrl } from "../../utils";
import { HTTP_VERB } from "../../enums";
import TableCore from "../../common/TableCore";
import {
  VStack,
  Link,
  OrderedList,
  ListItem,
  FormLabel,
  FormControl,
  Input,
} from "@chakra-ui/react";
import GenericFormContainer from "../../common/GenericForm/GenericFormContainer";
import { ExternalLinkIcon } from "@chakra-ui/icons";

const columns = [
  {
    label: "Event_Name",
    renderCell: (item) => item.event_Name,
  },
  {
    label: "Event_Date",
    renderCell: (item) => new Date(item.event_Date).toISOString().split("T")[0],
  },
  {
    label: "Event_ID_TicketTechnology",
    renderCell: (item) => item.event_ID_TicketTechnology,
  },
  {
    label: "Processed_Date",
    renderCell: (item) =>
      new Date(item.processed_Date).toISOString().split("T")[0],
  },
  {
    label: "Result",
    renderCell: (item) => item.result,
  },
];

const CheckPurchases = () => {
  const [orderLogs, setOrderLogs] = useState([]);
  const [search, setSearch] = useState("");
  const [fromDate, setFromDate] = useState(
    new Date().toISOString().split("T")[0]
  );
  const [toDate, setToDate] = useState(new Date().toISOString().split("T")[0]);
  const [isFetching, setIsFetching] = useState(false);

  const GetOrderLogs = async () => {
    if (search !== "") {
      setIsFetching(true);
      const body = {
        accountName: search,
        fromDate: fromDate,
        toDate: toDate,
      };
      const uri = `${getBaseUrl()}/orders/logsByAccountName`;
      let result = await fetch(uri, {
        ...getDefaultFetchOptions(HTTP_VERB.POST),
        body: JSON.stringify(body),
      });
      if (result.ok) {
        let payload = await result.json();

        setOrderLogs(payload);
      }
      setIsFetching(false);
    }
  };

  let data = () => {
    return {
      nodes: orderLogs,
    };
  };

  return (
    <>
      <GenericFormContainer
        title="Check Purchases in 1Ticket"
        subTitle={"Follow these steps to Check Purchases in 1Ticket"}
        isResultError={false}
        isSubmitDisabled={true}
        onSubmitClick={null}
        isFetching={isFetching}
        resultMessage={""}
      >
        <VStack align={"flex-start"} p="2">
          <OrderedList p="2">
            <ListItem>
              <Link href="https://alt3.1ticket.com/stagefront/">
                Link to 1Ticket <ExternalLinkIcon mx="2px" />
              </Link>
            </ListItem>
            <ListItem>
              Enter the consignor name and click Search Clients
            </ListItem>
            <ListItem>Click Login</ListItem>
            <ListItem>
              If Consignor reports issues in orders not flowing thru the portal.
              Please Enter Purchase order info or event name to search orders
            </ListItem>
            <ListItem>
              Select From and To dates to filter logs results (max logs to
              display is 50)
            </ListItem>
            <ListItem>
              Enter Consignor's email address on the search field and press
              Enter
            </ListItem>
          </OrderedList>
          <FormControl>
            <FormLabel htmlFor="fromDate">From Date</FormLabel>
            <Input
              id="fromDate"
              type="date"
              variant="filled"
              value={fromDate}
              maxWidth={300}
              onChange={(e) => setFromDate(e.target.value)}
              placeholder="Select From Date"
            />
          </FormControl>
          <FormControl>
            <FormLabel htmlFor="toDate">To Date</FormLabel>
            <Input
              id="toDate"
              type="date"
              variant="filled"
              value={toDate}
              maxWidth={300}
              onChange={(e) => setToDate(e.target.value)}
              placeholder="Select To Date"
            />
          </FormControl>
        </VStack>
      </GenericFormContainer>
      <TableCore
        columns={columns}
        data={data()}
        height={"100%"}
        width={"100%"}
        onSearchChange={(e) => setSearch(e)}
        onSearchReturn={() => GetOrderLogs()}
        pageSize={14}
        showSearch={true}
      />
    </>
  );
};

export default CheckPurchases;
