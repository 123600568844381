import { Box, Flex, Text } from "@chakra-ui/react";

import React from "react";
import SidebarMenuItem from "../Sidebar/SidebarMenuItem";

const Sidebar = ({ onClose, linkItems, title }) => {
  const renderLinks = () => {
    if (!linkItems) {
      return <></>;
    }
    return linkItems.map((v, i) => {
      return (
        <SidebarMenuItem icon={v.icon} path={v.path} label={v.label} key={i} />
      );
    });
  };
  return (
    <Box
      transition="1s ease"
      bg="#fffffe"
      borderRight="1px"
      borderRightColor="gray.200"
      w={{ base: "full", md: 60 }}
      h="93vh"
    >
      <Flex
        h="20"
        alignItems="left"
        p={5}
        justifyContent="space-between"
        direction={"column"}
      >
        <Text fontSize="xl" fontWeight="bold" mb={10}>
          {title}
        </Text>
        {renderLinks()}
      </Flex>
    </Box>
  );
};

export default Sidebar;
