import { Heading, VStack } from "@chakra-ui/react";
import React from "react";
import PaymentPending from "./PaymentPending";
import Payments from "./Payments";

const PayConsignors = () => {
  return (
    <VStack align={"left"}>
      <Heading>Pay Consignors</Heading>
      <PaymentPending />
      <Payments />
    </VStack>
  );
};

export default PayConsignors;
