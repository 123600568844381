import {
  Button,
  FormControl,
  FormLabel,
  HStack,
  Input,
} from "@chakra-ui/react";
import React, { useState } from "react";
import ComponentContainer from "../../../common/ComponentContainer";
import { getBaseUrl, getDefaultFetchOptions } from "../../../utils";
import { HTTP_VERB } from "../../../enums";
import EventSelectById from "../../../common/EventSelectById";
import { DateTime } from "luxon";

const OneTicketEventMap = ({
  eventDate,
  eventHeadlinerId,
  externalName,
  mapId,
  onCancelClick,
  onDelete,
  onEventHeadlinerChange,
  onExternalNameChange,
  onSave,
}) => {
  const getStartDate = () => {
    if (eventDate) {
      return eventDate;
    }
    return DateTime.now().minus({ days: 5 });
  };
  const getEndDate = () => {
    if (eventDate) {
      return eventDate;
    }
    return DateTime.now().plus({ days: 360 });
  };
  const [startDate] = useState(getStartDate());
  const [endDate] = useState(getEndDate);
  const [submitting, setSubmitting] = useState(false);
  const shouldDisableSubmit = () => {};
  const handleSubmit = async () => {
    setSubmitting(true);
    if (mapId) {
      await update();
    } else {
      await create();
    }
    setSubmitting(false);
  };
  const create = async () => {
    const uri = `${getBaseUrl()}/OneTicketEventMap`;
    const body = {
      externalEventName: externalName,
      eventHeadlinerId: eventHeadlinerId,
    };
    const result = await fetch(uri, {
      ...getDefaultFetchOptions(HTTP_VERB.POST),
      body: JSON.stringify(body),
    });
    const response = await result.json();
    if (result.ok) {
      callOnSave(response);
    } else {
      console.log(response);
    }
  };
  const update = async () => {
    const uri = `${getBaseUrl()}/OneTicketEventMap?id=${mapId}`;
    const body = {
      externalEventName: externalName,
      eventHeadlinerId: eventHeadlinerId,
    };
    const result = await fetch(uri, {
      ...getDefaultFetchOptions(HTTP_VERB.PATCH),
      body: JSON.stringify(body),
    });
    const response = await result.json();
    if (result.ok) {
      callOnSave(response);
    } else {
      console.log(response);
    }
  };
  const callOnSave = (data) => {
    if (onSave) {
      onSave(data);
    }
  };
  const handleCancel = () => {
    onExternalNameChange();
    onEventHeadlinerChange(undefined);
    if (onCancelClick) {
      onCancelClick();
    }
  };
  const handleDelete = async () => {
    const uri = `${getBaseUrl()}/OneTicketEventMap?id=${mapId}`;
    const result = await fetch(uri, getDefaultFetchOptions(HTTP_VERB.DELETE));
    const response = await result.json();
    if (result.ok) {
      if (onDelete) {
        onDelete(mapId);
      }
    } else {
      console.log(response);
    }
  };
  return (
    <ComponentContainer mt={2}>
      <HStack>
        <FormControl>
          <FormLabel>External Name</FormLabel>
          <Input
            placeholder="Enter Event name"
            type="text"
            variant="filled"
            value={externalName}
            onChange={(e) => onExternalNameChange(e.target.value)}
          />
        </FormControl>
        <FormControl>
          <EventSelectById
            onEventChange={(e) => onEventHeadlinerChange(e)}
            startDate={startDate}
            endDate={endDate}
            eventId={eventHeadlinerId}
          />
        </FormControl>
      </HStack>
      <HStack p={2}>
        <Button
          colorScheme={"teal"}
          variant="solid"
          disabled={shouldDisableSubmit()}
          isLoading={submitting}
          onClick={() => handleSubmit()}
        >
          Save
        </Button>
        {mapId && (
          <Button
            colorScheme={"red"}
            variant="solid"
            onClick={() => handleDelete()}
          >
            Delete
          </Button>
        )}
        <Button
          colorScheme={"blue"}
          variant="solid"
          onClick={() => handleCancel()}
        >
          Cancel
        </Button>
      </HStack>
    </ComponentContainer>
  );
};

export default OneTicketEventMap;
