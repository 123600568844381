import React from "react";
import { Button } from "@chakra-ui/react";

const VMActionButton = ({ vm, onClickHandler }) => {
  let buttonColor, buttonText, isDisabled;
  switch (vm.status) {
    case "stopped":
      buttonColor = "green";
      buttonText = "Start";
      isDisabled = false;
      break;
    case "running":
      buttonColor = "red";
      buttonText = "Stop";
      isDisabled = false;
      break;
    case "updating":
      buttonColor = "blue";
      buttonText = "Updating";
      isDisabled = true;
      break;
    case "error":
      buttonColor = "teal";
      buttonText = "Error";
      isDisabled = true;
      break;
    default:
      buttonColor = "gray";
      buttonText = "Unknown";
      isDisabled = true;
  }
  return (
    <Button
      colorScheme={buttonColor}
      onClick={(event) => onClickHandler(vm, event)}
      isDisabled={isDisabled}
    >
      {buttonText}
    </Button>
  );
};

export default VMActionButton;
