import {
  VStack,
  FormLabel,
  FormControl,
  Input,
  Link,
  OrderedList,
  ListItem,
} from "@chakra-ui/react";
import { ExternalLinkIcon } from "@chakra-ui/icons";
import React, { useState, useEffect } from "react";
import GenericFormContainer from "../../common/GenericForm/GenericFormContainer";
import { getBaseUrl, getDefaultFetchOptions } from "../../utils";
import { HTTP_VERB } from "../../enums";
import CompanySelect from "../../common/CompanySelect";

const Vivid = () => {
  const [isFetching, setIsFetching] = useState(false);
  const [resultMessage, setResultMessage] = useState("");
  const [isError, setIsError] = useState(false);
  const [companyToken, setCompanyToken] = useState("");
  const [companyName, setCompanyName] = useState("");
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [vividToken, setVividToken] = useState("");

  const buildUrl = (companyName) => {
    return `${getBaseUrl()}/companies/${companyName}/vivid`;
  };
  useEffect(() => {
    if (companyName.length === 0) {
      return;
    }
    async function getVivid() {
      setIsFetching(true);
      try {
        const uri = buildUrl(companyName);
        let response = await fetch(uri, getDefaultFetchOptions(HTTP_VERB.GET));
        if (response.ok) {
          if (response.statusText === "No Content") {
            setCompanyToken("");
            setUsername("");
            setPassword("");
            setVividToken("");
            setIsFetching(false);
            return;
          }
          let result = await response.json();
          if (result) {
            setUsername(result.username);
            setVividToken(result.vivid_Token);
            setPassword(result.password);
            setCompanyToken(result.stageFront_Token);
          }
        }
      } catch (error) {
        console.log(error);
      }
      setIsFetching(false);
    }
    getVivid();
  }, [companyName]);
  const submit = async () => {
    setIsFetching(true);
    try {
      let body = {
        vividToken: vividToken,
        username: username,
        password: password,
      };
      const uri = buildUrl(companyName);
      let response = await fetch(uri, {
        ...getDefaultFetchOptions(HTTP_VERB.POST),
        body: JSON.stringify(body),
      });
      if (response.ok) {
        let result = await response.text();
        setCompanyToken(result);
        setResultMessage(`Success`);
        setUsername("");
        setPassword("");
        setVividToken("");
        setCompanyName("");
      } else {
        setIsError(true);
        setResultMessage(
          `Error setting up Vivid 1.5 for company ${companyName}`
        );
      }
    } catch (error) {
      console.log(error);
    }
    setIsFetching(false);
  };
  const allowSubmit = () => {
    return (
      companyName.length > 0 &&
      vividToken.length > 0 &&
      username.length > 0 &&
      password.length > 0
    );
  };
  const renderVividInfo = () => {
    if (companyToken) {
      return (
        <OrderedList p="2">
          <ListItem>
            Copy this URL: https://vivid.stagefront.com/api/Order/
            {companyToken}/InstantOrder
          </ListItem>
          <ListItem>Click the link above to navigate to Vivid</ListItem>
          <ListItem>
            On the "Developers" page, locate the Notification URL textbox
          </ListItem>
          <ListItem>Paste into Notification URL textbox</ListItem>
          <ListItem>Click "Update URL"</ListItem>
          <ListItem>
            Contact StageFront Analytics team for rate adjustments
          </ListItem>
        </OrderedList>
      );
    }
  };
  return (
    <GenericFormContainer
      title="Setup Vivid"
      subTitle={"Configure Vivid 1.5 for a Company"}
      isResultError={isError}
      isSubmitDisabled={!allowSubmit()}
      onSubmitClick={() => submit()}
      isFetching={isFetching}
      resultMessage={resultMessage}
    >
      <VStack align={"flex-start"} p="2">
        <Link href="https://brokers.vividseats.com/Login.action?forceLogin=true&destination=%252FAPI.action">
          Link to Vivid 1.5 <ExternalLinkIcon mx="2px" />
        </Link>
        <CompanySelect
          companyName={companyName}
          onCompanyNameChange={(name) => setCompanyName(name)}
        />
        <FormControl>
          <FormLabel htmlFor="vividUsernameInput">Vivid Username</FormLabel>
          <Input
            id="vividUsernameInput"
            type="text"
            variant="filled"
            maxLength={128}
            value={username}
            onChange={(e) => setUsername(e.target.value)}
            placeholder="Enter User Name"
          />
        </FormControl>
        <FormControl>
          <FormLabel htmlFor="vividPasswordInput">Vivid Password</FormLabel>
          <Input
            id="vividPasswordInput"
            type="password"
            variant="filled"
            maxLength={50}
            value={password}
            onChange={(e) => setPassword(e.target.value)}
            placeholder="Enter Password"
          />
        </FormControl>
        <FormControl>
          <FormLabel htmlFor="vividTokenInput">Vivid SF Portal Token</FormLabel>
          <Input
            id="vividTokenInput"
            maxLength={36}
            type="text"
            variant="filled"
            value={vividToken}
            onChange={(e) => setVividToken(e.target.value)}
            placeholder="Enter SF Portal token from Vivid 1.5"
          />
        </FormControl>
        {renderVividInfo()}
      </VStack>
    </GenericFormContainer>
  );
};

export default Vivid;
