import React, { useState, useEffect } from "react";
import { FormControl, FormLabel, VStack, Switch } from "@chakra-ui/react";
import GenericFormContainer from "../../common/GenericForm/GenericFormContainer";
import { HTTP_VERB } from "../../enums";
import { getBaseUrl, getDefaultFetchOptions } from "../../utils";
import Select from "react-select";

const CloneUser = () => {
  const [isFetching, setIsFetching] = useState(false);
  const [resultMessage, setResultMessage] = useState("");
  const [isError, setIsError] = useState(false);
  const [users, setUsers] = useState([]);
  const [showDisabled, setShowDisabled] = useState(false);
  const [cloneTarget, setCloneTarget] = useState("");
  const [cloneSource, setCloneSource] = useState("");
  useEffect(() => {
    async function fetchUsers() {
      const uri = `${getBaseUrl()}/users?isDisabled=${showDisabled}`;
      let result = await fetch(uri, getDefaultFetchOptions(HTTP_VERB.GET));
      if (result.ok) {
        let userResults = await result.json();
        let options = userResults.map((v) => {
          return { value: v.userName, label: v.userName };
        });

        setUsers(options);
      }
    }
    fetchUsers();
  }, [showDisabled]);
  const allowSubmit = () => {
    return true;
  };
  const submit = async () => {
    setIsFetching(true);
    try {
      const uri = `${getBaseUrl()}/users/clone?source=${cloneSource}&target=${cloneTarget}`;
      let result = await fetch(uri, getDefaultFetchOptions(HTTP_VERB.PUT));
      if (result.ok) {
        setIsError(false);
        setResultMessage(`Cloned user ${cloneSource} to ${cloneTarget}`);
      } else {
        setIsError(true);
        let responseMessage = await result.text();
        setResultMessage(
          `Unable to clone user ${cloneSource} to ${cloneTarget}.  ${responseMessage}`
        );
      }
    } catch (error) {
      console.log(error);
    }
    setIsFetching(false);
  };
  return (
    <GenericFormContainer
      formMaxWidth={"500px"}
      title={"Clone User"}
      subTitle="Copy roles and access from one user to another"
      isResultError={isError}
      isSubmitDisabled={!allowSubmit()}
      onSubmitClick={() => submit()}
      isFetching={isFetching}
      resultMessage={resultMessage}
    >
      <VStack>
        <FormControl display="flex" alignItems="center" mt="4">
          <FormLabel htmlFor="show-disabled" mb="0">
            Show Disabled?
          </FormLabel>
          <Switch
            id="show-disabled"
            isChecked={showDisabled}
            onChange={(e) => setShowDisabled(e.target.checked)}
          />
        </FormControl>
        <FormControl>
          <FormLabel htmlFor="cloneSource">User to Clone FROM</FormLabel>
          <Select
            id="cloneSource"
            options={users}
            onChange={(e) => {
              setCloneSource(e.value);
            }}
          />
        </FormControl>
        <FormControl>
          <FormLabel htmlFor="cloneSource">User to Clone TO</FormLabel>
          <Select
            id="cloneTarget"
            options={users}
            onChange={(e) => {
              setCloneTarget(e.value);
            }}
          />
        </FormControl>
      </VStack>
    </GenericFormContainer>
  );
};

export default CloneUser;
