import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import CompanyCore from "./CompanyCore";
import { HTTP_VERB } from "../../enums";
import { getBaseUrl, getDefaultFetchOptions } from "../../utils";
import { Spinner } from "@chakra-ui/react";

const EditCompany = () => {
  let params = useParams();
  let company = params.company;
  const [isFetching, setIsFetching] = useState(false);
  const [resultMessage, setResultMessage] = useState("");
  const [isError, setIsError] = useState(false);
  const [companyData, setCompanyData] = useState();
  useEffect(() => {
    async function getCompanyInfo() {
      setIsFetching(true);
      const uri = `${getBaseUrl()}/companies/id/${company}`;
      try {
        let result = await fetch(uri, getDefaultFetchOptions(HTTP_VERB.GET));
        if (result.ok) {
          let response = await result.json();
          if (!response.oneTicketEmails.length) {
            response.oneTicketEmails.push("");
          }
          if (!response.purchasingEmails.length) {
            response.purchasingEmails.push("");
          }
          setCompanyData(response);
          setIsError(false);
        } else {
          setResultMessage(
            `Error fetching info for Company: ${company}.  Status Code: ${result.status}`
          );
          setIsError(true);
        }
      } catch (error) {
        console.log(error);
      }
      setIsFetching(false);
    }
    getCompanyInfo();
  }, [company]);
  const handleSubmit = async (data) => {
    setIsFetching(true);
    const uri = `${getBaseUrl()}/companies/${company}`;
    let requestBody = {
      brokerageId: data.brokerageId,
      emails: data.emails,
      oneTicketUserName: data.oneTicketUserName,
      oneTicketPassword: data.oneTicketPassword,
      oneTicketFolderName: data.oneTicketFolderName,
      oneTicketUserId: data.oneTicketUserId,
      oneTicketEmails: data.oneTicketEmails,
      consignorEmail: data.consignorEmail,
      oneTicketApiKey: data.oneTicketApiKey,
      isAcceptedTermsandConditions: data.isAcceptedTermsandConditions,
      purchasingEmails: data.purchasingEmails,
    };
    try {
      let result = await fetch(uri, {
        ...getDefaultFetchOptions(HTTP_VERB.PATCH),
        body: JSON.stringify(requestBody),
      });
      if (result.ok) {
        setIsError(false);
        setResultMessage(`Successfully updated Company: ${company}.`);
      } else {
        setResultMessage(
          `Error encountered updating Company: ${company}.  Status Code: ${result.status}`
        );
        setIsError(true);
      }
    } catch (error) {
      console.log(error);
    }
    setIsFetching(false);
  };

  return (
    <>
      {!companyData && <Spinner />}
      {companyData && (
        <CompanyCore
          brokerageId={companyData.brokerage_ID}
          companyId={companyData.company_ID}
          emails={companyData.notificationEmailList}
          isEdit={true}
          isError={isError}
          isFetching={isFetching}
          title={"Edit Company"}
          subTitle={"Modify Company Details"}
          oneTicketEmails={companyData.oneTicketEmails}
          oneTicketFolderName={companyData.dropbox_Folder}
          oneTicketPassword={companyData.autoPO_API_Password}
          oneTicketUserId={companyData.autoPO_UserID}
          oneTicketUsername={companyData.autoPO_API_User}
          consignorEmail={companyData.consignorEmail}
          oneTicketApiKey={companyData.oneTicket_Transfer_Key}
          onSubmit={(data) => handleSubmit(data)}
          name={companyData.company_Name}
          resultMessage={resultMessage}
          isAcceptedTermsandConditions={
            companyData.isAcceptedTermsandConditions
          }
          purchasingEmails={companyData.purchasingEmails}
        />
      )}
    </>
  );
};

export default EditCompany;
