import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { getDefaultFetchOptions, getBaseUrl } from "../../utils";
import { HTTP_VERB, TABLE_MODE } from "../../enums";
import TableCore from "../../common/TableCore";

const createCompany = (company) => {
  return {
    id: company.company_ID,
    company_Name: company.company_Name,
    status: company.status,
    notification_Email: company.notification_Email,
  };
};

const columns = [
  { label: "Name", renderCell: (item) => item.company_Name },
  {
    label: "Status",
    renderCell: (item) => item.status,
  },
  {
    label: "Notification Email",
    renderCell: (item) => item.notification_Email,
  },
];

const CompanyList = () => {
  const navigate = useNavigate();
  const [companies, setCompanies] = useState([]);
  const [search, setSearch] = useState("");
  useEffect(() => {
    async function GetCompanies() {
      const uri = `${getBaseUrl()}/companies`;
      let result = await fetch(uri, getDefaultFetchOptions(HTTP_VERB.GET));
      if (result.ok) {
        let temp = [];
        let payload = await result.json();
        for (const i of payload) {
          temp.push(createCompany(i));
        }
        setCompanies(temp);
      }
    }
    GetCompanies();
  }, []);
  const getData = () => {
    if (!search) {
      return companies;
    }
    return companies.filter((x) => {
      return x.company_Name.toLowerCase().includes(search.toLowerCase());
    });
  };
  let data = () => {
    return {
      nodes: getData(),
    };
  };
  const handleRowClick = (action, state) => {
    navigate(`/company/${action.payload.id}`);
  };
  return (
    <TableCore
      columns={columns}
      data={data()}
      height={"800px"}
      width={"800px"}
      onRowSelect={handleRowClick}
      onSearchChange={(e) => setSearch(e)}
      pageSize={14}
      showSearch={true}
      mode={TABLE_MODE.CLICKABLE}
    />
  );
};

export default CompanyList;
