import React, { useState, useEffect } from "react";
import { FormLabel, FormControl } from "@chakra-ui/react";
import WindowedSelect from "react-windowed-select";

const EventSelectCore = ({
  selectedValue,
  onValueChange,
  events,
  useIdAsValue,
  fetching,
}) => {
  const [options, setOptions] = useState([]);
  useEffect(() => {
    if (events.length === 0) {
      return;
    }

    let options = [];
    for (const event of events) {
      if (useIdAsValue) {
        options.push({
          label: event.event_Headliner,
          value: event.event_Headliner_ID,
        });
      } else {
        options.push({ label: event, value: event });
      }
    }
    setOptions(options);
  }, [events, useIdAsValue]);
  const handleChange = (e) => {
    if (onValueChange) {
      onValueChange(e?.value);
    }
  };
  const getValue = () => {
    if (!options) {
      return;
    }
    let event = options.find((v) => {
      return v.value === selectedValue;
    });
    return event;
  };
  return (
    <FormControl>
      <FormLabel htmlFor="eventSelect">Event Headliner</FormLabel>
      <WindowedSelect
        options={options}
        isClearable={true}
        isLoading={fetching}
        windowThreshold={8}
        onChange={(e) => handleChange(e)}
        value={getValue()}
        styles={{
          menuPortal: (provided) => ({ ...provided, zIndex: 9999 }),
          menu: (provided) => ({ ...provided, zIndex: 9999 }),
        }}
      />
    </FormControl>
  );
};

export default EventSelectCore;
