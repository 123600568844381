import React, { useState, useEffect } from "react";
import { getBaseUrl, getDefaultFetchOptions } from "../utils";
import { HTTP_VERB } from "../enums";
import EventSelectCore from "./EventSelectCore";

const EventSelectById = ({
  eventId,
  onEventChange,
  startDate,
  endDate,
  setParentFetching,
}) => {
  const [events, setEvents] = useState([]);
  const [fetching, setFetching] = useState(false);
  useEffect(() => {
    async function fetchData() {
      setFetching(true);
      if (setParentFetching) {
        setParentFetching(true);
      }

      let body = {
        startDate: startDate,
        endDate: endDate,
      };
      const uri = `${getBaseUrl()}/events/headliners`;
      let result = await fetch(uri, {
        ...getDefaultFetchOptions(HTTP_VERB.POST),
        body: JSON.stringify(body),
      });
      let data = await result.json();
      setEvents(data);
      setFetching(false);
      if (setParentFetching) {
        setParentFetching(false);
      }
    }
    fetchData();
  }, [startDate, endDate, setParentFetching]);
  return (
    <EventSelectCore
      selectedValue={eventId}
      onValueChange={onEventChange}
      events={events}
      fetching={fetching}
      useIdAsValue={true}
    />
  );
};

export default EventSelectById;
