import {
  Box,
  Button,
  FormControl,
  FormLabel,
  HStack,
  useToast,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  useDisclosure,
  Spinner,
} from "@chakra-ui/react";
import React, { useState, useCallback } from "react";
import { DateTime } from "luxon";
import ButtonDatePicker from "../../../common/ButtonDatePicker";
import ComponentContainer from "../../../common/ComponentContainer";
import FormHeader from "../../../common/FormHeader";
import { HTTP_VERB, TABLE_MODE } from "../../../enums";
import { getBaseUrl, getDefaultFetchOptions } from "../../../utils";
import PrettyPrintJson from "../../../common/PrettyPrintJson";
import CompanySelect from "../../../common/CompanySelect";
import EventSelectById from "../../../common/EventSelectById";
import InvoicesTable from "./InvoicesTable";
import InvoiceDetails from "../InvoiceDetails";

const OneTicketTransferSearch = () => {
  const getStartDate = () => {
    return DateTime.now().toJSDate();
  };
  const getEndDate = () => {
    return DateTime.now().toJSDate();
  };
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [startDate, setStartDate] = useState(getStartDate());
  const [endDate, setEndDate] = useState(getEndDate());
  const [company_Name, setCompanyName] = useState("");
  const [event_Headliner_ID, setEventHeadlinerID] = useState(null);
  const [invoiceIds, setInvoiceIds] = useState([]);
  const [isFetching, setIsFetching] = useState(false);
  const [results, setResults] = useState("");
  const [isSearching, setSearching] = useState(false);
  const [invoices, setInvoices] = useState();
  const [selectedInvoiceId, setSelectedInvoiceId] = useState();
  const [invoiceDetails, setInvoiceDetails] = useState();

  const toast = useToast();
  const renderToast = useCallback((toast, isError, title, desc) => {
    let status = "success";
    if (isError) {
      status = "error";
    }
    toast({
      title: title,
      description: desc,
      status: status,
      duration: 3000,
      isClosable: true,
    });
  }, []);
  const isSubmitDisabled = () => {
    return invoiceIds.length === 0;
  };
  const isSearchDisabled = () => {
    return !(
      (company_Name && company_Name !== "") ||
      (event_Headliner_ID && event_Headliner_ID !== null)
    );
  };
  const onSearchClick = async () => {
    setSearching(true);
    const uri = `${getBaseUrl()}/invoice/transfersByCompanyEvent`;
    let body = {
      startDate: startDate,
      endDate: endDate,
      company_Name: company_Name,
      event_Headliner_ID: event_Headliner_ID,
    };
    let request = await fetch(uri, {
      ...getDefaultFetchOptions(HTTP_VERB.POST),
      body: JSON.stringify(body),
    });
    const response = await request.json();
    if (request.ok) {
      setInvoices(response);
    } else {
      renderToast(
        toast,
        true,
        "Error Fetching Pending Invoices",
        response?.detail
      );
    }
    setSearching(false);
  };
  const fetchInvoiceDetails = async (invoiceId) => {
    if (!invoiceId) {
      return;
    }
    setIsFetching(true);
    const uri = `${getBaseUrl()}/invoice/${invoiceId}`;
    const request = await fetch(uri, getDefaultFetchOptions(HTTP_VERB.GET));
    const response = await request.json();
    if (request.ok) {
      setInvoiceDetails(response);
    } else {
      renderToast(
        toast,
        true,
        "Error Fetching Invoice Details",
        response?.detail
      );
    }
    setIsFetching(false);
  };
  const handleRowClick = (payload) => {
    const invoiceId = payload.id;
    if (!invoiceId) {
      setSelectedInvoiceId();
      return;
    }
    setSelectedInvoiceId(() => {
      fetchInvoiceDetails(invoiceId);
      return invoiceId;
    });

    onOpen();
  };
  const onRowSelect = (action, state) => {
    if (action.type.indexOf("EXCLUSIVELY") > -1) {
      handleRowClick(action.payload);
    }
    if (action.type.indexOf("RECURSIVELY") > -1) {
      setInvoiceIds(state.ids);
    }
  };
  const handleClose = () => {
    setSelectedInvoiceId();
    onClose();
  };
  const onSubmitClick = async () => {
    setIsFetching(true);
    const uri = `${getBaseUrl()}/invoice/bulktransfer`;
    let request = await fetch(uri, {
      ...getDefaultFetchOptions(HTTP_VERB.POST),
      body: JSON.stringify([...invoiceIds]),
    });
    if (request.ok) {
      let response = await request.json();
      renderToast(toast, false, "Invoices Submitted", "See results below");
      setResults(response);
    } else {
      let err = await request.json();
      renderToast(toast, true, "Error Submitting Invoices", err?.detail);
    }
    setIsFetching(false);
  };
  const onClearClick = () => {
    setResults("");
    setInvoiceIds("");
  };
  const handleEndDateChange = (val) => {
    setIsFetching(true);
    setSearching(true);
    if (val < startDate) {
      toast({
        title: "Form Validation error.",
        description: "End Date cannot be before Start Date",
        status: "error",
        duration: 10000,
        isClosable: true,
      });
      return;
    }
    setEndDate(val);
    setIsFetching(false);
    setSearching(false);
  };
  const handleStartDateChange = (val) => {
    setIsFetching(true);
    if (val > endDate) {
      toast({
        title: "Form Validation error.",
        description: "Start Date cannot be after End Date",
        status: "error",
        duration: 10000,
        isClosable: true,
      });
      return;
    }
    setStartDate(val);
    setIsFetching(false);
  };
  return (
    <>
      <ComponentContainer>
        <FormHeader
          title={"1Ticket Invoice Transfer Search"}
          subTitle={
            "Search for invoices using the form, select the invoices you wish to transfer and click Submit."
          }
        />
        <HStack p={2} spacing={1}>
          <CompanySelect
            onCompanyNameChange={(e) => setCompanyName(e)}
            shouldContainTransferKey={true}
          />
        </HStack>
        <HStack p={2} spacing={3} shouldWrapChildren={true}>
          <FormControl>
            <FormLabel>Start Date</FormLabel>
            <ButtonDatePicker
              date={startDate}
              onDateChange={(d) => handleStartDateChange(d)}
            />
          </FormControl>
          <FormControl>
            <FormLabel>End Date</FormLabel>
            <ButtonDatePicker
              date={endDate}
              onDateChange={(d) => handleEndDateChange(d)}
            />
          </FormControl>
          <FormHeader
            subTitle={
              "Changing the date range updates the Event Headliner drop-down."
            }
          />
        </HStack>
        <HStack p={2} spacing={1}>
          <EventSelectById
            onEventChange={(e) => setEventHeadlinerID(e)}
            startDate={startDate}
            endDate={endDate}
            setParentFetching={setIsFetching}
          />
        </HStack>
        <HStack p={2} spacing={1} shouldWrapChildren={true}>
          <FormControl>
            <Button
              colorScheme={"teal"}
              isLoading={isSearching || isFetching}
              onClick={() => onSearchClick()}
              isDisabled={isSearchDisabled()}
            >
              Search
            </Button>
          </FormControl>
          <FormHeader
            subTitle={"Must select a Company, Event Headliner or both."}
          />
        </HStack>
      </ComponentContainer>
      <ComponentContainer>
        <HStack p={2} spacing={1} isInline={true}>
          <Button
            isDisabled={isSubmitDisabled()}
            colorScheme={"teal"}
            isLoading={isFetching}
            onClick={() => onSubmitClick()}
          >
            Submit Transfer
          </Button>
        </HStack>
        {results && (
          <Button colorScheme={"teal"} onClick={() => onClearClick()}>
            Clear Results
          </Button>
        )}
        {results && (
          <Box>
            <PrettyPrintJson data={results} />
          </Box>
        )}
        {invoices && (
          <InvoicesTable
            invoices={invoices}
            onRowSelect={onRowSelect}
            mode={TABLE_MODE.SELECTABLE}
          />
        )}
      </ComponentContainer>
      <Modal
        isOpen={isOpen}
        onClose={onClose}
        scrollBehavior={"inside"}
        size={"4xl"}
      >
        <ModalOverlay />
        <ModalContent>
          {isFetching && <Spinner size="xl" />}
          {!isFetching}
          {
            <>
              <ModalHeader>Invoice {selectedInvoiceId}</ModalHeader>
              <ModalBody>
                <InvoiceDetails invoice={invoiceDetails} />
              </ModalBody>
            </>
          }
          <ModalCloseButton />
          <ModalFooter>
            <Button colorScheme="red" mr={3} onClick={handleClose}>
              Close
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </>
  );
};

export default OneTicketTransferSearch;
