import { FormControl, FormLabel, Input, VStack } from "@chakra-ui/react";
import React, { useState } from "react";
import GenericFormContainer from "../../common/GenericForm/GenericFormContainer";
import { HTTP_VERB } from "../../enums";
import { getBaseUrl, getDefaultFetchOptions } from "../../utils";

const Merge = () => {
  const [oldId, setOldId] = useState("");
  const [newId, setNewId] = useState("");
  const [isFetching, setIsFetching] = useState(false);
  const [resultMessage, setResultMessage] = useState("");
  const [isError, setIsError] = useState(false);
  const submit = async () => {
    const uri = `${getBaseUrl()}/events/merge`;
    setIsFetching(true);
    try {
      const body = {
        oldEventID: oldId,
        newEventID: newId,
      };
      let result = await fetch(uri, {
        ...getDefaultFetchOptions(HTTP_VERB.POST),
        body: JSON.stringify(body),
      });
      if (result.ok) {
        setIsError(false);
        setResultMessage(`Events ${oldId} and ${newId} merged succesfully.`);
        setNewId("");
        setOldId("");
      } else {
        if (result.status === 403) {
          setIsError(true);
          setResultMessage(
            `Error encountered merging events ${oldId} and ${newId}.  Status Code: ${result.status}`
          );
        } else {
          let errResponse = await result.json();
          setIsError(true);
          setResultMessage(
            `Error encountered merging events ${oldId} and ${newId}.  ${errResponse?.detail}`
          );
        }
      }
    } catch (error) {
      console.log(error);
    }
    setIsFetching(false);
  };
  const allowSubmit = () => {
    return oldId.length > 0 && newId.length > 0;
  };
  return (
    <GenericFormContainer
      formMaxWidth={"500px"}
      title={"Merge Events"}
      subTitle="Merge duplicate events and move all inventory to new event"
      isResultError={isError}
      isSubmitDisabled={!allowSubmit()}
      onSubmitClick={() => submit()}
      isFetching={isFetching}
      resultMessage={resultMessage}
    >
      <VStack align={"flex-start"} p="2">
        <FormControl>
          <FormLabel htmlFor="oldId">Old Event ID</FormLabel>
          <Input
            id="oldId"
            type="number"
            value={oldId}
            onChange={(e) => setOldId(e.target.value)}
            placeholder="Enter Old Event ID"
            variant={"filled"}
          />
        </FormControl>
        <FormControl>
          <FormLabel htmlFor="newId">New Event ID</FormLabel>
          <Input
            id="newId"
            type="number"
            value={newId}
            onChange={(e) => setNewId(e.target.value)}
            placeholder="Enter New Event ID"
            variant={"filled"}
          />
        </FormControl>
      </VStack>
    </GenericFormContainer>
  );
};

export default Merge;
