import {
  FormControl,
  FormLabel,
  FormErrorMessage,
  Input,
  VStack,
} from "@chakra-ui/react";
import React, { useState, useEffect } from "react";
import GenericFormContainer from "../../common/GenericForm/GenericFormContainer";
import { HTTP_VERB } from "../../enums";
import {
  getBaseUrl,
  getDefaultFetchOptions,
  emailValidationRegex,
} from "../../utils";
import CompanySelectCore from "../../common/CompanySelectCore";

const AddMarvinCompany = () => {
  const [company, setCompany] = useState("");
  const [email, setEmail] = useState("");
  const [webhookUrl, setWebhookUrl] = useState("");
  const [companies, setCompanies] = useState([]);
  const [isFetching, setIsFetching] = useState(false);
  const [resultMessage, setResultMessage] = useState("");
  const [isError, setIsError] = useState(false);
  const [isEmailValid, setIsEmailValid] = useState(true);

  async function getCompanies() {
    setIsFetching(true);
    const uri = `${getBaseUrl()}/marvin/companies/unregisteredcompanies`;
    let result = await fetch(uri, getDefaultFetchOptions(HTTP_VERB.GET));
    if (result.ok) {
      let response = await result.json();
      setCompanies(response);
    }
    setIsFetching(false);
  }

  useEffect(() => {
    getCompanies();
  }, []);
  const allowSubmit = () => {
    if (company.length > 0 && (isEmailValid || email.length === 0)) {
      return true;
    }
    return false;
  };
  const submitCompany = async () => {
    let body = {
      companyName: company,
      email: email,
      webhookUrl: webhookUrl,
    };
    const uri = `${getBaseUrl()}/marvin/companies/add`;
    setIsFetching(true);
    try {
      let result = await fetch(uri, {
        ...getDefaultFetchOptions(HTTP_VERB.POST),
        body: JSON.stringify(body),
      });
      if (result.ok) {
        setIsError(false);
        setCompany("");
        setEmail("");
        setWebhookUrl("");
        getCompanies();
        setResultMessage(`Company ${company} added succesfully.`);
      } else {
        setIsError(true);
        setResultMessage(
          `Error encountered adding Company: ${company}.  Status Code: ${result.status}`
        );
      }
    } catch (error) {
      console.log(error);
    }
    setIsFetching(false);
  };
  return (
    <GenericFormContainer
      formMaxWidth={"500px"}
      isSubmitDisabled={!allowSubmit()}
      isFetching={isFetching}
      onSubmitClick={() => submitCompany()}
      isResultError={isError}
      resultMessage={resultMessage}
      title="Add Company"
      subTitle="Insert a Company into Marvin"
    >
      <VStack align={"flex-start"} p="2">
        <CompanySelectCore
          value={company}
          onValueChange={(name) => setCompany(name)}
          fetching={isFetching}
          companies={companies}
          useIdAsValue={false}
        />
      </VStack>
      <FormControl isInvalid={!isEmailValid && email.length !== 0}>
        <FormLabel htmlFor="email">Email Address</FormLabel>
        <Input
          id="email"
          type="email"
          variant="filled"
          maxLength={50}
          onBlur={() => setIsEmailValid(email.match(emailValidationRegex))}
          value={email}
          onChange={(e) => setEmail(e.target.value)}
          placeholder="Enter Email Address"
        />
        <FormErrorMessage>Email Address is invalid</FormErrorMessage>
      </FormControl>
      <FormControl>
        <FormLabel htmlFor="webhookUrl">Webhook Url</FormLabel>
        <Input
          id="webhookUrl"
          type="text"
          placeholder="Enter Webhook Url"
          variant={"filled"}
          value={webhookUrl}
          onChange={(e) => setWebhookUrl(e.target.value)}
        />
      </FormControl>
    </GenericFormContainer>
  );
};

export default AddMarvinCompany;
