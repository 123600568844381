import React, { useState } from "react";
import { FormControl, FormLabel, Input, VStack } from "@chakra-ui/react";
import { getBaseUrl, getDefaultFetchOptions } from "../../utils";
import GenericFormContainer from "../../common/GenericForm/GenericFormContainer";
import { HTTP_VERB } from "../../enums";
import CompanySelect from "../../common/CompanySelect";

const UpdateCredentials = () => {
  const [isFetching, setIsFetching] = useState(false);
  const [userName, setUserName] = useState("");
  const [password, setPassword] = useState("");
  const [folderName, setFolderName] = useState("");
  const [userID, setUserID] = useState("");
  const [name, setName] = useState("");
  const [resultMessage, setResultMessage] = useState("");
  const [isError, setIsError] = useState(false);

  const submitCredentials = async () => {
    try {
      setIsFetching(true);
      const body = {
        apiUser: userName,
        apiPassword: password,
        dropboxFolder: folderName,
        userId: userID,
      };
      var uri = `${getBaseUrl()}/companies/${name}/1ticket`;
      var result = await fetch(uri, {
        ...getDefaultFetchOptions(HTTP_VERB.POST),
        body: JSON.stringify(body),
      });
      if (result.ok) {
        setIsError(false);
        setResultMessage(`Credentials for: ${name} added succesfully.`);
      } else {
        setIsError(true);
        setResultMessage(
          `Error encountered adding credentials to ${name}. Status Code: ${result.status}`
        );
      }
    } catch (error) {
      setIsFetching(false);
      console.log(error);
    }
    setIsFetching(false);
    setName("");
    setUserName("");
    setPassword("");
    setFolderName("");
    setUserID("");
  };
  const allowSubmit = () => {
    if (name.length > 0) {
      return true;
    }
    return false;
  };
  return (
    <GenericFormContainer
      formMaxWidth={"500px"}
      isSubmitDisabled={!allowSubmit()}
      isFetching={isFetching}
      onSubmitClick={() => {
        submitCredentials();
      }}
      isResultError={isError}
      resultMessage={resultMessage}
      subTitle={"Add 1Ticket credentials to new companies"}
      title={"Add 1Ticket Credentials"}
    >
      <VStack align={"flex-start"} p="2">
        <CompanySelect
          companyName={name}
          onCompanyNameChange={(name) => setName(name)}
        />
        <FormControl>
          <FormLabel htmlFor="userName">API User Name</FormLabel>
          <Input
            id="userName"
            type="text"
            placeholder="Enter API User Name"
            maxLength={128}
            variant={"filled"}
            value={userName}
            onChange={(e) => setUserName(e.target.value)}
          />
        </FormControl>
        <FormControl>
          <FormLabel htmlFor="password">API Password</FormLabel>
          <Input
            id="password"
            type="text"
            maxLength={50}
            placeholder="Enter API Password"
            variant={"filled"}
            value={password}
            onChange={(e) => setPassword(e.target.value)}
          />
        </FormControl>
        <FormControl>
          <FormLabel htmlFor="folderName">Dropbox Folder Name</FormLabel>
          <Input
            id="folderName"
            type="text"
            maxLength={50}
            placeholder="Enter Dropbox Folder Name"
            variant={"filled"}
            value={folderName}
            onChange={(e) => setFolderName(e.target.value)}
          />
        </FormControl>
        <FormControl>
          <FormLabel htmlFor="userID">AutoPO_User_ID</FormLabel>
          <Input
            id="userID"
            type="number"
            maxLength={50}
            placeholder="Enter AutoPO_User_ID"
            variant={"filled"}
            value={userID}
            onChange={(e) => setUserID(e.target.value)}
          />
        </FormControl>
      </VStack>
    </GenericFormContainer>
  );
};

export default UpdateCredentials;
