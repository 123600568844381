import React, { useEffect, useState, useCallback } from "react";
import TableCore from "../../common/TableCore";
import FormHeader from "../../common/FormHeader";
import { Box, Select, useToast } from "@chakra-ui/react";
import { getDefaultFetchOptions, getBaseUrl } from "../../utils";
import { HTTP_VERB, TABLE_MODE } from "../../enums";

const Intervention = () => {
  const [search, setSearch] = useState("");
  const [orders, setOrders] = useState([]);
  const toast = useToast();
  const renderToast = useCallback((toast, isError, title, desc) => {
    let status = "success";
    if (isError) {
      status = "error";
    }
    toast({
      title: title,
      description: desc,
      status: status,
      duration: 3000,
      isClosable: true,
    });
  }, []);
  useEffect(() => {
    async function getUsers() {
      const uri = `${getBaseUrl()}/api/FulfillmentIntervention`;
      let request = await fetch(uri, getDefaultFetchOptions(HTTP_VERB.GET));
      if (request.ok) {
        let response = await request.json();
        setOrders(response);
      } else {
        renderToast(
          toast,
          false,
          "Fetching Orders failed",
          `Error fetching rejected orders`
        );
      }
    }
    getUsers();
  }, [renderToast, toast]);
  const columns = [
    {
      label: "Listing",
      renderCell: (item) => item.listingId,
      resize: true,
    },
    {
      label: "Order",
      renderCell: (item) => item.externalOrderId,
    },
    {
      label: "Seats",
      renderCell: (item) => item.seats,
    },
    {
      label: "EventDate",
      renderCell: (item) => new Date(item.eventDate).toLocaleDateString(),
    },
    {
      label: "EventTime",
      renderCell: (item) => item.eventTime,
    },
    {
      label: "Hours Until",
      renderCell: (item) => item.hoursUntilEvent,
    },
    {
      label: "Status",
      renderCell: (item) => renderStatus(item),
    },
  ];

  const data = () => {
    let output = { nodes: [] };
    if (!orders || orders.length === 0) {
      return output;
    }
    if (!search) {
      output.nodes = orders;
    }
    let data = orders.filter((x) => {
      var searchTerm = search?.toLowerCase();
      return (
        x.listingId?.toString().includes(searchTerm) ||
        x.externalOrderId?.toString().includes(searchTerm)
      );
    });
    output.nodes = data;
    return output;
  };
  const handleStatusUpdate = async (data, status) => {
    const uri = `${getBaseUrl()}/api/FulfillmentIntervention?id=${
      data.id
    }&status=${status}`;
    let request = await fetch(uri, getDefaultFetchOptions(HTTP_VERB.PATCH));
    if (request.ok) {
      renderToast(
        toast,
        false,
        "Updated",
        `Status updated for Listing ${data.username}`
      );
      if (status !== 3) {
        let temp = [...orders];
        console.log(temp.length);
        temp = temp.filter((x) => x.id !== data.id);
        console.log(temp.length);
        setOrders(temp);
      }
    } else {
      let errResponse = await request.json();
      renderToast(toast, true, "Error updating status.", errResponse?.detail);
    }
  };
  const renderStatus = (data) => {
    return (
      <Select
        value={data.status}
        onChange={(e) => handleStatusUpdate(data, e.target.value)}
      >
        <option value="1">FULLFILLED</option>
        <option value="2">REJECTED</option>
        <option value="3">PENDING</option>
      </Select>
    );
  };
  return (
    <Box>
      <FormHeader
        title={"Fulfillment Order Intervention"}
        subTitle={"Orders with unavailable inventory"}
      />
      <TableCore
        columns={columns}
        data={data()}
        height={"900px"}
        width={"1200px"}
        onSearchChange={(e) => setSearch(e)}
        pageSize={10}
        mode={TABLE_MODE.VIEW_ONLY}
        showSearch={true}
      />
    </Box>
  );
};

export default Intervention;
