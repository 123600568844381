import { FormControl, FormLabel, Input, VStack } from "@chakra-ui/react";
import React, { useState } from "react";
import GenericFormContainer from "../../common/GenericForm/GenericFormContainer";
import { HTTP_VERB } from "../../enums";
import { getBaseUrl, getDefaultFetchOptions } from "../../utils";

const Move = () => {
  const [oldId, setOldId] = useState("");
  const [newId, setNewId] = useState("");
  const [isFetching, setIsFetching] = useState(false);
  const [resultMessage, setResultMessage] = useState("");
  const [isError, setIsError] = useState(false);
  const submit = async () => {
    const uri = `${getBaseUrl()}/events/move`;
    setIsFetching(true);
    try {
      const body = {
        oldEventID: oldId,
        newEventID: newId,
      };
      let result = await fetch(uri, {
        ...getDefaultFetchOptions(HTTP_VERB.POST),
        body: JSON.stringify(body),
      });
      if (result.ok) {
        setIsError(false);
        setResultMessage(
          `Items from event ${oldId} moved into ${newId} succesfully.`
        );
      } else {
        setIsError(true);
        setResultMessage(
          `Error encountered moving items from event ${oldId} to ${newId}.  Status Code: ${result.status}`
        );
      }
    } catch (error) {
      console.log(error);
    }
    setIsFetching(false);
    setNewId("");
    setOldId("");
  };
  const allowSubmit = () => {
    return oldId.length > 0 && newId.length > 0;
  };
  return (
    <GenericFormContainer
      formMaxWidth={"500px"}
      title={"Move Events"}
      subTitle="Move all items from old event to new event"
      isResultError={isError}
      isSubmitDisabled={!allowSubmit()}
      onSubmitClick={() => submit()}
      isFetching={isFetching}
      resultMessage={resultMessage}
    >
      <VStack align={"flex-start"} p="2">
        <FormControl>
          <FormLabel htmlFor="oldId">Old Event ID</FormLabel>
          <Input
            id="oldId"
            type="number"
            value={oldId}
            onChange={(e) => setOldId(e.target.value)}
            placeholder="Enter Old Event ID"
            variant={"filled"}
            required={true}
          />
        </FormControl>
        <FormControl>
          <FormLabel htmlFor="newId">New Event ID</FormLabel>
          <Input
            id="newId"
            type="number"
            value={newId}
            onChange={(e) => setNewId(e.target.value)}
            placeholder="Enter New Event ID"
            variant={"filled"}
            required={true}
          />
        </FormControl>
      </VStack>
    </GenericFormContainer>
  );
};

export default Move;
