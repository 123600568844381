import { FormControl, FormLabel, Input, VStack, HStack, Icon, Text } from "@chakra-ui/react";
import React, { useState } from "react";
import GenericFormContainer from "../../common/GenericForm/GenericFormContainer";
import { HTTP_VERB } from "../../enums";
import { getBaseUrl, getDefaultFetchOptions } from "../../utils";
import DynamicInputList from "../../common/DynamicInputList";
import { FcInfo } from "react-icons/fc";

const TransferInventory = () => {
  const [user_Name, setUser_Name] = useState("");
  const [company_Name, setCompany_Name] = useState("");
  const [from_Company_Name, setFrom_Company_Name] = useState("");
  const [internal_Notes, setInternal_Notes] = useState("");
  const [listing_IDs, setListing_IDs] = useState("");
  const [event_IDs_TVP, setEvent_IDs_TVP] = useState([""]);
  const [isFetching, setIsFetching] = useState(false);
  const [resultMessage, setResultMessage] = useState("");
  const [isError, setIsError] = useState(false);
  const submit = async () => {
    const uri = `${getBaseUrl()}/events/transfer`;
    setIsFetching(true);
    try {
      const body = {
        user_Name: user_Name,
        company_Name: company_Name,
        from_Company_Name: from_Company_Name,
        internal_Notes: internal_Notes,
        event_IDs_TVP: event_IDs_TVP,
        listing_IDs: listing_IDs
      };
      let result = await fetch(uri, {
        ...getDefaultFetchOptions(HTTP_VERB.POST),
        body: JSON.stringify(body),
      });
      if (result.ok) {
        setIsError(false);
        setResultMessage(`Inventory transfer: Company: ${company_Name}, User: ${user_Name}, From Company: ${from_Company_Name}, with Internal Notes ${internal_Notes}, Listings ${listing_IDs}, Events: ${event_IDs_TVP} succesfully.`);
      } else {
        setIsError(true);
        setResultMessage(
          `Error encountered Inventory transfer: Company: ${company_Name}, User: ${user_Name}, From Company: ${from_Company_Name}, with Internal Notes ${internal_Notes}, Listings ${listing_IDs}, Events: ${event_IDs_TVP}... Status Code: ${result.status}`
        );
      }
    } catch (error) {
      console.log(error);
    }
    setIsFetching(false);
    setUser_Name("");
    setCompany_Name("");
    setFrom_Company_Name("");
    setInternal_Notes("");
    setListing_IDs("");
    setEvent_IDs_TVP([""]);
  };
  const allowSubmit = () => {
    return user_Name.length > 0 && company_Name.length > 0 && ((event_IDs_TVP.length > 0 && event_IDs_TVP[0].length > 0) || listing_IDs.length > 0);
  };
  return (
    <GenericFormContainer
      formMaxWidth={"500px"}
      title={"Transfer Inventory"}
      subTitle="Transfer inventory from user to user"
      isResultError={isError}
      isSubmitDisabled={!allowSubmit()}
      onSubmitClick={() => submit()}
      isFetching={isFetching}
      resultMessage={resultMessage}
    >
      <VStack align={"flex-start"} p="2">
        <FormControl>
          <FormLabel htmlFor="user_Name">User_Name</FormLabel>
          <Input
            id="user_Name"
            type="text"
            value={user_Name}
            onChange={(e) => setUser_Name(e.target.value)}
            placeholder="Enter your User_Name"
            variant={"filled"}
            required={true}
          />
        </FormControl>
        <FormControl>
          <FormLabel htmlFor="company_Name">To_Company_Name</FormLabel>
          <Input
            id="company_Name"
            type="text"
            value={company_Name}
            onChange={(e) => setCompany_Name(e.target.value)}
            placeholder="Enter To_Company_Name"
            variant={"filled"}
            required={true}
          />
        </FormControl>
        <FormControl>
          <FormLabel htmlFor="from_Company_Name">From_Company_Name</FormLabel>
          <Input
            id="from_Company_Name"
            type="text"
            value={from_Company_Name}
            onChange={(e) => setFrom_Company_Name(e.target.value)}
            placeholder="Enter From_Company_Name"
            variant={"filled"}
            required={true}
          />
        </FormControl>
        <FormControl>
          <FormLabel htmlFor="internal_Notes">Internal_Notes</FormLabel>
          <Input
            id="internal_Notes"
            type="text"
            value={internal_Notes}
            onChange={(e) => setInternal_Notes(e.target.value)}
            placeholder="Enter your Internal_Notes"
            variant={"filled"}
            required={false}
          />
        </FormControl>
        <FormControl>
          <FormLabel htmlFor="listing_IDs">Listing_IDs</FormLabel>
          <Input
            id="listing_IDs"
            type="text"
            value={listing_IDs}
            onChange={(e) => setListing_IDs(e.target.value)}
            placeholder="Enter Listing_IDs"
            variant={"filled"}
            required={false}
          />
        </FormControl>
        <HStack ml={2} mr={2} borderWidth={1} borderRadius={4} p={2}>
          <Icon as={FcInfo} />
          <Text fontSize={"xs"} m="0" as={"span"}>
            Separate IDs by comma or enter Event_IDs_TVP
          </Text>
        </HStack>
        <DynamicInputList
          initialValues={event_IDs_TVP}
          formLabel={"Event_IDs_TVP"}
          onValueChange={(v) => setEvent_IDs_TVP(v)}
        />
      </VStack>
    </GenericFormContainer>
  );
};

export default TransferInventory;
