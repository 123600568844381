import React, { useState, useEffect, useCallback } from "react";
import {
  Box,
  Button,
  FormControl,
  FormLabel,
  HStack,
  Input,
  List,
  ListItem,
  VStack,
  useToast,
  Modal,
  ModalBody,
  ModalContent,
  ModalOverlay,
  SimpleGrid,
  useDisclosure,
} from "@chakra-ui/react";
import { useParams } from "react-router-dom";
import { DateTime } from "luxon";
import ComponentContainer from "../../../common/ComponentContainer";
import FormHeader from "../../../common/FormHeader";
import { getBaseUrl, getDefaultFetchOptions } from "../../../utils";
import { HTTP_VERB } from "../../../enums";
import CompanySelectCore from "../../../common/CompanySelectCore";
import ButtonDatePicker from "../../../common/ButtonDatePicker";
import { List as VirtualList } from "react-virtualized";
import OneTicketEventMap from "./OneTicketEventMap";
import SearchResultDetail from "./SearchResultDetail";
import PrettyPrintJson from "../../../common/PrettyPrintJson";

const OneTicketManualSearch = () => {
  const getStartDate = () => {
    return DateTime.now().toJSDate();
  };
  const getEndDate = () => {
    return DateTime.now().plus({ days: 30 }).toJSDate();
  };
  const params = useParams();
  const toast = useToast();
  const { isOpen, onOpen, onClose } = useDisclosure();
  const renderToast = useCallback((toast, isError, title, desc) => {
    let status = "success";
    if (isError) {
      status = "error";
    }
    toast({
      title: title,
      description: desc,
      status: status,
      duration: 3000,
      isClosable: true,
    });
  }, []);
  const [companies, setCompanies] = useState();
  const [selectedApiKey, setSelectedApiKey] = useState("");
  const [selectedTicketIds, setSelectedTicketIds] = useState([]);
  const [companiesFetching, setCompaniesFetching] = useState(false);
  const [eventName, setEventName] = useState("");
  const [startDate, setStartDate] = useState(getStartDate());
  const [endDate, setEndDate] = useState(getEndDate());
  const [venue, setVenue] = useState("");
  const [section, setSection] = useState("");
  const [row, setRow] = useState("");
  const [seat, setSeat] = useState("");
  const [searching, setSearching] = useState(false);
  const [results, setResults] = useState([]);
  const [externalName, setExternalName] = useState("");
  const [eventHeadlinerId, setEventHeadlinerId] = useState();
  const [invoice, setInvoice] = useState();
  const [transferResult, setTransferResult] = useState();
  const [transferring, setTransferring] = useState(false);

  useEffect(() => {
    setCompaniesFetching(true);
    (async () => {
      const uri = `${getBaseUrl()}/invoice/companies`;
      let result = await fetch(uri, getDefaultFetchOptions(HTTP_VERB.GET));
      let response = await result.json();
      if (result.ok) {
        setCompanies(response);
      } else {
        renderToast(toast, true, "Error fetching Companies", response?.detail);
      }
    })();
    setCompaniesFetching(false);
  }, [renderToast, toast]);
  useEffect(() => {
    const invoiceId = params?.invoiceId;
    if (!invoiceId) {
      return;
    }
    (async () => {
      const uri = `${getBaseUrl()}/invoice/${invoiceId}`;
      let result = await fetch(uri, getDefaultFetchOptions(HTTP_VERB.GET));
      let response = await result.json();
      if (result.ok) {
        const eventDate = DateTime.fromISO(response.event_Date);
        setSelectedApiKey(response.apiKey);
        setEventName(response.event_Name);
        setVenue(response.venue_Name);
        setStartDate(eventDate.toJSDate());
        setEndDate(eventDate.plus({ days: 1 }).toJSDate());
        setInvoice(response);
      } else {
        console.log(response);
      }
    })();
  }, [params?.invoiceId]);
  const handleCompanyChange = (v) => {
    setSelectedApiKey(v);
  };
  const handleSearchClick = async () => {
    setSearching(true);
    setResults([]);
    const uri = `${getBaseUrl()}/oneticket/search?apiToken=${selectedApiKey}`;
    const body = {
      startDate: startDate,
      endDate: endDate,
      event: eventName,
      venue: venue,
      section: section,
      row: row,
      seat: seat,
    };
    let result = await fetch(uri, {
      ...getDefaultFetchOptions(HTTP_VERB.POST),
      body: JSON.stringify(body),
    });
    let response = await result.json();
    if (result.ok) {
      setResults(response);
    } else {
      renderToast(toast, true, "Error fetching results", response?.detail);
    }
    setSearching(false);
  };
  const shouldDisableSearch = () => {
    if (!selectedApiKey.length) {
      return true;
    }
    return false;
  };
  const getSearchResultById = (id) => {
    return results.find((v) => {
      return v.id === id;
    });
  };
  const handleTicketSelect = (id) => {
    if (selectedTicketIds.indexOf(id) >= 0) {
      let removed = selectedTicketIds.filter((v) => {
        return v !== id;
      });
      setSelectedTicketIds(removed);
      return;
    }

    const upd = selectedTicketIds.concat(id);
    setSelectedTicketIds(upd);
  };
  const renderResultRow = ({
    index,
    isScrolling,
    isVisible,
    key,
    parent,
    style,
  }) => {
    const item = results[index];
    if (selectedTicketIds.indexOf(item.id) >= 0) {
      style["fontWeight"] = "bold";
    } else {
      style["fontWeight"] = "normal";
    }
    return (
      <List
        {...style}
        borderBottom={"1px"}
        cursor={"pointer"}
        key={key}
        onClick={() => handleTicketSelect(item.id)}
      >
        <ListItem>Event: {item.event}</ListItem>v
        <ListItem>Venue: {item.venue}</ListItem>
        <ListItem>
          Date:{" "}
          {DateTime.fromISO(item.local_Date).toLocaleString(
            DateTime.DATETIME_MED
          )}
        </ListItem>
        <ListItem>Section: {item.section}</ListItem>
        <ListItem>Row: {item.row}</ListItem>
        <ListItem>Seat: {item.seat}</ListItem>
      </List>
    );
  };
  const handleCancelClick = () => {
    onClose();
  };
  const handleSaveClick = (data) => {
    renderToast(toast, false, "Success", "Mapping succesfully updated");
    submitHeadlinerTransfers(eventHeadlinerId);
    onClose();
  };
  const submitHeadlinerTransfers = async (headlinerId) => {
    const uri = `${getBaseUrl()}/invoice/bulkheadlinertransfer?headlinerId=${headlinerId}`;
    const result = await fetch(uri, getDefaultFetchOptions(HTTP_VERB.POST));
    const response = await result.json();
    console.log(response);
  };
  const handleDeleteClick = (id) => {
    renderToast(toast, false, "Success", "Mapping succesfully deleted");
    onClose();
  };
  const handleMapClick = () => {
    const id = selectedTicketIds[0];
    const result = getSearchResultById(id);
    setExternalName(result.event);
    onOpen();
  };
  const handleTicketRemoveClick = (id) => {
    if (selectedTicketIds.indexOf(id) >= 0) {
      let removed = selectedTicketIds.filter((v) => {
        return v !== id;
      });
      setSelectedTicketIds(removed);
      return;
    }
  };
  const renderSelectedTickets = () => {
    return selectedTicketIds.map((v, i) => {
      const info = getSearchResultById(v);
      return (
        <SearchResultDetail
          key={i}
          data={info}
          onRemove={(id) => handleTicketRemoveClick(id)}
        />
      );
    });
  };
  const handleManualTransfer = async () => {
    setTransferring(true);
    const uri = `${getBaseUrl()}/invoice/manualTransfer`;
    const body = {
      invoiceId: invoice.invoice_ID,
      ticketIds: selectedTicketIds,
    };
    const result = await fetch(uri, {
      ...getDefaultFetchOptions(HTTP_VERB.POST),
      body: JSON.stringify(body),
    });
    const response = await result.json();
    setTransferResult(response);
    setSelectedTicketIds([]);
    setTransferring(false);
  };
  return (
    <ComponentContainer width={1000}>
      <FormHeader
        title={"1Ticket Event Search"}
        subTitle={
          "Only Start Date and End Date are required.  All other fields are optional"
        }
      />
      {invoice && (
        <Box mt={5} mb={5} p={2} borderWidth={1} borderRadius={4}>
          <List>
            <ListItem>InvoiceId: {invoice.invoice_ID}</ListItem>
            <ListItem>
              Event Date:{" "}
              {DateTime.fromISO(invoice.event_Date).toLocaleString()}
            </ListItem>
            <ListItem>Event Time: {invoice.event_Time}</ListItem>
            <ListItem>Event Name: {invoice.event_Name}</ListItem>
            <ListItem>Venue: {invoice.venue_Name}</ListItem>
            <ListItem>Section: {invoice.section}</ListItem>
            <ListItem>Row: {invoice.row}</ListItem>
            <ListItem>
              Seats: {invoice.seat_From} - {invoice.seat_Thru}
            </ListItem>
          </List>
        </Box>
      )}
      {selectedTicketIds.length > 0 && (
        <SimpleGrid autoColumns autoRows minChildWidth="350px" spacing="5">
          {renderSelectedTickets()}
        </SimpleGrid>
      )}
      {selectedTicketIds.length === 1 && (
        <Button
          colorScheme={"teal"}
          isLoading={searching}
          variant="solid"
          onClick={() => handleMapClick()}
        >
          Map Event Name
        </Button>
      )}
      {selectedTicketIds?.length === invoice?.seats?.length && (
        <Button
          colorScheme={"blue"}
          isLoading={transferring}
          variant="solid"
          onClick={() => handleManualTransfer()}
        >
          Transfer
        </Button>
      )}
      {transferResult && (
        <Box>
          <PrettyPrintJson data={transferResult} />
          <Button
            colorScheme={"blue"}
            isLoading={searching}
            variant="solid"
            onClick={() => setTransferResult()}
          >
            Clear
          </Button>
        </Box>
      )}
      <CompanySelectCore
        selectedValue={selectedApiKey}
        companies={companies}
        useIdAsValue={true}
        idName="key"
        fetching={companiesFetching}
        onValueChange={(v) => handleCompanyChange(v)}
      />
      <VStack align={"flex-start"} p="2" width={"500px"}>
        <HStack>
          <FormControl>
            <FormLabel>Start Date</FormLabel>
            <ButtonDatePicker
              date={startDate}
              onDateChange={(d) => setStartDate(d)}
            />
          </FormControl>
          <FormControl>
            <FormLabel>End Date</FormLabel>
            <ButtonDatePicker
              date={endDate}
              onDateChange={(d) => setEndDate(d)}
            />
          </FormControl>
        </HStack>
        <HStack w={"100%"}>
          <FormControl>
            <FormLabel>Event Name</FormLabel>
            <Input
              placeholder="Enter Event Name"
              type="text"
              variant="filled"
              value={eventName}
              onChange={(e) => setEventName(e.target.value)}
            />
          </FormControl>
          <FormControl>
            <FormLabel>Venue</FormLabel>
            <Input
              placeholder="Enter Venue Name"
              type="text"
              variant="filled"
              value={venue}
              onChange={(e) => setVenue(e.target.value)}
            />
          </FormControl>
        </HStack>
        <HStack>
          <FormControl>
            <FormLabel>Section</FormLabel>
            <Input
              placeholder="Enter Section"
              type="text"
              variant="filled"
              value={section}
              onChange={(e) => setSection(e.target.value)}
            />
          </FormControl>
          <FormControl>
            <FormLabel>Row</FormLabel>
            <Input
              placeholder="Enter Row"
              type="text"
              variant="filled"
              value={row}
              onChange={(e) => setRow(e.target.value)}
            />
          </FormControl>
          <FormControl>
            <FormLabel>Seat</FormLabel>
            <Input
              placeholder="Enter Seat"
              type="text"
              variant="filled"
              value={seat}
              onChange={(e) => setSeat(e.target.value)}
            />
          </FormControl>
        </HStack>
        <Button
          colorScheme={"teal"}
          disabled={shouldDisableSearch()}
          isLoading={searching}
          variant="solid"
          onClick={() => handleSearchClick()}
        >
          Search
        </Button>
      </VStack>
      {results && (
        <>
          Total Results: {results.length}
          <Box p={"2"} borderWidth="1px" borderRadius="lg">
            <VirtualList
              height={500}
              width={970}
              rowCount={results.length}
              rowHeight={155}
              rowRenderer={renderResultRow}
            />
          </Box>
        </>
      )}
      <Modal
        isOpen={isOpen}
        onClose={onClose}
        scrollBehavior={"outside"}
        size={"4xl"}
      >
        <ModalOverlay />
        <ModalContent>
          <ModalBody>
            <OneTicketEventMap
              externalName={externalName}
              eventDate={startDate}
              eventHeadlinerId={eventHeadlinerId}
              onExternalNameChange={(e) => setExternalName(e)}
              onEventHeadlinerChange={(e) => setEventHeadlinerId(e)}
              onCancelClick={() => {
                handleCancelClick();
              }}
              onSave={(d) => handleSaveClick(d)}
              onDelete={(id) => handleDeleteClick(id)}
            />
          </ModalBody>
        </ModalContent>
      </Modal>
    </ComponentContainer>
  );
};

export default OneTicketManualSearch;
