import { FormControl, FormLabel, VStack, Input } from "@chakra-ui/react";
import React, { useState } from "react";
import GenericFormContainer from "../../common/GenericForm/GenericFormContainer";
import { HTTP_VERB } from "../../enums";
import { getBaseUrl, getDefaultFetchOptions } from "../../utils";

const AlfredTicketMasterLink = () => {
  const [isFetching, setIsFetching] = useState(false);
  const [resultMessage, setResultMessage] = useState("");
  const [isError, setIsError] = useState(false);
  const [posnetEventId, setPosnetEventId] = useState("");
  const [ticketmasterEventId, setTicketmasterEventId] = useState("");
  const submit = async () => {
    const uri = `${getBaseUrl()}/alfred/${posnetEventId}/linkticketmaster`;
    setIsFetching(true);
    try {
      let result = await fetch(uri, {
        ...getDefaultFetchOptions(HTTP_VERB.POST),
        body: JSON.stringify(ticketmasterEventId),
      });
      if (result.ok) {
        setIsError(false);
        setResultMessage(`Event linked to Alfred Successfully.`);
        setPosnetEventId("");
        setTicketmasterEventId("");
      } else {
        setIsError(true);
        let responseMessage = await result.text();
        setResultMessage(
          `Error linking Event ${ticketmasterEventId}.  Status Code: ${result.status}.  ${responseMessage}`
        );
      }
    } catch (error) {
      console.log(error);
    }
    setIsFetching(false);
  };
  const allowSubmit = () => {
    return true;
  };
  return (
    <GenericFormContainer
      formMaxWidth={"500px"}
      width={"400px"}
      isFetching={isFetching}
      isResultError={isError}
      isSubmitDisabled={!allowSubmit()}
      onSubmitClick={() => submit()}
      resultMessage={resultMessage}
      subTitle="Link Alfred to a Ticketmaster Event"
      title={"Alfred Ticketmaster Link"}
    >
      <VStack align={"flex-start"} p="2">
        <FormControl>
          <FormLabel htmlFor="eventId">POSNET Event Id</FormLabel>
          <Input
            id="eventId"
            variant="filled"
            type="number"
            value={posnetEventId}
            placeholder="Enter POSNET Event ID"
            onChange={(e) => setPosnetEventId(e.target.value)}
          />
        </FormControl>
        <FormControl>
          <FormLabel htmlFor="tmEventId">Ticketmaster EventId</FormLabel>
          <Input
            variant="filled"
            maxLength={20}
            id="tmEventId"
            value={ticketmasterEventId}
            onChange={(e) => setTicketmasterEventId(e.target.value)}
            placeholder="Enter Ticketmaster Event ID"
          />
        </FormControl>
      </VStack>
    </GenericFormContainer>
  );
};

export default AlfredTicketMasterLink;
