import React from "react";
import { Box, Flex, Icon } from "@chakra-ui/react";
import Sidebar from "../../common/Sidebar/Sidebar";
import PayConsignors from "./PayConsignors";
import { Outlet, Routes, Route } from "react-router-dom";
import { FaDollarSign } from "react-icons/fa";
import { CgArrowsExchange } from "react-icons/cg";

const subMenu = [
  {
    icon: <Icon as={FaDollarSign} />,
    path: "",
    label: "Pay Consignors",
  },
  {
    icon: <Icon as={CgArrowsExchange} />,
    path: "consignorrates",
    label: "Consignor Rates",
  },
];

const AccountingHome = () => {
  return (
    <Flex direction={"row"}>
      <Sidebar isOpen={true} title={"Accounting"} linkItems={subMenu} />
      <Box h="full" p="4">
        <Flex direction={"column"}>
          <Routes>
            <Route path="/" element={<PayConsignors />} />
          </Routes>
          <Outlet />
        </Flex>
      </Box>
    </Flex>
  );
};

export default AccountingHome;
