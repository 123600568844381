import React, { useState, useCallback } from "react";
import { Box, VStack, HStack, Button, useToast } from "@chakra-ui/react";
import ComponentContainer from "../../../common/ComponentContainer";
import FormHeader from "../../../common/FormHeader";
import { HTTP_VERB } from "../../../enums";
import { getBaseUrl, getDefaultFetchOptions } from "../../../utils";
import PrettyPrintJson from "../../../common/PrettyPrintJson";

const OneTicketBatchTransfer = () => {
  const [isFetching, setIsFetching] = useState(false);
  const [results, setResults] = useState("");
  const toast = useToast();
  const renderToast = useCallback((toast, isError, title, desc) => {
    let status = "success";
    if (isError) {
      status = "error";
    }
    toast({
      title: title,
      description: desc,
      status: status,
      duration: 3000,
      isClosable: true,
    });
  }, []);
  const onProcessTransferclick = async () => {
    setIsFetching(true);
    const uri = `${getBaseUrl()}/invoice/transfers`;
    let request = await fetch(uri, {
      ...getDefaultFetchOptions(HTTP_VERB.POST),
    });
    if (request.ok) {
      let response = await request.json();
      renderToast(toast, false, "Invoices Submitted", "See results below");
      setResults(response);
    } else {
      let err = await request.json();
      renderToast(toast, true, "Error Submitting Invoices", err?.detail);
    }
    setIsFetching(false);
  };
  const onRetryClick = async () => {
    setIsFetching(true);
    const uri = `${getBaseUrl()}/invoice/retry`;
    let request = await fetch(uri, {
      ...getDefaultFetchOptions(HTTP_VERB.POST),
    });
    if (request.ok) {
      let response = await request.json();
      renderToast(toast, false, "Retry initiated", "See results below");
      setResults(response);
    } else {
      let err = await request.json();
      renderToast(toast, true, "Error retrying invoices", err?.detail);
    }
    setIsFetching(false);
  };
  const onUpdateStatusClick = async () => {
    setIsFetching(true);
    const uri = `${getBaseUrl()}/invoice/statuses`;
    try {
      let request = await fetch(uri, {
        ...getDefaultFetchOptions(HTTP_VERB.POST),
      });
      if (request.ok) {
        let response = await request.json();
        renderToast(
          toast,
          false,
          "Status Updates Performed",
          "See results below"
        );
        setResults(response);
      } else {
        try {
          let err = await request.json();
          renderToast(toast, true, "Error checking Status", err?.detail);
        } catch (error) {
          renderToast(toast, true, "Error error checking status", error);
        }
      }
    } catch (error) {
      renderToast(true, toast, "Error", error);
      console.log(error);
      setIsFetching(false);
    }

    setIsFetching(false);
  };
  const onClearClick = () => {
    setResults("");
  };
  return (
    <ComponentContainer width={1000}>
      <FormHeader
        title={"1Ticket Batch Processing"}
        subTitle={"Initiate Batch processing for transfer and status updates"}
      />
      <Box mt={5}>
        <FormHeader
          title={"Initiate Transfers"}
          subTitle={"Execute 1Ticket Invoice Transfer Batch Process"}
        />
        <VStack align={"flex-start"} p="2">
          <HStack>
            <Button
              colorScheme={"teal"}
              isLoading={isFetching}
              onClick={() => onProcessTransferclick()}
            >
              Initiate Transfers
            </Button>
          </HStack>
        </VStack>
      </Box>
      <Box mt={5}>
        <FormHeader
          title={"Update Statuses"}
          subTitle={"Fetch Pending Invoices and update their status"}
        />
        <VStack align={"flex-start"} p="2">
          <HStack>
            <Button
              colorScheme={"teal"}
              isLoading={isFetching}
              onClick={() => onUpdateStatusClick()}
            >
              Update Statuses
            </Button>
          </HStack>
        </VStack>
      </Box>
      <Box mt={5}>
        <FormHeader
          title={"Retry Failures"}
          subTitle={
            "Retry Transfer on Invoices that failed and are eligible for retry"
          }
        />
        <VStack align={"flex-start"} p="2">
          <HStack>
            <Button
              colorScheme={"teal"}
              isLoading={isFetching}
              onClick={() => onRetryClick()}
            >
              Retry Eligible Invoices
            </Button>
          </HStack>
        </VStack>
      </Box>
      {results && (
        <Button colorScheme={"teal"} onClick={() => onClearClick()}>
          Clear Results
        </Button>
      )}
      {results && (
        <Box>
          <PrettyPrintJson data={results} />
        </Box>
      )}
    </ComponentContainer>
  );
};

export default OneTicketBatchTransfer;
