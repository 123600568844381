import React, { useEffect, useState } from "react";
import ComponentContainer from "../../common/ComponentContainer";
import FormHeader from "../../common/FormHeader";
import { HTTP_VERB } from "../../enums";
import { getBaseUrl, getDefaultFetchOptions } from "../../utils";
import { FormLabel, HStack, Switch, VStack } from "@chakra-ui/react";

const EditPortalRoles = ({ userId }) => {
  const [roles, setRoles] = useState([]);
  const [userRoles, setUserRoles] = useState(undefined);
  useEffect(() => {
    (async () => {
      const uri = `${getBaseUrl()}/roles`;
      let request = await fetch(uri, getDefaultFetchOptions(HTTP_VERB.GET));
      if (request.ok) {
        let response = await request.json();
        setRoles(response);
      } else {
        console.log("Error fetching roles");
      }
    })();
    (async () => {
      const uri = `${getBaseUrl()}/users/roles?userId=${userId}`;
      let request = await fetch(uri, getDefaultFetchOptions(HTTP_VERB.GET));
      if (request.ok) {
        let response = await request.json();
        setUserRoles(response);
      } else {
        console.log("Error fetching roles");
      }
    })();
  }, [userId]);
  const updateRole = async (id, checked) => {
    const uri = `${getBaseUrl()}/users/editRole`;
    let body = {
      userId: userId,
      roleId: id,
      isChecked: checked,
    };
    let request = await fetch(uri, {
      ...getDefaultFetchOptions(HTTP_VERB.PATCH),
      body: JSON.stringify(body),
    });
    if (request.ok) {
      setUserRoles((userRoles) => {
        let temp = [...userRoles];
        if (checked === true) {
          temp.push(id);
        } else {
          temp = temp.filter(function (value) {
            return value !== id;
          });
        }
        return temp;
      });
    }
  };
  return (
    <ComponentContainer>
      <FormHeader
        title={"Edit Portal Roles"}
        subTitle={"Assign or remove Portal roles"}
      />
      {roles && userRoles && (
        <VStack align={"flex-start"} p="2">
          {roles.map((role) => (
            <HStack key={role.id}>
              <Switch
                isChecked={userRoles.indexOf(role.id) !== -1}
                onChange={(e) => updateRole(role.id, e.target.checked)}
              />
              <FormLabel>{role.name}</FormLabel>
            </HStack>
          ))}
        </VStack>
      )}
    </ComponentContainer>
  );
};

export default EditPortalRoles;
