import {
  Box,
  Heading,
  Icon,
  UnorderedList,
  ListItem,
  Text,
  Link,
} from "@chakra-ui/react";
import React from "react";
import { FaFileCsv } from "react-icons/fa";
import { getWebUrl } from "../../utils";

const PaymentFiles = ({ files }) => {
  const getFileUrl = (fileName) => {
    const uri = `${getWebUrl()}/${fileName}`;
    return uri;
  };
  const cleanFileName = (fileName) => {
    fileName = fileName.replace("consignorpayments\\", "");
    return fileName;
  };
  const renderFiles = () => {
    if (!files || files.length === 0) {
      return;
    }
    return files.map((v, i) => {
      return (
        <ListItem key={i}>
          <Link href={getFileUrl(v)}>
            {cleanFileName(v)} <Icon as={FaFileCsv} />
          </Link>
        </ListItem>
      );
    });
  };

  return (
    <Box borderWidth={1} borderRadius={4} p={2}>
      <Heading as={"h3"} size="sm" mb="1">
        Payment Reports
      </Heading>
      <Text fontSize={"xs"} as={"span"}>
        Click any file below to download.
      </Text>
      <Box maxHeight={"200px"} overflowY={"auto"}>
        <UnorderedList cursor={"pointer"}>{renderFiles()}</UnorderedList>
      </Box>
    </Box>
  );
};

export default PaymentFiles;
