import {
  Input,
  Text,
  Tr,
  Td,
  IconButton,
  Popover,
  PopoverTrigger,
  PopoverContent,
  PopoverArrow,
  PopoverHeader,
  PopoverBody,
  Portal,
  Button,
  PopoverCloseButton,
  PopoverFooter,
  useDisclosure,
} from "@chakra-ui/react";
import { FaSave } from "react-icons/fa";
import { MdDeleteForever } from "react-icons/md";
import React, { useState } from "react";

const ConsignorRate = ({ data, onSave, onDelete, isFetching }) => {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [retailerFee, setRetailerFee] = useState(data.exchange.retailerFee ?? 0);
  const [markup, setMarkup] = useState(0);
  const [fee, setFee] = useState(0);
  const [notes, setNotes] = useState(data?.rate?.notes ?? '');
  const handleDelete = () => {
    if (onDelete) {
      onDelete(data);
    }
    onClose();
  };
  const handleSave = () => {
    if (onSave) {
      const output = {
        updatedMarkup: markup,
        updatedRetailerFee: retailerFee,
        updatedNotes: notes,
        updatedFee: fee,
        ...data,
      };
      onSave(output);
    }
  };
  return (
    <Tr>
      <Td width={"120px"}>{data.exchange.name}</Td>
      <Td isNumeric>
        <Input
          type={"number"}
          width={"80px"}
          value={markup}
          onChange={(e) => setMarkup(e.target.value)}
        />
      </Td>
      <Td isNumeric>
        <Input
          type={"number"}
          width={"80px"}
          value={retailerFee}
          onChange={(e) => setRetailerFee(e.target.value)}
        />
      </Td>
      <Td isNumeric>
        <Input
          type={"number"}
          width={"80px"}
          value={fee}
          onChange={(e) => setFee(e.target.value)}
        />
      </Td>
      <Td>
        <Input
          type={"text"}
          width={"350px"}
          value={notes}
          onChange={(e) => setNotes(e.target.value)}
        />
      </Td>
      <Td width={"100"}>
        <IconButton
          mr={2}
          isLoading={isFetching}
          onClick={handleSave}
          colorScheme={"blue"}
          aria-label="Save Rate"
          icon={<FaSave />}
        />
        <Popover isOpen={isOpen}>
          <PopoverTrigger>
            <IconButton
              isLoading={isFetching}
              colorScheme={"red"}
              onClick={onOpen}
              aria-label="Remove rate"
              icon={<MdDeleteForever />}
            />
          </PopoverTrigger>
          <Portal>
            <PopoverContent color="white" bg="blue.800" borderColor="blue.800">
              <PopoverHeader pt={4} fontWeight="bold" border="0">
                Warning
              </PopoverHeader>
              <PopoverArrow />
              <PopoverCloseButton onClick={onClose} />
              <PopoverBody>
                Are you sure you want to delete this Exchange?
              </PopoverBody>
              <PopoverFooter
                border="0"
                display="flex"
                alignItems="center"
                justifyContent="space-between"
                pb={4}
              >
                <Button onClick={onClose} colorScheme={"red"}>
                  Cancel
                </Button>
                <Button onClick={handleDelete} colorScheme={"blue"}>
                  Submit
                </Button>
              </PopoverFooter>
            </PopoverContent>
          </Portal>
        </Popover>
      </Td>
      <Td isNumeric>
        <Text>{data.rate?.commissionRate}</Text>
      </Td>
      <Td isNumeric>
        <Text>{data.rate?.markup}</Text>
      </Td>
    </Tr>
  );
};

export default ConsignorRate;
