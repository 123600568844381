import React from "react";
import { FormLabel, FormControl, Select } from "@chakra-ui/react";

const CompanySelectCore = ({
  companies,
  fetching,
  idName = "company_ID",
  onValueChange,
  selectedValue,
  useIdAsValue,
}) => {
  const renderSelectItems = () => {
    if (!companies || companies.length === 0) {
      return;
    }

    let options = [];
    for (const company of companies) {
      if (useIdAsValue) {
        options.push({ name: company.company_Name, id: company[idName] });
      } else {
        options.push({ name: company, id: company });
      }
    }
    return options.map((v, i) => {
      return (
        <option key={i} value={v.id}>
          {v.name}
        </option>
      );
    });
  };
  const handleChange = (val) => {
    if (onValueChange) {
      onValueChange(val);
    }
  };
  return (
    <FormControl>
      <FormLabel htmlFor="companySelect">Company</FormLabel>
      <Select
        id="companySelect"
        isDisabled={fetching}
        variant={"filled"}
        onChange={(e) => handleChange(e.target.value)}
        value={selectedValue}
      >
        <option value="">Select Company</option>
        {renderSelectItems()}
      </Select>
    </FormControl>
  );
};

export default CompanySelectCore;
